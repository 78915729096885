const Balance = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M12 20.5c-2.427 0-4.45-.342-6.07-1.027-1.62-.686-2.43-1.542-2.43-2.569V7c0-.965.83-1.79 2.488-2.474C7.648 3.842 9.651 3.5 12 3.5c2.349 0 4.352.342 6.012 1.026C19.67 5.21 20.5 6.036 20.5 7v9.904c0 1.027-.81 1.883-2.43 2.569-1.62.685-3.643 1.027-6.07 1.027Zm0-11.542c1.458 0 2.925-.206 4.403-.618 1.477-.412 2.334-.857 2.568-1.334-.228-.49-1.075-.946-2.541-1.37a15.881 15.881 0 0 0-8.844-.017c-1.459.412-2.317.862-2.576 1.349.252.5 1.104.956 2.556 1.37 1.452.413 2.93.62 4.434.62Zm0 5.004c.694 0 1.369-.033 2.025-.1a17.344 17.344 0 0 0 1.882-.292 12.697 12.697 0 0 0 1.68-.477c.521-.19.992-.405 1.413-.645V8.9c-.42.24-.892.455-1.413.645a12.7 12.7 0 0 1-1.68.476 17.32 17.32 0 0 1-1.882.293 20.22 20.22 0 0 1-4.089-.005c-.67-.07-1.303-.169-1.898-.297a12.71 12.71 0 0 1-1.662-.472A8.13 8.13 0 0 1 5 8.9v3.548c.404.24.862.453 1.376.64a12.62 12.62 0 0 0 1.662.472c.595.128 1.228.227 1.898.297.67.07 1.358.105 2.064.105ZM12 19c.812 0 1.608-.053 2.39-.16a15.072 15.072 0 0 0 2.135-.439c.641-.185 1.182-.4 1.622-.645.44-.245.725-.502.853-.77v-3.038c-.42.24-.892.455-1.413.645-.522.19-1.082.348-1.68.477a17.32 17.32 0 0 1-1.882.292c-.656.067-1.331.1-2.025.1-.706 0-1.395-.035-2.064-.105-.67-.07-1.303-.169-1.898-.297a12.718 12.718 0 0 1-1.662-.472A8.132 8.132 0 0 1 5 13.948V17c.128.276.41.532.845.769.435.236.974.447 1.615.632a15.04 15.04 0 0 0 2.142.439c.787.107 1.586.16 2.398.16Z"
        />
    </svg>
);

export default Balance;
