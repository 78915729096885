import classNames from 'classnames';
import AccordionItem from 'components/ui/Accordion/Accordion';
import TextInput from 'components/ui/Form/TextInput/TextInput';
import {PROFESSIONAL_POSITION_OPTIONS} from 'constants/common';
import React, {useMemo} from 'react';
import {ICrpBpListItem} from 'api/business-partner';
import AutoComplete from 'components/ui/Form/AutoComplete/AutoComplete';
import styles from '../CrpForm.module.scss';

interface IProps {
    crpData?: ICrpBpListItem;
}

const CrpAdminForm = ({crpData}: IProps) => {
    const adminDone = useMemo(() => {
        return crpData && !!crpData.role && !!crpData.professionalPosition;
    }, [crpData]);

    return (
        <AccordionItem
            toggleType="title"
            openStatus={!adminDone}
            className={classNames(styles.accordionContainer, {
                [styles.groupDone]: adminDone,
            })}
            accordionKey="signer"
            title={
                <>
                    <span className={styles.groupStatus} />
                    <div className={styles.accordionHeader}>
                        Additional information admins
                    </div>
                </>
            }
            body={
                <>
                    <AutoComplete
                        name="professionalPosition"
                        label="Professional position"
                        options={PROFESSIONAL_POSITION_OPTIONS}
                        variant="dark"
                        size="large"
                    />
                    <TextInput
                        name="role"
                        label="Could you please specify your professional position?"
                        placeholder="CEO"
                        variant="dark"
                        size="large"
                    />
                </>
            }
        />
    );
};

export default CrpAdminForm;
