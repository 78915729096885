import React, {useMemo} from 'react';
import {ICrpBpListItem} from 'api/business-partner';
import classNames from 'classnames';
import AccordionItem from 'components/ui/Accordion/Accordion';
import CheckBoxGroup from 'components/ui/Form/OnboardingCheckBoxGroup/OnboardingCheckBoxGroup';
import {
    DIRECT_OWNER_OPTIONS,
    OWNER_PERCENTAGE_OPTIONS,
    PROFESSIONAL_POSITION_OPTIONS,
} from 'constants/common';
import AutoComplete from 'components/ui/Form/AutoComplete/AutoComplete';
import TextInput from 'components/ui/Form/TextInput/TextInput';
import {useFormContext} from 'react-hook-form';
import styles from '../CrpForm.module.scss';

interface IProps {
    crpData?: ICrpBpListItem;
    formOfControl?: string;
    inReview: boolean;
}

const CrpOwnerForm = ({crpData, formOfControl, inReview}: IProps) => {
    const {watch} = useFormContext();
    const directOwner = watch('directOwner');
    const ownerOptions =
        formOfControl === '3'
            ? [...DIRECT_OWNER_OPTIONS, {value: 'null', label: 'No'}]
            : DIRECT_OWNER_OPTIONS;

    const ownerDone = useMemo(() => {
        return (
            ((formOfControl !== '3' &&
                crpData &&
                crpData.directOwner !== null &&
                crpData.ownerPercentage) ||
                (formOfControl === '3' &&
                    crpData &&
                    crpData.ownerPercentage === '0')) &&
            !!crpData &&
            !!crpData.professionalPosition
        );
    }, [crpData, formOfControl]);

    return (
        <AccordionItem
            toggleType="title"
            openStatus={!ownerDone}
            className={classNames(styles.accordionContainer, {
                [styles.groupDone]: ownerDone,
            })}
            accordionKey="owner"
            title={
                <>
                    <span className={styles.groupStatus} />
                    <div className={styles.accordionHeader}>
                        Additional information
                    </div>
                </>
            }
            body={
                <>
                    <CheckBoxGroup
                        options={ownerOptions}
                        name="directOwner"
                        type="radio"
                        label="Is the person listed above holding capital shares/voting rights directly?"
                        variant="dark"
                        hidden={formOfControl !== '1'}
                        size="large"
                    />
                    {directOwner === 'yes' ? (
                        <AutoComplete
                            name="ownerPercentage"
                            label="How many percentages does this person hold in the company?"
                            options={OWNER_PERCENTAGE_OPTIONS}
                            variant="dark"
                            valueKey="value"
                            hidden={formOfControl !== '1'}
                            size="large"
                        />
                    ) : null}
                    <AutoComplete
                        name="professionalPosition"
                        label="Professional position"
                        options={PROFESSIONAL_POSITION_OPTIONS}
                        variant="dark"
                        size="large"
                    />
                    <TextInput
                        label="Could you please specify your professional position?"
                        placeholder="CEO"
                        name="role"
                        variant="dark"
                        size="large"
                    />
                </>
            }
        />
    );
};

export default CrpOwnerForm;
