import {useUpdateBusinessPartnerOnboarding} from 'api/business-partner';
import AutoComplete from 'components/ui/Form/AutoComplete/AutoComplete';

import Form, {FormOnSubmit, WithFormError} from 'components/ui/Form/Form/Form';
import TextInput from 'components/ui/Form/TextInput/TextInput';
import {BUDGET_LIST} from 'constants/businessPartnerAttributes';
import {SOURCE_OF_FUNDS, SOURCE_OF_REFFERAL} from 'constants/common';
import {
    BusinessAttributesCompanyFormValues,
    businessAttributesValidationSchema,
} from 'form/validation/businessPartner';
import {handleTypedFormError} from 'helpers/handleFormError';
import {translate} from 'helpers/localize';
import React, {useEffect, useState} from 'react';
import {findOnboardingStepByNumber} from 'components/pages/OnboardingPage/onboardingSteps';
import {LegalType} from 'types/onboarding';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import ErrorFeedback from 'components/ui/Form/ErrorFeedback/ErrorFeedback';
import NextStepButton from '../components/NextStepButton';
import StepTitle from '../components/StepTitle';
import {IDefaultStepProps} from '../useOnboarding';

import styles from './Steps.module.scss';

export const convertToTranslatedOptions = (options: {}) =>
    Object.keys(options).map(key => ({
        '@id': key.split('-')[0],
        label: translate(options[key as keyof typeof options]),
    }));

const DEFAULT_FORM_VALUES: BusinessAttributesCompanyFormValues = {
    gwgInfo: {
        operationalBusiness: true,
        returnBudget: '',
        sourceOfFunds: '',
        customSourceOfFunds: '',
        referralCode: '',
        website: '',
    },
    inReview: false,
    referralType: '',
};

const getTitle = (legalType: LegalType, inReview: boolean) => {
    switch (legalType) {
        case LegalType.INDIVIDUAL:
            return 'Tell us more about yourself';
        case LegalType.COMPANY:
            return inReview
                ? 'Are the details about your business activities up to date?'
                : 'Tell us more about your business activities';
        default:
            return 'MISSING_TITLE';
    }
};

const BusinessAttributes = (props: IDefaultStepProps) => {
    const {
        legalType,
        isIndividual,
        activeBusinessPartner,
        setActiveBusinessPartner,
        onSuccess,
        isEdit,
        inReview,
        currentStep,
    } = props;
    const [defaultValues, setDefaultValues] =
        useState<Partial<BusinessAttributesCompanyFormValues>>(
            DEFAULT_FORM_VALUES,
        );

    const formMethods = useForm<
        WithFormError<BusinessAttributesCompanyFormValues>
    >({
        resolver: yupResolver(businessAttributesValidationSchema),
        defaultValues,
    });

    const {watch, setValue, register, errors} = formMethods;
    register('gwgInfo.operationalBusiness');
    register('inReview');
    const operationalBusiness = watch('gwgInfo.operationalBusiness');

    const sourceOfFundsValue = watch('gwgInfo.sourceOfFunds');

    const {mutate: addAdditionalInfo, isPending: isLoading} =
        useUpdateBusinessPartnerOnboarding();

    const [isReferralCodeFieldNeeded, setReferralCodeFieldNeeded] =
        useState<boolean>(false);

    const onSubmit: FormOnSubmit<BusinessAttributesCompanyFormValues> = (
        values,
        setError,
    ) => {
        // TODO - fix after yup upgrade
        if (
            values.referralType === 'Clientreferral' &&
            // @ts-ignore
            !values.gwgInfo.referralCode
        ) {
            setError('gwgInfo.referralCode', {message: 'Required'});
            return;
        }
        const {inReview: _, ...rest} = values;
        const data = isIndividual
            ? {
                  ...rest,
                  gwgInfo: {
                      ...rest.gwgInfo,
                      id: activeBusinessPartner.gwgInfo?.id,
                  },
                  businessPartnerId: activeBusinessPartner['@id'],
                  step: findOnboardingStepByNumber(currentStep)?.type,
              }
            : {
                  ...rest,
                  gwgInfo: {
                      ...rest.gwgInfo,
                      id: activeBusinessPartner.gwgInfo?.id,
                      // TODO - fix after yup upgrade
                      // Typescript can't ignore a block, it's either one line or an entire file...
                      sourceOfFunds:
                          // @ts-ignore
                          rest.gwgInfo.sourceOfFunds === 'OTHERS'
                              ? // @ts-ignore
                                rest.gwgInfo.customSourceOfFunds
                              : // @ts-ignore
                                rest.gwgInfo.sourceOfFunds,
                  },
                  businessPartnerId: activeBusinessPartner['@id'],
                  step: findOnboardingStepByNumber(currentStep)?.type,
              };

        addAdditionalInfo(data, {
            onSuccess: response => {
                setActiveBusinessPartner(response);
                onSuccess({refetchCrpList: true});
            },
            onError: (requestErrors: any) => {
                handleTypedFormError(requestErrors, setError, values);
            },
        });
    };

    useEffect(() => {
        const {gwgInfo, referralType} = activeBusinessPartner;
        if (referralType === 'Clientreferral') {
            setReferralCodeFieldNeeded(true);
        }

        let sourceOfFunds: string | undefined;
        if (gwgInfo?.sourceOfFunds) {
            const defaultSource = SOURCE_OF_FUNDS[
                legalType ?? LegalType.INDIVIDUAL
            ].find(source => source['@id'] === gwgInfo?.sourceOfFunds)?.['@id'];
            if (defaultSource) {
                sourceOfFunds = defaultSource;
            } else {
                sourceOfFunds = 'OTHERS';
            }
        }

        setDefaultValues({
            gwgInfo: {
                operationalBusiness: gwgInfo?.operationalBusiness || null,
                returnBudget: gwgInfo?.returnBudget || null,
                referralCode: gwgInfo?.referralCode || null,
                sourceOfFunds: sourceOfFunds || null,
                customSourceOfFunds: gwgInfo?.sourceOfFunds || null,
                website: gwgInfo?.website || null,
            },
            referralType:
                referralType || activeBusinessPartner.bexio ? 'Bexio' : null,
            inReview,
        });
    }, [activeBusinessPartner, isIndividual, legalType, inReview]);

    return (
        <>
            <StepTitle title={getTitle(legalType!, inReview)} />
            <Form<BusinessAttributesCompanyFormValues>
                onSubmit={onSubmit}
                formMethods={formMethods}
                defaultValues={defaultValues}
                className={styles.form}
                confirmLeave
            >
                <AutoComplete
                    label={
                        <span data-notranslate>
                            {translate(
                                'What is your budgeted annual sales in %{currency}?',
                                {
                                    currency: activeBusinessPartner.mainCurrency
                                        ?.split('/')
                                        .pop(),
                                },
                            )}
                        </span>
                    }
                    name="gwgInfo.returnBudget"
                    placeholder="Please select"
                    options={BUDGET_LIST}
                    sortKey="@id"
                    numericSort
                    variant="dark"
                    translateInputContent={false}
                    size="large"
                />
                <AutoComplete
                    label="Source of funds"
                    name="gwgInfo.sourceOfFunds"
                    placeholder="Please select"
                    options={SOURCE_OF_FUNDS[legalType ?? LegalType.INDIVIDUAL]}
                    variant="dark"
                    info="Refers to the origin of the funds which are the subject of the transactions."
                    size="large"
                />
                {sourceOfFundsValue === 'OTHERS' ? (
                    <TextInput
                        label="Please specify"
                        name="gwgInfo.customSourceOfFunds"
                        variant="dark"
                        size="large"
                    />
                ) : null}
                <TextInput
                    label="Is there a company website?"
                    name="gwgInfo.website"
                    placeholder="www.yourwebsite.com"
                    variant="dark"
                    behavior="website"
                    size="large"
                />
                {!inReview ? (
                    <AutoComplete
                        label="Where did you hear from us?"
                        name="referralType"
                        placeholder="Please select"
                        options={SOURCE_OF_REFFERAL}
                        variant="dark"
                        onChange={value => {
                            setReferralCodeFieldNeeded(
                                (
                                    value as (typeof SOURCE_OF_REFFERAL)[number]
                                )?.['@id'] === 'Clientreferral',
                            );
                        }}
                        size="large"
                        isClearable
                    />
                ) : null}
                {!isEdit && isReferralCodeFieldNeeded ? (
                    <TextInput
                        label="Do you have a referral code?"
                        name="gwgInfo.referralCode"
                        placeholder="Code"
                        variant="dark"
                        size="large"
                    />
                ) : null}
                <div className={styles.checkBoxContainer}>
                    <input
                        type="checkbox"
                        onChange={e =>
                            setValue(
                                'gwgInfo.operationalBusiness',
                                e.target.checked,
                            )
                        }
                        checked={!!operationalBusiness}
                        id="operationalBusiness"
                        name="operationalBusiness"
                        className={styles.checkBox}
                        value="true"
                    />
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label
                        htmlFor="operationalBusiness"
                        className={styles.checkBoxLabel}
                    >
                        I confirm that the company is an operating company with
                        own offices.
                    </label>
                    {/* @ts-ignore */}
                    {errors.gwgInfo?.operationalBusiness?.message ? (
                        <ErrorFeedback
                            message={
                                // @ts-ignore
                                errors.gwgInfo.operationalBusiness.message
                            }
                        />
                    ) : null}
                </div>
                <NextStepButton loading={isLoading} />
            </Form>
        </>
    );
};

export default BusinessAttributes;
