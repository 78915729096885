import * as React from 'react';

const SvgArrowUpRightMulti = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 31 20"
        {...props}
    >
        <path
            fill="currentColor"
            d="M20.11 7.734 14.157.75l16.66.234-2.406 16.492-5.951-6.983-10.346 8.816-2.351-2.759 10.345-8.816Z"
        />
        <path
            fill="currentColor"
            d="M6.28 7.94 3.007 4.1l9.157.129-1.323 9.064-3.27-3.837L1.886 14.3.594 12.785l5.685-4.846Z"
        />
    </svg>
);

export default SvgArrowUpRightMulti;
