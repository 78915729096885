import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Email address must be valid')
        .required('Required'),
    password: Yup.string().required('Required'),
});

export const smsValidationSchema = Yup.object().shape({
    pin: Yup.string()
        .min(6, 'Invalid code')
        .max(6, 'Invalid code')
        .typeError('SMS Code must be valid')
        .required('Required'),
});
