const SvgDownload = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M12.71 14.955a.5.5 0 0 1-.597 0L8.99 12.637a.5.5 0 0 1 .298-.902h6.247a.5.5 0 0 1 .298.902l-3.123 2.318Z"
        />
        <path
            fill="currentColor"
            d="M11.647 12.117V6h1.53v6.118zM5.529 17.471h13.765V19H5.529zM5.529 15.176V19h-1.53v-3.824zM20.823 15.176V19h-1.529v-3.824z"
        />
    </svg>
);

export default SvgDownload;
