const Tools = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="m17.477 20.604-6.3-6.33c-.353.152-.71.27-1.075.352a5.23 5.23 0 0 1-1.16.124c-1.596 0-2.953-.559-4.072-1.678C3.752 11.954 3.192 10.598 3.192 9c0-.516.066-1.01.197-1.481a5.93 5.93 0 0 1 .561-1.346l3.592 3.574L9.69 7.6 6.135 4.027c.425-.242.87-.432 1.335-.57a5.152 5.152 0 0 1 1.472-.207c1.597 0 2.954.56 4.072 1.678C14.133 6.047 14.692 7.404 14.692 9a5.049 5.049 0 0 1-.477 2.235l6.331 6.3a.868.868 0 0 1 .262.639.869.869 0 0 1-.262.638l-1.811 1.811a.825.825 0 0 1-.63.252.893.893 0 0 1-.628-.27Zm.639-1.5.915-.915-6.66-6.66a3.42 3.42 0 0 0 .621-1.181c.134-.442.2-.891.2-1.348 0-1.109-.396-2.09-1.188-2.944-.792-.854-1.795-1.272-3.008-1.256l2.177 2.177a.876.876 0 0 1 .271.614.807.807 0 0 1-.252.613l-3.027 3.027a.85.85 0 0 1-.623.262.851.851 0 0 1-.623-.262L4.742 9.054c.003 1.284.434 2.304 1.295 3.06.86.758 1.828 1.136 2.905 1.136.44 0 .878-.065 1.315-.195.436-.13.836-.333 1.199-.61l6.66 6.66Z"
        />
    </svg>
);

export default Tools;
