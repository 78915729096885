import {components, OptionProps, SingleValueProps} from 'react-select';

import {ICountry} from 'types/api';
import CurrencyFlag from 'components/ui/CurrencyFlag/CurrencyFlag';
import AutoComplete, {IAutoCompleteProps} from './AutoComplete';

const CurrencyAutoComplete = (props: IAutoCompleteProps) => {
    const Option = (optionProps: OptionProps<ICountry, false>) => {
        const {data} = optionProps;
        return (
            <components.Option {...optionProps}>
                <CurrencyFlag code={data.code} />
            </components.Option>
        );
    };

    const SingleValue = (singleValueProps: SingleValueProps<ICountry>) => {
        const {data} = singleValueProps;
        return (
            <components.SingleValue {...singleValueProps}>
                <CurrencyFlag code={data.code} title />
            </components.SingleValue>
        );
    };

    return (
        <AutoComplete
            data-notranslate
            components={{Option, SingleValue}}
            isClearable
            labelKey="code"
            valueKey="@id"
            {...props}
            translateInputContent={false}
        />
    );
};

export default CurrencyAutoComplete;
