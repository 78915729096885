const Upload = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M12.114 6.221a.5.5 0 0 1 .596 0l3.123 2.319a.5.5 0 0 1-.298.901H9.288a.5.5 0 0 1-.298-.901l3.124-2.319Z"
        />
        <path
            fill="currentColor"
            d="M13.176 9.058v6.118h-1.529V9.058zM5.529 17.471h13.765V19H5.529zM5.529 15.176V19h-1.53v-3.824zM20.823 15.176V19h-1.529v-3.824z"
        />
    </svg>
);

export default Upload;
