import React, {useMemo} from 'react';
import {IDefaultStepProps} from 'components/pages/OnboardingPage/useOnboarding';
import NextStepButton from 'components/pages/OnboardingPage/components/NextStepButton';
import StepTitle from 'components/pages/OnboardingPage/components/StepTitle';
import Form, {FormOnSubmit, WithFormError} from 'components/ui/Form/Form/Form';
import AutoComplete from 'components/ui/Form/AutoComplete/AutoComplete';
import {findOnboardingStepByNumber} from 'components/pages/OnboardingPage/onboardingSteps';
import {useForm} from 'react-hook-form';
import {
    useGetBusinessPartnerPaymentTypes,
    useUpdateBusinessPartnerOnboarding,
} from 'api/business-partner';
import {handleTypedFormError} from 'helpers/handleFormError';
import {
    PAYMENT_SIZES,
    PAYMENT_VOLUME,
} from 'constants/businessPartnerAttributes';
import {
    GeneralPaymentsFormValues,
    generalPaymentsValidationSchema,
} from 'form/validation/businessPartner';
import {yupResolver} from '@hookform/resolvers';
import styles from './Steps.module.scss';

const GeneralPayments = (props: IDefaultStepProps) => {
    const {
        onSuccess,
        currentStep,
        setActiveBusinessPartner,
        activeBusinessPartner,
        legalType,
    } = props;
    const {data: paymentTypes, isLoading: isPaymentTypesLoading} =
        useGetBusinessPartnerPaymentTypes();

    const {mutate: addAdditionalInfo, isPending: isLoading} =
        useUpdateBusinessPartnerOnboarding();

    const defaultValues = useMemo(() => {
        return {
            gwgInfo: {
                paymentSize: activeBusinessPartner?.gwgInfo?.paymentSize ?? '',
                paymentTypes:
                    activeBusinessPartner?.gwgInfo?.paymentTypes ?? [],
                paymentVolume:
                    activeBusinessPartner?.gwgInfo?.paymentVolume ?? '',
            },
        };
    }, [activeBusinessPartner]);

    const formMethods = useForm<WithFormError<GeneralPaymentsFormValues>>({
        defaultValues,
        resolver: yupResolver(generalPaymentsValidationSchema),
    });

    const handleSubmit: FormOnSubmit<GeneralPaymentsFormValues> = (
        data,
        setError,
    ) => {
        addAdditionalInfo(
            {
                gwgInfo: {
                    ...data.gwgInfo,
                    id: activeBusinessPartner.gwgInfo?.id,
                },
                step: findOnboardingStepByNumber(currentStep)?.type,
                businessPartnerId: activeBusinessPartner['@id'],
            },
            {
                onSuccess: response => {
                    setActiveBusinessPartner(response);
                    onSuccess({refetchCrpList: true});
                },
                onError: (requestErrors: any) => {
                    handleTypedFormError(requestErrors, setError, data);
                },
            },
        );
    };

    return (
        <>
            <StepTitle title="Tell us about your payments" />
            <Form onSubmit={handleSubmit} formMethods={formMethods}>
                <div className={styles.form}>
                    <AutoComplete
                        name="gwgInfo.paymentTypes"
                        label="Which types of payments do you intend to make with amnis?"
                        isMulti
                        options={paymentTypes || []}
                        isLoading={isPaymentTypesLoading}
                        labelKey="value"
                        size="large"
                        variant="dark"
                        showMultiMessage
                    />
                    <AutoComplete
                        name="gwgInfo.paymentSize"
                        label={
                            <span>
                                What is the average amout of your payments
                                in&nbsp;
                                <var data-var="currency">
                                    {activeBusinessPartner?.mainCurrency
                                        ?.split('/')
                                        .pop()}
                                </var>
                                ?
                            </span>
                        }
                        options={PAYMENT_SIZES}
                        size="large"
                        variant="dark"
                    />
                    <AutoComplete
                        name="gwgInfo.paymentVolume"
                        label={
                            <span>
                                What is your estimated incoming and outgoing
                                payment volume on amnis in&nbsp;
                                <var data-var="currency">
                                    {activeBusinessPartner?.mainCurrency
                                        ?.split('/')
                                        .pop()}
                                </var>
                                &nbsp;p.a.?
                            </span>
                        }
                        options={legalType ? PAYMENT_VOLUME[legalType] : []}
                        size="large"
                        variant="dark"
                    />
                </div>
                <div className={styles.form}>
                    <NextStepButton loading={isLoading} />
                </div>
            </Form>
        </>
    );
};

export default GeneralPayments;
