import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {useHistory} from 'react-router';
import {useForm} from 'react-hook-form';

import {useBusinessPartner} from 'context/business-partner-context';
import {BusinessPartner} from 'types/api';
import {LegalType} from 'types/onboarding';
import {PATH} from 'constants/common';
import IndividualForm from '../components/IndividualForm';
import LegalTypePicker from '../components/LegalTypePicker';
import {IDefaultStepProps} from '../useOnboarding';
import CompanyForm from '../components/CompanyForm';
import StepTitle from '../components/StepTitle';

import styles from './Steps.module.scss';

interface IProps extends IDefaultStepProps {
    isEdit?: boolean;
    shouldBlockNavigation?: boolean;
    isOnboarding?: boolean;
}

const CreateBusinessPartner = ({
    activeBusinessPartner,
    legalType,
    setLegalType,
    onSuccess,
    setActiveBusinessPartner,
    isEdit,
    inReview,
    currentStep,
    isOnboarding = true,
}: IProps) => {
    const history = useHistory<{redirect?: boolean}>();
    const {businessPartners} = useBusinessPartner();
    const [confirmNew, setConfirmNew] = React.useState<boolean>(false);
    const [showBpPicker, setShowBpPicker] = React.useState<boolean>(false);
    const [inProgressOnboardings, setInProgressOnboardings] = useState<
        BusinessPartner[]
    >([]);

    const {register} = useForm({
        shouldUnregister: false,
    });
    useEffect(() => {
        if (activeBusinessPartner.sandbox) {
            const inProgressOnboarding = businessPartners.filter(
                bp => !bp.identified && !bp.sandbox,
            );

            if (inProgressOnboarding.length > 0) {
                setInProgressOnboardings(inProgressOnboarding);
                setConfirmNew(true);
            }
        }
    }, [activeBusinessPartner, businessPartners]);

    const handleSelectedBpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setActiveBusinessPartner(
            businessPartners.find(bp => bp.id === +e.target.value) || null,
        );
        history.push(history.location.pathname, {
            redirect: true,
        });
    };

    useEffect(() => {
        if (history.location.state?.redirect === true) {
            history.push(PATH.ONBOARDING_OVERVIEW, {});
        }
    }, [history, history.location]);

    const renderForm = () => {
        const formProps = {
            onSuccess,
            isEdit:
                isEdit === undefined ? !activeBusinessPartner.sandbox : isEdit,
            activeBusinessPartner,
            setActiveBusinessPartner,
            className: styles.form,
            shouldBlockNavigation: false,
            inReview,
            currentStep,
        };

        return legalType === LegalType.INDIVIDUAL ? (
            <IndividualForm {...formProps} />
        ) : (
            <CompanyForm {...formProps} />
        );
    };

    const renderPage = () => {
        if (confirmNew) {
            return (
                <>
                    <StepTitle
                        title="You have already started the onboarding process."
                        subTitle="Do you want to continue the onboarding instead of
                        starting to add a new account?"
                    />
                    <div className={styles.form}>
                        {showBpPicker ? (
                            inProgressOnboardings.map(bp => (
                                <div>
                                    <input
                                        type="radio"
                                        key={bp.id}
                                        id={`${bp.id}`}
                                        className={styles.radioInput}
                                        value={bp.id}
                                        name="selectedBp"
                                        onChange={handleSelectedBpChange}
                                        ref={register}
                                    />
                                    <label
                                        htmlFor={`${bp.id}`}
                                        className={styles.radioLabel}
                                    >
                                        {bp.companyName}
                                    </label>
                                </div>
                            ))
                        ) : (
                            <div className={styles.buttonRow}>
                                <Button
                                    variant="outline-primary"
                                    className="mr-2"
                                    onClick={() => setConfirmNew(false)}
                                >
                                    Start new
                                </Button>
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => setShowBpPicker(true)}
                                >
                                    Continue pending one
                                </Button>
                            </div>
                        )}
                    </div>
                </>
            );
        }

        return (
            <>
                {legalType === null ? (
                    <>
                        <StepTitle title="Which account type would you like to open?" />
                        <LegalTypePicker
                            isOnboarding={isOnboarding}
                            setLegalType={setLegalType}
                        />
                    </>
                ) : (
                    renderForm()
                )}
            </>
        );
    };

    return renderPage();
};

export default CreateBusinessPartner;
