import {ROUTING_TYPE_MAPPING} from 'constants/wallet';

export interface ICreateWallet {
    currency: string;
    businessPartner: string;
}
export interface IWalletCreate {
    businessPartner: string;
    currency: string;
}

export interface IWalletIban {
    accountNumber: string;
    bankAddress: string;
    bankCountry: string;
    bankName: string;
    paymentType: 'regular' | 'priority';
    routingCode: string;
    routingType: string;
}

export type VirtualIban = {
    '@type': 'VirtualIban';
    id: number;
    currency: string;
    iban: string;
    address: string;
    country: string;
    paymentType: 'regular' | 'priority';
    bankName: string;
    routingCode: string;
    routingType: keyof typeof ROUTING_TYPE_MAPPING;
};

export interface IBusinessPartnerWallet {
    '@id': string;
    '@type': string;
    availableBalance: number;
    baseCurrencyBalance: number;
    businessPartner: string;
    type: WalletTypes;
    currency: string;
    iban: string | null;
    id: number;
    forecastedBalance: number;
    ibans: IWalletIban[] | null;
    position: number;
    virtualIbans: VirtualIban[];
    pendingCardTransactionBalance: number;
}

export enum WalletTypes {
    NORMAL = 'normal',
    COLLATERAL = 'collateral',
}

export interface IUpdateBusinessPartnerWalletOrderRequest {
    walletType: WalletTypes;
    wallets: number[];
    businessPartnerId: string;
}
