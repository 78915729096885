import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {devtools} from 'zustand/middleware';

type CrpStoreState = {
    isLoading: boolean;
};

type CrpStoreActions = {
    setIsLoading: (status: boolean) => void;
};

export const useCrpStore = create(
    devtools(
        immer<CrpStoreState & CrpStoreActions>(set => ({
            isLoading: false,
            setIsLoading: status => {
                set(state => {
                    state.isLoading = status;
                });
            },
        })),
    ),
);
