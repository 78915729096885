import React, {useCallback, useState} from 'react';
import IconSVG from 'components/ui/Icon/IconSVG';
import {LegalType} from 'types/onboarding';
import {useLanguage} from 'context/language-context';
import RestrictedBusinessAlert from 'components/pages/OnboardingPage/components/RestrictedBusinessAlert';
import {Icons} from 'components/ui/Icon/IconSVGConfig';
import NextStepButton from 'components/pages/OnboardingPage/components/NextStepButton';
import classNames from 'classnames';
import {getPrivacyPolicy} from 'helpers/termsAndPrivacy';
import AcceptTermsCheckbox from './AcceptTermsCheckbox';

import styles from './LegalTypePicker.module.scss';
import {useBusinessPartner} from 'context/business-partner-context';

interface IProps {
    setLegalType: React.Dispatch<React.SetStateAction<LegalType | null>>;
    isOnboarding: boolean;
}
interface ILegalTypeProps {
    type: LegalType;
    icon: Icons;
    title: string;
    subtitle: string;
}
const LEGAL_TYPES: ILegalTypeProps[] = [
    {
        type: LegalType.COMPANY,
        icon: 'briefcase',
        title: 'Business account',
        subtitle: 'Perfect solution for SMEs of any size',
    },
    {
        type: LegalType.INDIVIDUAL,
        icon: 'person',
        title: 'Private account',
        subtitle: 'Minimum yearly currency exchange volume of EUR 100k',
    },
];

const LegalTypePicker = ({setLegalType, isOnboarding}: IProps) => {
    const {activeBusinessPartner} = useBusinessPartner();
    const {selectedLanguage} = useLanguage();
    const [acceptTerms, setAcceptTerms] = useState<number>(0);
    const [_legalType, _setLegalType] = useState<LegalType | null>(null);
    const [error, setError] = useState<boolean>(false);
    const handleClick = (type: LegalType) => {
        _setLegalType(type);
    };

    const handleAcceptTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAcceptTerms(e.target.checked ? 1 : 0);
        if (e.target.checked) setError(false);
        else setError(true);
    };

    const getPrivacyPoliciyUrl = useCallback(
        () => getPrivacyPolicy(selectedLanguage),
        [selectedLanguage],
    );

    return (
        <div className={styles.container}>
            {LEGAL_TYPES.filter(
                lt =>
                    !isOnboarding ||
                    !activeBusinessPartner.bexio ||
                    lt.type !== LegalType.INDIVIDUAL,
            ).map(legalType => (
                <div
                    className={classNames(styles.card, {
                        [styles.active]: _legalType === legalType.type,
                    })}
                    key={legalType.type}
                    onClick={() => handleClick(legalType.type)}
                    onKeyPress={() => handleClick(legalType.type)}
                    role="button"
                    tabIndex={0}
                >
                    <IconSVG
                        name={legalType.icon}
                        className={styles.icon}
                        customDimensions
                        width="3rem"
                        height="3rem"
                    />
                    <div className={styles.typeTexts}>
                        <p>{legalType.title}</p>
                        <p>{legalType.subtitle}</p>
                    </div>
                </div>
            ))}
            <div className={styles.restrictedBusinessAlert}>
                <RestrictedBusinessAlert />
            </div>
            <AcceptTermsCheckbox
                isError={error}
                linkToTerms={getPrivacyPoliciyUrl()}
                onChange={handleAcceptTerms}
                value={acceptTerms}
                termsName="data protection policy"
            />
            <NextStepButton
                disabled={acceptTerms !== 1 || !_legalType}
                onClick={() => setLegalType(_legalType!)}
                containerClassName={styles.submitButton}
            />
        </div>
    );
};

export default LegalTypePicker;
