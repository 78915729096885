import styles from './AmnisSpinner.module.scss';

const AmnisSpinner = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className={styles.loadingIcon}
        >
            <path
                fill="currentColor"
                d="M11.997 21.5c-1.3 0-2.528-.249-3.683-.747a9.63 9.63 0 0 1-3.026-2.04 9.628 9.628 0 0 1-2.04-3.027 9.196 9.196 0 0 1-.748-3.682c0-1.317.25-2.55.749-3.7a9.663 9.663 0 0 1 2.04-3.015 9.628 9.628 0 0 1 3.026-2.04A9.202 9.202 0 0 1 12 2.5c.213 0 .39.072.534.216a.726.726 0 0 1 .216.535c0 .212-.072.39-.216.534A.726.726 0 0 1 12 4c-2.217 0-4.104.78-5.663 2.338C4.78 7.896 4 9.784 4 12c0 2.217.78 4.105 2.337 5.663C7.896 19.22 9.783 20 12 20s4.104-.779 5.663-2.337C19.22 16.105 20 14.217 20 12c0-.212.072-.39.216-.534a.727.727 0 0 1 .534-.216c.213 0 .391.072.535.216A.726.726 0 0 1 21.5 12c0 1.3-.25 2.529-.75 3.686a9.625 9.625 0 0 1-2.038 3.026 9.661 9.661 0 0 1-3.016 2.04 9.192 9.192 0 0 1-3.7.748Z"
            />
        </svg>
    );
};

export default AmnisSpinner;
