import React, {useState, useEffect, useRef, useCallback} from 'react';
import {
    AuthTypes,
    IUseAuthenticateFuturaeResponse,
    useAuthenticateFuturae,
} from 'api/futurae';
import Loader from 'components/ui/AmnisLoader/AmnisLoader';
import ConfirmationMobile from 'components/ui/ConfirmationMobile/ConfirmationMobile';
import FuturaeAuth from 'components/ui/FuturaeAuth/FuturaeAuth';
import {Alert} from 'react-bootstrap';
import {ListenerHandler, useEventSource} from 'context/event-source-context';
import {ICardDetails, Permission} from 'types/api';
import useModal from 'components/ui/ModalContainer/useModal';
import ModalOverlay from 'components/ui/Overlays/ModalOverlay/ModalOverlay';
import {PartialWithRequired} from 'types/utils/utilitytypes';
import styles from './TwoFAPage.module.scss';

interface ITwoFAPageProps {
    token: string;
    verifyErrorProps: {
        title: string;
        text: string;
        btnTitle: string;
        btnSize: string;
    };
    enroll?: boolean;
    handleAuthResponse: (
        isSuccessful: boolean,
        newToken?: string,
        cardData?: ICardDetails,
        pin?: string,
    ) => void;
    onboarding?: boolean;
    type: AuthTypes;
    listenerKey?: string;
    listenerHandler?: ListenerHandler;
    pin?: string;
    cardId?: number;
    updatePermission?: PartialWithRequired<Permission, '@id'>;
}

const TwoFAPage: React.FC<ITwoFAPageProps> = ({
    token,
    handleAuthResponse,
    verifyErrorProps,
    enroll = false,
    onboarding = false,
    type,
    listenerKey = '',
    listenerHandler,
    cardId,
    updatePermission,
}) => {
    const [factorData, setFactorData] =
        useState<IUseAuthenticateFuturaeResponse | null>(null);
    const [authErrors, setAuthErrors] = useState<string | null>(null);
    const {registerListener} = useEventSource();
    const [smsMfaClaimUUID, setSmsMfaClaimUUID] = useState<string | null>(null);
    const unsubscribe = useRef<() => void>();
    const requestedSms = useRef<boolean>(false);

    useEffect(() => {
        requestedSms.current = false;
    }, []);

    const {mutate: authenticateFuturae, isPending: authLoading} =
        useAuthenticateFuturae();

    const requestAuth = useCallback(() => {
        authenticateFuturae(
            {
                token,
                factor: onboarding ? 'sms' : undefined,
                type,
                transactionId: '',
                cardId: cardId!,
                ...updatePermission,
                '@id': updatePermission?.['@id'] || '',
            },
            {
                onSuccess: data => {
                    if (listenerHandler) {
                        unsubscribe.current = registerListener(
                            listenerKey,
                            listenerHandler,
                            data.mfaClaimUUID,
                        );
                    }
                    setSmsMfaClaimUUID(data.mfaClaimUUID);
                    setFactorData(data);
                },
                onError: (data: any) => {
                    setAuthErrors(
                        data['hydra:description'] || 'Something went wrong.',
                    );
                },
            },
        );
    }, [
        authenticateFuturae,
        token,
        onboarding,
        type,
        cardId,
        updatePermission,
        listenerHandler,
        registerListener,
        listenerKey,
    ]);

    useEffect(() => {
        if (!token || enroll || requestedSms.current) {
            return undefined;
        }

        requestedSms.current = true;
        requestAuth();

        if (unsubscribe.current) return () => unsubscribe.current!();
        return undefined;
    }, [token, enroll, requestAuth]);

    const requestSms = () => {
        requestAuth();
    };

    if (authLoading) {
        return <Loader />;
    }

    if (authErrors) {
        return (
            <Alert variant="danger">
                <div>Error: {authErrors}</div>
                <div>Please contact the Customer Support.</div>
            </Alert>
        );
    }

    if (!factorData || factorData?.factor === 'sms' || onboarding) {
        return (
            <ConfirmationMobile
                onSuccessfulSmsAuth={handleAuthResponse}
                verifyErrorProps={verifyErrorProps}
                token={token}
                enroll={enroll}
                onboarding={onboarding}
                requestNewSms={requestSms}
                isRequestSmsLoading={authLoading}
                smsMfaClaimUUID={smsMfaClaimUUID}
            />
        );
    }

    if (factorData?.factor === 'approve') {
        return (
            <FuturaeAuth
                futuraImgUrl={factorData.qrcode_url}
                onSuccessful={handleAuthResponse}
            />
        );
    }

    return (
        <Alert variant="danger">
            There was an authentication error. Please contact the Customer
            Support.
        </Alert>
    );
};

const useTwoFAPage = () => {
    const {openModalWithContent, closeModal} = useModal();

    const triggerTwoFA = (args: ITwoFAPageProps) => {
        openModalWithContent(
            '2FA',
            <ModalOverlay>
                <div className={styles.container}>
                    <TwoFAPage
                        {...args}
                        handleAuthResponse={(
                            isSuccessful,
                            newToken,
                            cardData,
                            pin,
                        ) => {
                            args.handleAuthResponse(
                                isSuccessful,
                                newToken,
                                cardData,
                                pin,
                            );
                            // closeModal('2FA');
                        }}
                        listenerHandler={{
                            area: '',
                            ...args.listenerHandler,
                            callback: message => {
                                if (message.status === 'success') {
                                    closeModal('2FA');
                                } else {
                                    closeModal('2FA');
                                }

                                return args.listenerHandler?.callback(message);
                            },
                        }}
                    />
                </div>
            </ModalOverlay>,
        );
    };

    return {
        triggerTwoFA,
    };
};

export default TwoFAPage;
export {useTwoFAPage};
