const Automation = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        {...props}
    >
        <path d="M5.308 21.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.282V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h1.384V2.385h1.539V4.5h7.577V2.385h1.5V4.5h1.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v13.385c0 .505-.175.932-.525 1.282-.35.35-.778.525-1.283.525H5.308Zm0-1.5h13.384a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.211v-9.385H5v9.385c0 .077.032.147.096.211a.293.293 0 0 0 .212.096ZM5 8.808h14v-2.5a.294.294 0 0 0-.096-.211.294.294 0 0 0-.212-.097H5.308a.294.294 0 0 0-.212.097.294.294 0 0 0-.096.211v2.5Z" />
        <path d="M11.574 18.8v-.843a1.98 1.98 0 0 1-.962-.507c-.256-.25-.438-.596-.545-1.038l.976-.37c.088.329.221.566.398.711.177.145.398.218.663.218.24 0 .43-.044.569-.133.139-.088.209-.227.209-.417a.583.583 0 0 0-.218-.455c-.146-.126-.465-.262-.958-.407-.518-.158-.884-.37-1.1-.635a1.46 1.46 0 0 1-.321-.948c0-.385.124-.708.374-.967s.554-.423.915-.492v-.825h.9v.825c.341.075.624.216.848.421.225.206.39.463.498.773l-.957.398a.952.952 0 0 0-.318-.446.88.88 0 0 0-.545-.16c-.234 0-.403.042-.507.127a.395.395 0 0 0-.156.318c0 .164.07.297.213.398.142.1.46.224.952.37.462.138.815.353 1.062.644.246.29.37.638.37 1.042 0 .474-.14.838-.417 1.09a2 2 0 0 1-1.043.484v.824h-.9Z" />
    </svg>
);

export default Automation;
