import {useMemo} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';

export type UsePinInput = ReturnType<typeof usePinInput>;
const usePinInput = (fieldNr: number) => {
    const elements: (HTMLInputElement | null)[] = Array(fieldNr).fill(null);
    const defaultValues = useMemo(() => {
        const tmp = [];
        for (let i = 0; i < fieldNr; i++) {
            tmp.push({value: null});
        }
        return {rawValues: tmp};
    }, [fieldNr]);
    const {control, setValue, getValues, reset} = useForm<{
        rawValues: {value: string | null}[];
    }>({
        defaultValues,
    });
    const {fields} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'rawValues', // unique name for your Field Array
    });

    const resetPinInput = () => {
        reset(defaultValues);
        return elements?.[0]?.focus();
    };

    return {
        elements,
        fieldNr,
        pinControl: control,
        setPinValue: setValue,
        getPinValue: getValues,
        pinFields: fields,
        resetPinInput,
    };
};

export default usePinInput;
