import * as React from 'react';

const TagPlusIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="22"
        height="15"
        viewBox="0 0 22 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.303982 7.55107C-0.101328 7.95638 -0.101327 8.61352 0.303982 9.01883L3.97337 12.6882C4.37868 13.0935 5.03582 13.0935 5.44113 12.6882L10.4315 7.69785C10.4508 7.67858 10.4691 7.65875 10.4865 7.6384C10.6802 7.43732 10.7958 7.1548 10.7638 6.82375L10.4405 3.48477C10.3926 2.99066 10.0016 2.59962 9.50746 2.55177L6.16848 2.22845C5.83776 2.19643 5.55547 2.31182 5.35443 2.50511C5.33386 2.52271 5.31381 2.54124 5.29435 2.5607L0.303982 7.55107ZM8.80839 4.82522C9.15918 4.82522 9.44355 4.54084 9.44355 4.19005C9.44355 3.83926 9.15918 3.55489 8.80839 3.55489C8.4576 3.55489 8.17323 3.83926 8.17323 4.19005C8.17323 4.54084 8.4576 4.82522 8.80839 4.82522Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.833 7.87449L6.21075 12.4967L6.21075 13.0945C6.21075 13.6677 6.67542 14.1323 7.24861 14.1323L12.4379 14.1323C13.0111 14.1323 13.4758 13.6677 13.4758 13.0945L13.4758 6.03702C13.4758 6.00977 13.4747 5.98277 13.4727 5.95606C13.4674 5.67694 13.3494 5.39537 13.0927 5.18394L12.6365 4.80836L12.6365 3.0907C12.6365 2.74987 12.5467 2.28914 12.2653 1.90809C11.9766 1.51714 11.5004 1.23014 10.776 1.23014C10.049 1.23014 9.57334 1.52783 9.28591 1.92204C9.00581 2.30621 8.91541 2.76589 8.91541 3.0907L8.91541 3.19969L9.31538 3.19969L9.31538 3.0907C9.31538 2.82738 9.39103 2.45678 9.6091 2.15769C9.81984 1.86865 10.1744 1.63011 10.776 1.63011C11.38 1.63011 11.7341 1.86203 11.9436 2.14569C12.1603 2.43925 12.2366 2.80881 12.2366 3.0907L12.2366 4.47901L10.6802 3.19749C10.7683 3.33338 10.8255 3.49129 10.8419 3.66141L11.1652 7.00039C11.1973 7.33144 11.0816 7.61397 10.888 7.81505C10.8706 7.83539 10.8522 7.85523 10.833 7.87449Z"
            fill="white"
        />
        <path
            d="M17.6803 2.98646L14.8005 2.98646L14.8005 4.47969L17.6803 4.47969L17.6803 7.46616L19.1202 7.46616L19.1202 4.47969L22 4.4797V2.98647L19.1202 2.98646L19.1202 9.49371e-07L17.6803 0L17.6803 2.98646Z"
            fill="white"
        />
    </svg>
);

export default TagPlusIcon;
