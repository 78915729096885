/* eslint-disable */
import * as React from 'react';

const SvgSms = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className=""
        aria-hidden="true"
        data-prefix="fal"
        data-icon="sign-out"
        viewBox="0 0 512 512"
        width="1em"
        height="1em"
        {...props}
    >
        <path
            fill="currentColor"
            d="M352,96V32c0-17.632-14.336-32-32-32H64C46.368,0,32,14.368,32,32v448c0,17.664,14.368,32,32,32h256    c17.664,0,32-14.336,32-32v-96h-32v32H64V64h256v32H352z"
        />
        <path
            fill="currentColor"
            d="M448,128H256c-17.632,0-32,14.368-32,32v128c0,17.952,14.048,32,32,32h32v32c0,6.048,3.424,11.584,8.832,14.304    c2.272,1.12,4.736,1.696,7.168,1.696c3.392,0,6.784-1.088,9.6-3.2l59.744-44.8H448c17.952,0,32-14.048,32-32V160    C480,142.048,465.952,128,448,128z M304.64,240c-8.832,0-16.16-7.168-16.16-16c0-8.832,6.976-16,15.84-16h0.32    c8.832,0,16,7.168,16,16C320.64,232.832,313.472,240,304.64,240z M400.64,240c-8.8,0-16.16-7.168-16.16-16    c0-8.832,7.008-16,15.84-16h0.32c8.832,0,16,7.168,16,16C416.64,232.832,409.472,240,400.64,240z"
        />
    </svg>
);

export default SvgSms;
