import {Alert as BootAlert, AlertProps} from 'react-bootstrap';

interface IAlertProps {
    message: string | React.ReactNode;
    title?: string;
    variant?: AlertProps['variant'];
    className?: string;
}

const Alert: React.FC<IAlertProps> = ({
    message,
    title,
    variant = 'danger',
    className,
}) => {
    return (
        <BootAlert variant={variant} className={className}>
            {title && <BootAlert.Heading>{title}</BootAlert.Heading>}

            <div>{message}</div>
        </BootAlert>
    );
};

export default Alert;
