import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useCallback,
} from 'react';
import {MessageEvent} from 'event-source-polyfill';
import NotificationContainer from '../components/ui/Notifications/NotificationContainer';
import {allListeners, useEventSource} from './event-source-context';

type NotificationProviderType = {
    addNotification: (notification: INotificationData, id: string) => void;
    removeNotification: (id: string) => void;
};

const NotificationContext = createContext<NotificationProviderType>({
    addNotification: () => null,
    removeNotification: () => null,
});
NotificationContext.displayName = 'NotificationContext';

export interface INotificationProviderProps {
    children: React.ReactNode;
}

export interface INotificationData {
    area: string;
    status: 'success' | 'error';
    message: string;
    payload?: {
        info: string;
    };
}

export interface INotificationListItem {
    id: string;
    content: INotificationData;
}

export type NotificationList = INotificationListItem[];

const NotificationProvider = ({children}: INotificationProviderProps) => {
    const {registerNotifications} = useEventSource();
    const [notifications, setNotifications] = useState<NotificationList>([]);

    const addNotification = useCallback(
        (notification: INotificationData, id: string) => {
            setNotifications(prevState => [
                ...prevState,
                {
                    id,
                    content: notification,
                },
            ]);
        },
        [setNotifications],
    );

    const removeNotification = useCallback(
        (id: string) => {
            setNotifications(prevState => prevState.filter(n => n.id !== id));
        },
        [setNotifications],
    );

    useEffect(() => {
        const onMessageListener = (event: MessageEvent) => {
            const parsed = JSON.parse(event.data);
            addNotification(parsed, event.lastEventId);
        };
        const unregisterAllNotification = registerNotifications(
            allListeners,
            onMessageListener,
        );

        return () => unregisterAllNotification();
    }, [addNotification, registerNotifications]);

    return (
        <NotificationContext.Provider
            value={{addNotification, removeNotification}}
        >
            <NotificationContainer notifications={notifications} />
            {children}
        </NotificationContext.Provider>
    );
};

const useNotification = () => {
    const context = useContext(NotificationContext);

    if (context === undefined) {
        throw new Error(
            `useNotification must be used within a NotificationProvider`,
        );
    }
    return context;
};

export {NotificationProvider, useNotification};
