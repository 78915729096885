import AmnisButton from 'components/ui/AmnisButton/AmnisButton';
import styles from './PageTitle.module.scss';

interface IProps {
    description?: React.ReactNode;
    /**
     * @deprecated
     */
    className?: string;
    children: React.ReactNode;
    goBack?: () => void;
}

const PageTitle = ({children, description, goBack}: IProps) => (
    <div>
        <div className={styles.titleContainer}>
            <h4>{children}</h4>
            {goBack ? (
                <AmnisButton
                    intent="terciary"
                    onClick={goBack}
                    startIcon="chevronLeft"
                >
                    Back
                </AmnisButton>
            ) : null}
        </div>
        {description ? <p>{description}</p> : null}
    </div>
);

export default PageTitle;
