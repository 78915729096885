import * as React from 'react';

const SvgRegisterXChange = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
        {...props}
    >
        <path
            fill="#B3DFD8"
            fillRule="evenodd"
            d="m26.6895 15.9336.6421 1.9264.6421-1.2843-1.2842-.6421Z"
            clipRule="evenodd"
        />
        <path
            fill="#B3DFD8"
            d="m17.9416 25.8064-1.7658-.9632 3.4514-6.6621 7.0632-2.2475.6421 1.9264-6.3408 2.0067-3.0501 5.9397Z"
        />
        <path
            fill="#B3DFD8"
            fillRule="evenodd"
            d="m21.2315 29.9796-.6421-1.9264-.6421 1.2843 1.2842.6421Z"
            clipRule="evenodd"
        />
        <path
            fill="#B3DFD8"
            d="m21.232 29.9795-.6422-1.9264 6.3409-1.9264 3.0501-5.9397 1.7658.8829-3.4514 6.6622-7.0632 2.2474ZM34.7165 16.816l1.2843.8027c-.1606.4816-.4816.8026-.8829 1.1237-.4014.3211-.8027.4013-1.2843.4013-.321 0-.6421-.0802-.9632-.1605-.321-.1605-.5618-.3211-.8026-.5619-.4816-.5618-.7224-1.204-.8026-1.9264h-.6422v-.8026h.5619v-.3211h-.5619v-.8027h.6422c.0802-.7224.4013-1.3645.8829-1.8461.2408-.2408.4816-.4013.8026-.5619.3211-.0802.6421-.1605.8829-.1605.4816 0 .8829.1605 1.2843.4013.4013.2408.6421.6422.8829 1.0435l-1.2843.8027c-.0802-.1606-.1605-.3211-.321-.4816-.1606-.1606-.3211-.1606-.4816-.1606-.4816 0-.8027.3211-.9632 1.0435h1.4448v.8027h-1.6053v.321h1.6053v.8027h-1.5251c.1606.7224.4816 1.1237.9632 1.1237.1606 0 .4013-.0802.4816-.2408.2408-.1605.3211-.4013.4013-.6421ZM16.4948 33.351c0 .2408 0 .4816-.0803.7224-.0802.2408-.2408.4013-.4013.5619-.4013.321-.8026.5618-1.2842.5618v.8027h-.6421v-.8027c-.4816 0-.8829-.1605-1.2843-.4013-.4013-.2408-.6421-.6421-.8026-1.0435l1.204-.7224c.0802.1606.1605.3211.321.4816.1606.1606.3211.2408.4816.2408v-1.204h-.1605c-.1605-.0802-.3211-.1605-.4816-.2408-.1605-.0802-.3211-.1605-.4816-.2408-.1605-.0802-.321-.2408-.4013-.321-.0803-.1606-.2408-.3211-.2408-.4816-.0803-.2408-.0803-.4014-.0803-.6422 0-.2408 0-.4816.1606-.7224.0802-.2408.2408-.4013.4013-.5618.321-.3211.8026-.4816 1.2842-.5619v-.8027h.6421v.8027c.4014 0 .7224.1605 1.0435.4013.321.2408.5618.5619.7223.883l-1.2842.7224c-.0803-.1606-.0803-.2408-.2408-.3211-.0802-.0803-.2408-.1605-.321-.2408v1.204l.321.0803.3211.1605.321.1605c.0803.0803.2408.0803.3211.1606l.2408.2408c.0803.0802.1605.1605.2408.321.0802.0803.0802.2408.0802.3211 0 .1605 0 .3211.0803.4816Zm-2.9698-2.7291c0 .1606.1606.3211.4013.4816v-.9632c-.0802 0-.2407.0803-.321.1606 0 .1605-.0803.2408-.0803.321Zm1.0435 3.2107c.0802 0 .2408-.0803.321-.1605.0803-.0803.0803-.1606.0803-.3211s-.1605-.3211-.4013-.4816v.9632Z"
        />
        <rect width="47" height="47" x=".5" y=".5" stroke="#66F" rx="23.5" />
    </svg>
);

export default SvgRegisterXChange;
