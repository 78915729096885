const Visibility = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M12.002 15.279c1.039 0 1.92-.364 2.647-1.09.725-.728 1.088-1.61 1.088-2.65 0-1.038-.363-1.92-1.09-2.646-.728-.726-1.61-1.089-2.65-1.089-1.038 0-1.92.364-2.646 1.091-.725.727-1.088 1.61-1.088 2.649 0 1.038.363 1.92 1.09 2.646.728.726 1.61 1.089 2.65 1.089ZM12 14.017a2.386 2.386 0 0 1-1.753-.722 2.387 2.387 0 0 1-.722-1.753c0-.688.24-1.272.722-1.753A2.387 2.387 0 0 1 12 9.067c.688 0 1.272.24 1.753.722.481.48.722 1.065.722 1.753 0 .687-.24 1.271-.722 1.753a2.387 2.387 0 0 1-1.753.722Zm.001 3.941c-2.108 0-4.028-.581-5.762-1.744-1.733-1.163-3.01-2.72-3.829-4.672.82-1.952 2.095-3.51 3.828-4.673 1.732-1.163 3.653-1.744 5.76-1.744 2.109 0 4.03.581 5.763 1.744 1.733 1.163 3.01 2.72 3.829 4.673-.82 1.952-2.095 3.51-3.828 4.672-1.733 1.163-3.653 1.744-5.76 1.744ZM12 16.583a8.75 8.75 0 0 0 4.755-1.363 8.96 8.96 0 0 0 3.312-3.678 8.96 8.96 0 0 0-3.312-3.678A8.75 8.75 0 0 0 12 6.5a8.75 8.75 0 0 0-4.755 1.364 8.959 8.959 0 0 0-3.312 3.678 8.959 8.959 0 0 0 3.312 3.678A8.75 8.75 0 0 0 12 16.583Z"
        />
    </svg>
);

export default Visibility;
