import * as React from 'react';

const SvgCheckMark = (props: any) => (
    <svg viewBox="0 0 512 512" height="1em" width="1em" {...props}>
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="32"
            d="M416 128L192 384l-96-96"
            height="1em"
            width="1em"
        />
    </svg>
);

export default SvgCheckMark;
