import React, {useEffect} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import classNames from 'classnames';

import DatePickerInput from 'components/ui/DatePicker/DatePicker';
import InfoPopover from 'components/ui/InfoPopover/InfoPopover';
import HolidayDatePicker from 'components/ui/DatePicker/HolidayDatePicker';
import {getDeepValue} from 'helpers/common';
import {formatDateInZurichTZ} from 'helpers/dates';
import {DISPLAY_DATE_FORMAT} from 'constants/date';
import {IDefaultControlProps} from '../Form/Form';

import ErrorFeedback from '../ErrorFeedback/ErrorFeedback';
import styles from './DatePicker.module.scss';

export interface IDatePickerProps extends IDefaultControlProps {
    maxDate?: Date;
    minDate?: Date;
    currencies?: string[];
    defaultValue?: Date | null;
    dateFormat?: string;
    showMonthYearPicker?: boolean;
    isClearable?: boolean;
    considerValueDay?: boolean;
}

const DatePicker = ({
    maxDate,
    name,
    placeholder = 'DD.MM.YYYY',
    className,
    label,
    info,
    rounded = true,
    variant,
    disabled,
    size,
    currencies,
    registerOptions,
    minDate,
    hidden,
    defaultValue,
    readOnly,
    dateFormat = DISPLAY_DATE_FORMAT,
    showMonthYearPicker = false,
    isClearable,
    considerValueDay,
}: IDatePickerProps) => {
    const {control, errors} = useFormContext();

    useEffect(() => {
        if (control && name && defaultValue && !control.getValues()?.[name]) {
            control.setValue(name, formatDateInZurichTZ(defaultValue));
        }
    }, [control, name, defaultValue]);

    const isErrorMessage = () => {
        return getDeepValue(errors, name)?.message;
    };

    return (
        <div
            className={classNames(styles.container, className, {
                [styles.invalidInput]: getDeepValue(errors, name),
                [styles.darkInput]: variant === 'dark',
                [styles.roundedInput]: rounded,
                [styles.disabled]: disabled || readOnly,
                [styles.largeInput]: size === 'large',
                [styles.hidden]: hidden,
                [styles.requiredTextExtraSpace]: isErrorMessage(),
            })}
        >
            {label ? (
                <label htmlFor={name} className={styles.label}>
                    {label}
                    {info ? <InfoPopover content={info} /> : null}
                </label>
            ) : null}
            <Controller
                placeholderText={placeholder}
                control={control}
                as={currencies ? HolidayDatePicker : DatePickerInput}
                name={name}
                maxDate={maxDate}
                minDate={minDate}
                className={styles.datePicker}
                disabled={disabled || readOnly}
                currencies={currencies}
                rules={registerOptions}
                dateFormat={dateFormat}
                showMonthYearPicker={showMonthYearPicker}
                considerValueDay={considerValueDay}
                defaultValue={
                    defaultValue ? formatDateInZurichTZ(defaultValue) : null
                }
                isClearable={isClearable}
            />
            {isErrorMessage() ? (
                <ErrorFeedback message={getDeepValue(errors, name).message} />
            ) : null}
        </div>
    );
};

export default DatePicker;
