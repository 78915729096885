/* eslint-disable */
import * as React from 'react';

const AverageFxChart = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="1rem" height="1rem" {...props}>
            <path fill="#0f3e82" d="M5.116 81.884V0H0v87h87.31v-5.116z" />
            <path
                fill="none"
                strokeWidth="5"
                stroke="#4c858e"
                d="M13.655 69.333l34-30 12 8.667 26.667-26.667"
            />
            <path
                fill="none"
                strokeWidth="5"
                stroke="#4c858e"
                d="M73.988 21.52h12.334v11.793"
            />
            <path
                fill="none"
                strokeWidth="5"
                stroke="#e03e52"
                d="M14.322 38.221h19.823L54.322 22l28 39.333"
            />
            <path
                fill="none"
                strokeWidth="5"
                stroke="#e03e52"
                d="M85.36825993 49.85810964l-2.98386466 11.9676275-11.4426975-2.85298492"
            />
        </svg>
    );
};

export default AverageFxChart;
