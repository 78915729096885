import * as React from 'react';

const SvgPerson = (props: any) => (
    <svg
        width="8"
        height="10"
        viewBox="0 0 8 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.48215 0.0923633C0.778213 -0.0504736 1.1346 -0.0262119 1.40555 0.155225L7.62777 4.32189C7.86136 4.47832 8 4.73087 8 5C8 5.26913 7.86136 5.52168 7.62777 5.67811L1.40555 9.84477C1.1346 10.0262 0.778213 10.0505 0.48215 9.90764C0.186087 9.7648 0 9.47882 0 9.16666V0.833336C0 0.52118 0.186087 0.2352 0.48215 0.0923633Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgPerson;
