import {PATH} from 'constants/common';
import {ICardListProp} from 'components/ui/Card/CardListProp';

export const PRODUCT_TOUR_PARAM = 'product_tour_id';

export const PRODUCT_TOURS: ICardListProp[] = [
    {
        title: 'Accounts',
        path: PATH.ACCOUNTS,
        links: {
            de: 369213,
            en: 367453,
            cs: 373140,
            fr: 370375,
            it: 370341,
            pl: 373244,
        },
        icon: 'accountsNew',
    },
    {
        title: 'Payments',
        path: PATH.PAYMENTS,
        links: {
            de: 369221,
            en: 367480,
            cs: 373145,
            fr: 370382,
            it: 370344,
            pl: 373250,
        },
        icon: 'paymentsNew',
    },
    {
        title: 'Currency exchange',
        path: PATH.CURRENCIES,
        links: {
            de: 369225,
            en: 367496,
            cs: 373148,
            fr: 370385,
            it: 370346,
            pl: 373259,
        },
        icon: 'currencyExchange',
    },
    {
        title: 'Contacts',
        path: PATH.CONTACTS,
        links: {
            de: 369233,
            en: 367470,
            cs: 373184,
            fr: 370392,
            it: 370353,
            pl: 373286,
        },
        icon: 'contacts',
    },
    {
        title: 'Tools (Alerts)',
        path: PATH.TOOLS,
        links: {
            de: 369236,
            en: 367463,
            cs: 373191,
            fr: 370425,
            it: 370359,
            pl: 373291,
        },
        icon: 'toolsNew',
    },
];
