const ChevronLeft = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M14 17.654 8.346 12 14 6.347 15.054 7.4l-4.6 4.6 4.6 4.6L14 17.654Z"
        />
    </svg>
);

export default ChevronLeft;
