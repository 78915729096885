import {useAuth} from 'context/auth-context';
import {ConfirmationModal} from 'components/modals';
import Form, {FormOnSubmit} from 'components/ui/Form/Form/Form';
import useModal from 'components/ui/ModalContainer/useModal';
import {CrpVariants} from 'constants/businessPartnerAttributes';
import OnboardingOverlay from 'components/ui/Overlays/OnboardingOverlay/OnboardingOverlay';
import AlertModal from 'components/modals/AlertModal/AlertModal';
import {crpFormValidationSchema} from 'form/validation/businessPartner';
import CrpForm from 'components/pages/OnboardingPage/components/CrpForm';
import useCrpTable from 'components/pages/OnboardingPage/components/hooks/useCrpTable';
import CrpContractSignerInfo from 'components/pages/OnboardingPage/components/Infos/CrpContractSignerInfo';
import {IDefaultStepProps} from '../useOnboarding';
import StepTitle from '../components/StepTitle';
import NextStepButton from '../components/NextStepButton';
import CrpTable from '../components/CrpTable';

import styles from './Steps.module.scss';

const CrpSigner = ({
    isCrpLoading,
    crpList,
    onSuccess,
    inReview,
    currentStep,
    legalType,
}: IDefaultStepProps) => {
    const {closeModal, openModalWithContent} = useModal();
    const {handleCrpEdit} = useCrpTable({crpList, currentStep});

    const {user} = useAuth();

    const checkAllCrps = () => {
        const contractSigners = crpList.filter(crp => crp.contractSigner);
        let result = true;

        contractSigners.forEach(signer => {
            try {
                crpFormValidationSchema(
                    CrpVariants.CONTRACT_SIGNER,
                    signer.confidential,
                    legalType,
                ).validateSync({...signer.crp, ...signer});
            } catch (err) {
                result = false;
                openModalWithContent(
                    'crpForm',
                    <OnboardingOverlay fullHeight>
                        <CrpForm
                            legalType={legalType}
                            variant={CrpVariants.CONTRACT_SIGNER}
                            handleSubmit={(crpValues, setCrpError) => {
                                handleCrpEdit(
                                    CrpVariants.CONTRACT_SIGNER,
                                    signer.crp.user === user['@id'],
                                    crpValues,
                                    true,
                                    signer,
                                    setCrpError,
                                );
                            }}
                            crpBpId={signer['@id']}
                            isLoggedInUser={signer.crp.user === user['@id']}
                            inReview={inReview}
                        />
                    </OnboardingOverlay>,
                );
            }
        });

        return result;
    };

    const checkCrpsBeforeSubmit: FormOnSubmit<never> = () => {
        if (!checkAllCrps) return;

        const self = crpList.find(s => s.crp.user === user['@id']);
        const contractSigners = crpList.filter(crpBp => crpBp.contractSigner);

        if (!self || (!self.isOwner && !self.hasSigningRights)) {
            openModalWithContent(
                'confirmation',
                <OnboardingOverlay hideCloseButton>
                    <ConfirmationModal
                        title="Warning"
                        description="You didn't select yourself for any of the beneficial owner(s) or persons, who will sign the contract. Is this right?"
                        onClose={() => closeModal()}
                        onSubmit={() => {
                            onSuccess();
                            closeModal();
                        }}
                    />
                </OnboardingOverlay>,
            );
        } else if (
            contractSigners.length < 2 &&
            contractSigners.some(
                contractSigner => contractSigner.collectiveSigningRight,
            )
        ) {
            openModalWithContent(
                'alert',
                <OnboardingOverlay hideCloseButton>
                    <AlertModal
                        title="Warning"
                        variant="warning"
                        description={
                            <span>
                                You have marked online 1 person with collective
                                signing rights. Please select 1 more person or
                                change&nbsp;
                                <var data-var="crp-name">
                                    {contractSigners[0].crp.firstName}&nbsp;
                                    {contractSigners[0].crp.lastName}
                                </var>
                                &apos;s right from collective to single
                                signature.
                            </span>
                        }
                        onClose={() => closeModal()}
                    />
                </OnboardingOverlay>,
            );
        } else {
            onSuccess();
        }
    };

    const hasContractSigners = crpList?.some(crp => crp.contractSigner);

    return (
        <Form onSubmit={checkCrpsBeforeSubmit}>
            <div className={styles.form}>
                <StepTitle
                    title="Contract signers"
                    subTitle="Please define the person(s) who will sign the contract. The selected person(s) will receive an email invitation to verify their identity and sign the contract."
                    info={<CrpContractSignerInfo />}
                />
                <div className={styles.labelContainer}>
                    <span className={styles.label}>
                        Select one person with an individual or two with a
                        collective signing right.
                    </span>
                </div>
            </div>
            <CrpTable
                crpList={crpList}
                isCrpLoading={isCrpLoading}
                variant={CrpVariants.CONTRACT_SIGNER}
                currentStep={currentStep}
                legalType={legalType}
                inReview={inReview}
            />
            <div className={styles.form}>
                <NextStepButton disabled={!hasContractSigners} />
            </div>
        </Form>
    );
};

export default CrpSigner;
