const NewContactIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 26 15"
        {...props}
    >
        <path
            fill="currentColor"
            d="M6 7.5c1.906 0 3.5-1.674 3.5-3.75C9.5 1.708 7.906 0 6 0 4.062 0 2.5 1.708 2.5 3.75 2.5 5.826 4.063 7.5 6 7.5Zm2.375 1.071h-.25A4.67 4.67 0 0 1 6 9.107a4.7 4.7 0 0 1-2.156-.536h-.25c-2 0-3.594 1.742-3.594 3.884v.938C0 14.297.656 15 1.5 15h9c.813 0 1.5-.703 1.5-1.607v-.938c0-2.143-1.625-3.884-3.625-3.884ZM14 5h-3v1.5h3v3h1.5v-3h3V5h-3V2H14v3Z"
        />
    </svg>
);

export default NewContactIcon;
