const Contacts = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M6.5 16.02c.828 0 1.634.093 2.417.281.784.188 1.561.483 2.333.884v-9.83a8.201 8.201 0 0 0-2.28-1.03 9.457 9.457 0 0 0-4.151-.202 8.705 8.705 0 0 0-1.608.462.303.303 0 0 0-.163.11.28.28 0 0 0-.048.16v9.457c0 .09.032.155.096.197a.216.216 0 0 0 .212.014 9.419 9.419 0 0 1 1.502-.373 10.37 10.37 0 0 1 1.69-.13Zm6.25 1.165a10.25 10.25 0 0 1 2.333-.884 10.318 10.318 0 0 1 2.417-.281c.6 0 1.163.043 1.69.13a9.418 9.418 0 0 1 1.502.373.216.216 0 0 0 .212-.014c.064-.042.096-.107.096-.197V6.854a.258.258 0 0 0-.048-.154.37.37 0 0 0-.164-.115 8.706 8.706 0 0 0-1.606-.463 9.458 9.458 0 0 0-4.151.202 8.2 8.2 0 0 0-2.281 1.03v9.831Zm-.75 2.2a9.784 9.784 0 0 0-2.61-1.379 8.745 8.745 0 0 0-2.89-.486 7.998 7.998 0 0 0-3.5.8.98.98 0 0 1-1.017-.078 1.042 1.042 0 0 1-.483-.919V6.466c0-.216.055-.418.166-.607a.95.95 0 0 1 .48-.409 9.299 9.299 0 0 1 2.116-.731A10.58 10.58 0 0 1 6.5 4.48a10.3 10.3 0 0 1 2.852.4c.928.265 1.81.658 2.648 1.177.837-.52 1.72-.912 2.648-1.178A10.3 10.3 0 0 1 17.5 4.48c.758 0 1.503.08 2.237.238a9.3 9.3 0 0 1 2.117.731c.209.084.369.22.48.41.11.188.166.39.166.606v10.857c0 .397-.167.7-.502.91a1.064 1.064 0 0 1-1.056.067 8.09 8.09 0 0 0-1.678-.582 7.904 7.904 0 0 0-1.764-.198c-.994 0-1.957.162-2.89.486a9.786 9.786 0 0 0-2.61 1.38Z"
        />
    </svg>
);

export default Contacts;
