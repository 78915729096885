import {useQuery} from '@tanstack/react-query';
import {useClient} from 'context/auth-context';

export interface IParameter {
    name: string;
    value: {[key: string]: string}[];
}

const parametersQueryConfig = {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
};

const useParameters = () => {
    const client = useClient();

    return useQuery<IParameter[]>({
        queryKey: ['parameters'],
        queryFn: () => client('/web_api/parameters', {}),
    });
};

export {useParameters};
