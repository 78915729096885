const AccountBalanceWallet = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <g>
            <path
                fill="currentColor"
                d="M16 13.5c.433 0 .792-.141 1.075-.425.283-.283.425-.641.425-1.075 0-.433-.142-.791-.425-1.075-.283-.283-.642-.425-1.075-.425-.433 0-.792.142-1.075.425-.283.284-.425.642-.425 1.075 0 .434.142.792.425 1.075.283.284.642.425 1.075.425Zm-10.692 7c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.282V5.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h13.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283V8.03H19V5.31a.3.3 0 0 0-.087-.222.3.3 0 0 0-.22-.087H5.307a.3.3 0 0 0-.221.087.3.3 0 0 0-.087.221v13.385a.3.3 0 0 0 .087.22.3.3 0 0 0 .22.087h13.385a.3.3 0 0 0 .221-.086.3.3 0 0 0 .087-.221v-2.721h1.5v2.72c0 .506-.175.933-.525 1.283-.35.35-.778.525-1.283.525H5.308Zm8-4c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.282V9.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h6.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v5.385c0 .505-.175.932-.525 1.282-.35.35-.778.525-1.283.525h-6.384Zm6.384-1.5a.3.3 0 0 0 .221-.086.3.3 0 0 0 .087-.221V9.308a.3.3 0 0 0-.087-.221.3.3 0 0 0-.22-.087h-6.385a.3.3 0 0 0-.222.087.3.3 0 0 0-.086.221v5.385a.3.3 0 0 0 .086.22.3.3 0 0 0 .222.087h6.384Z"
            />
        </g>
    </svg>
);

export default AccountBalanceWallet;
