import React, {useEffect, useState} from 'react';

import {ICountry} from 'types/api';
import {useCountries} from 'api/countries';
import {translate} from 'helpers/localize';
import AccordionItem, {Accordion} from 'components/ui/Accordion/Accordion';
import Button from 'components/ui/Button';
import CountryAutoComplete from 'components/ui/Form/AutoComplete/CountryAutoComplete';
import Checkbox from 'components/ui/Form/Checkbox/Checkbox';
import TextInput from 'components/ui/Form/TextInput/TextInput';
// import {filterNotAllowedCountries} from 'helpers/countries';

import {useFormContext} from 'react-hook-form';
import {countryValidityStore} from 'components/pages/NewCardPage/components/CountryValidity/countryValidityStore';
import styles from './CountryValidity.module.scss';

export interface ICountryValidityProps {
    saveCountriesByContinent: (allowedCountries: ICountry[]) => void;
    cancel: () => void;
    title?: string;
    description?: string;
    defaultOpen?: 'single' | 'multi';
    defaultValue?: ICountry[];
}
const CountryValidity = ({
    cancel,
    saveCountriesByContinent,
    title = 'Country validity',
    description = 'Specify in which countries your amnis debit card can be used for payments at merchants as well as ATMs.',
    defaultOpen = 'multi',
}: ICountryValidityProps) => {
    const countriesByContinent = countryValidityStore(
        state => state.countriesByContinent,
    );
    const enabledCountries = countryValidityStore(
        state => state.enabledCountries,
    );
    const setCountriesEnabled = countryValidityStore(
        state => state.setCountriesEnabled,
    );
    const toggleContinent = countryValidityStore(
        state => state.toggleContinent,
    );
    const toggleCountry = countryValidityStore(state => state.toggleCountry);
    const setCountriesByContinent = countryValidityStore(
        state => state.setCountriesByContinent,
    );

    const {countries: allCountries, isLoading: countriesIsLoading} =
        useCountries();
    const {watch} = useFormContext();

    const onlyCountries = watch('onlyCountries');

    useEffect(() => {
        setCountriesByContinent(allCountries);
    }, [allCountries, setCountriesByContinent]);

    const getContinentCheckBoxValue = (continent: string) => {
        return countriesByContinent[continent].every(country =>
            enabledCountries.includes(country['@id']),
        );
    };

    const onCountryCheckboxChange = (country: ICountry) => {
        toggleCountry(country['@id']);
    };

    const onContinentCheckboxChange = (continent: string, checked: boolean) => {
        toggleContinent(continent, checked);
    };

    const getActiveCountriesNumberByContinent = (continent: string) => {
        let nrOfSelected = 0;
        countriesByContinent[continent].forEach(country => {
            if (enabledCountries.includes(country['@id'])) nrOfSelected += 1;
        });
        return nrOfSelected;
    };

    const [first, setFirst] = useState(defaultOpen === 'single');
    const [second, setSecond] = useState(defaultOpen === 'multi');

    const getFilteredCountries = (continent: string, searchString: string) => {
        const countryList = countriesByContinent[continent];
        return countryList.filter(c =>
            c.name
                .toLocaleLowerCase()
                .includes(searchString.toLocaleLowerCase()),
        );
    };

    const [searchString, setSearchString] = useState('');

    return (
        <div className={styles.container}>
            <span className={styles.title}>{title}</span>
            <span className={styles.description}>{description}</span>
            <div className={styles.box}>
                <Accordion toggleMode="single">
                    <AccordionItem
                        openStatus={defaultOpen === 'single'}
                        toggleType="title"
                        accordionKey="onlyFew"
                        title={
                            <>
                                <div className={styles.radioButtonContainer}>
                                    <input
                                        type="radio"
                                        name="countryValidityRadioGroup"
                                        className={
                                            styles.countryValidityRadioGroupId
                                        }
                                        checked={first}
                                    />
                                </div>

                                <span>Only in the following countries</span>
                            </>
                        }
                        titleClass={styles.parentAccordionTitle}
                        body={
                            <div
                                className={styles.countryAutoCompleteContainer}
                            >
                                <CountryAutoComplete
                                    name="onlyCountries"
                                    placeholder="Select countries"
                                    isLoading={countriesIsLoading}
                                    options={allCountries}
                                    onChange={values =>
                                        setCountriesEnabled(
                                            values as ICountry[],
                                        )
                                    }
                                    isMulti
                                />
                            </div>
                        }
                        openStatusChanged={status => {
                            if (status) {
                                setFirst(true);
                                setSecond(false);
                            }
                        }}
                    />
                    <div className={styles.separator} />
                    <AccordionItem
                        toggleType="title"
                        accordionKey="inAllCountriesAccordionKey"
                        openStatus={defaultOpen === 'multi'}
                        title={
                            <>
                                <div className={styles.radioButtonContainer}>
                                    <input
                                        type="radio"
                                        name="countryValidityRadioGroup"
                                        checked={second}
                                        className={
                                            styles.countryValidityRadioGroupId
                                        }
                                    />
                                </div>
                                <div
                                    className={
                                        styles.allCountriesTitleTextContainer
                                    }
                                >
                                    In all countries
                                </div>
                            </>
                        }
                        titleRightDescriptionText={`${
                            enabledCountries.length
                        }/${allCountries.length} ${translate('active')}`}
                        titleClass={styles.parentAccordionTitle}
                        body={
                            <div className={styles.inAllCountriesBodyContainer}>
                                <span>
                                    If you don`t want to allow payments in one
                                    continent or country, you can deselect them
                                    here
                                </span>

                                <Accordion toggleMode="single">
                                    {Object.keys(countriesByContinent).map(
                                        key => (
                                            <AccordionItem
                                                toggleType="title"
                                                accordionKey={key}
                                                title={
                                                    <div
                                                        className={
                                                            styles.accordionTitle
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.accordionTitlePart
                                                            }
                                                        >
                                                            <Checkbox
                                                                name="check"
                                                                checked={getContinentCheckBoxValue(
                                                                    key,
                                                                )}
                                                                onChange={e => {
                                                                    onContinentCheckboxChange(
                                                                        key,
                                                                        e.target
                                                                            .checked,
                                                                    );
                                                                }}
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                }}
                                                                className={
                                                                    styles.checkbox
                                                                }
                                                            />
                                                            <span>{key}</span>
                                                        </div>
                                                    </div>
                                                }
                                                titleRightDescriptionText={`${getActiveCountriesNumberByContinent(
                                                    key,
                                                )}/${
                                                    countriesByContinent[key]
                                                        .length
                                                } ${translate('active')}`}
                                                titleClass={
                                                    styles.accordionTitle
                                                }
                                                body={
                                                    <>
                                                        <TextInput
                                                            name="search"
                                                            onChange={v => {
                                                                setSearchString(
                                                                    v.target
                                                                        .value,
                                                                );
                                                            }}
                                                            placeholder="Search for country"
                                                        />
                                                        <div
                                                            className={
                                                                styles.accordionBody
                                                            }
                                                        >
                                                            {getFilteredCountries(
                                                                key,
                                                                searchString,
                                                            ).map(country => (
                                                                <div
                                                                    className={
                                                                        styles.countryRow
                                                                    }
                                                                    key={`${key}${country['@id']}`}
                                                                >
                                                                    <Checkbox
                                                                        name="check"
                                                                        checked={enabledCountries.includes(
                                                                            country[
                                                                                '@id'
                                                                            ],
                                                                        )}
                                                                        onChange={() => {
                                                                            onCountryCheckboxChange(
                                                                                country,
                                                                            );
                                                                        }}
                                                                        className={
                                                                            styles.checkbox
                                                                        }
                                                                    />
                                                                    <span>
                                                                        {
                                                                            country.name
                                                                        }
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                }
                                            />
                                        ),
                                    )}
                                </Accordion>
                            </div>
                        }
                        openStatusChanged={status => {
                            if (status) {
                                setFirst(false);
                                setSecond(true);
                            }
                        }}
                    />
                </Accordion>
            </div>
            <div className={styles.buttonRow}>
                <Button
                    onClick={() => {
                        let allowedCountries: ICountry[] = [];

                        if (first) {
                            allowedCountries = onlyCountries?.map((c: any) =>
                                allCountries.find(ac => ac['@id'] === c),
                            );
                        }
                        if (second) {
                            allowedCountries = enabledCountries.map(
                                c => allCountries.find(ac => ac['@id'] === c)!,
                            );
                        }
                        saveCountriesByContinent(allowedCountries);
                    }}
                >
                    Save settings
                </Button>
                <Button variant="text" onClick={cancel}>
                    Cancel
                </Button>
            </div>
        </div>
    );
};

export default CountryValidity;
