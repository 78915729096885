import React, {useEffect, useState} from 'react';
import {IDefaultStepProps} from 'components/pages/OnboardingPage/useOnboarding';
import NextStepButton from 'components/pages/OnboardingPage/components/NextStepButton';
import StepTitle from 'components/pages/OnboardingPage/components/StepTitle';
import Form, {FormOnSubmit, WithFormError} from 'components/ui/Form/Form/Form';
import CountryValidity from 'components/pages/NewCardPage/components/CountryValidity/CountryValidity';
import {useCountries} from 'api/countries';
import Button from 'components/ui/Button';
import classNames from 'classnames';
import {findOnboardingStepByNumber} from 'components/pages/OnboardingPage/onboardingSteps';
import PaymentCustomers from 'components/pages/OnboardingPage/components/PaymentCustomers';
import {useForm} from 'react-hook-form';
import {useUpdateBusinessPartnerOnboarding} from 'api/business-partner';
import {handleTypedFormError} from 'helpers/handleFormError';
import {v4 as uuidv4} from 'uuid';
import {
    IncomingAndOutgoingPaymentsFormValues,
    incomingAndOutgoingPaymentsValidationSchema,
} from 'form/validation/businessPartner';
import {yupResolver} from '@hookform/resolvers';
import {countryValidityStore} from 'components/pages/NewCardPage/components/CountryValidity/countryValidityStore';
import {
    IcomingAndOutgoingPayments,
    extractCustomerData,
    findCountriesByIri,
} from 'components/pages/OnboardingPage/steps/IncomingPayments';
import styles from './Steps.module.scss';

const OutgoingPayments = (props: IDefaultStepProps) => {
    const {
        onSuccess,
        currentStep,
        setActiveBusinessPartner,
        activeBusinessPartner,
    } = props;

    const step = findOnboardingStepByNumber(currentStep)
        ?.type as IcomingAndOutgoingPayments;

    const {countries} = useCountries();
    const enableCountries = countryValidityStore(
        state => state.setCountriesEnabled,
    );
    const enabledCountres = countryValidityStore(
        state => state.enabledCountries,
    );
    const resetState = countryValidityStore(state => state.resetState);
    const [showCountryValidity, setShowCountryValidity] = useState(false);

    useEffect(() => {
        if (
            countries &&
            activeBusinessPartner.gwgInfo?.outgoingPaymentCountries?.length
        ) {
            enableCountries(
                findCountriesByIri(
                    countries,
                    activeBusinessPartner.gwgInfo?.outgoingPaymentCountries,
                ),
            );
        } else if (activeBusinessPartner.countryOfIncorporation) {
            enableCountries(
                findCountriesByIri(countries, [
                    activeBusinessPartner.countryOfIncorporation,
                ]),
            );
        }

        return () => resetState();
    }, [activeBusinessPartner, countries, step, enableCountries, resetState]);

    const {mutate: addAdditionalInfo, isPending: isLoading} =
        useUpdateBusinessPartnerOnboarding();
    const formMethods = useForm<
        WithFormError<IncomingAndOutgoingPaymentsFormValues>
    >({
        defaultValues: {
            onlyCountries: activeBusinessPartner.gwgInfo
                ?.outgoingPaymentCountries.length
                ? activeBusinessPartner.gwgInfo?.outgoingPaymentCountries
                : [activeBusinessPartner.countryOfIncorporation],
            gwgInfo: {
                type: step,
                outgoingPaymentCustomers: activeBusinessPartner?.gwgInfo
                    ?.outgoingPaymentCustomers.length
                    ? activeBusinessPartner?.gwgInfo?.outgoingPaymentCustomers
                    : [
                          {
                              name: '',
                              website: '',
                              country: '',
                              percentage: '',
                              uniqueId: uuidv4(),
                          },
                          {
                              name: '',
                              website: '',
                              country: '',
                              percentage: '',
                              uniqueId: uuidv4(),
                          },
                          {
                              name: '',
                              website: '',
                              country: '',
                              percentage: '',
                              uniqueId: uuidv4(),
                          },
                      ],
            },
        },
        resolver: yupResolver(incomingAndOutgoingPaymentsValidationSchema),
        shouldUnregister: false,
    });

    const {register} = formMethods;
    register('gwgInfo.type', {required: true});

    const handleSubmit: FormOnSubmit<IncomingAndOutgoingPaymentsFormValues> = (
        data,
        setError,
    ) => {
        const customers = extractCustomerData(
            data.gwgInfo.outgoingPaymentCustomers || [],
            countries,
        );

        if (!Array.isArray(customers)) {
            setError('gwgInfo.outgoingPaymentCustomers', {
                message: customers.error,
            });
            return;
        }
        addAdditionalInfo(
            {
                gwgInfo: {
                    ...data.gwgInfo,
                    id: activeBusinessPartner.gwgInfo?.id,
                    outgoingPaymentCountries: enabledCountres,
                    outgoingPaymentCustomers: customers,
                },
                step: findOnboardingStepByNumber(currentStep)?.type,
                businessPartnerId: activeBusinessPartner['@id'],
            },
            {
                onSuccess: response => {
                    setActiveBusinessPartner(response);
                    onSuccess({refetchCrpList: true});
                },
                onError: (requestErrors: any) => {
                    handleTypedFormError(requestErrors, setError, data);
                },
            },
        );
    };

    return (
        <>
            <StepTitle title="Tell us about your outgoing payments" />
            <Form<IncomingAndOutgoingPaymentsFormValues>
                onSubmit={handleSubmit}
                formMethods={formMethods}
            >
                {showCountryValidity ? (
                    <div className={styles.form}>
                        <CountryValidity
                            title=""
                            description="To which countries do your payments go?"
                            cancel={() => setShowCountryValidity(false)}
                            saveCountriesByContinent={_countries => {
                                setShowCountryValidity(false);
                            }}
                            defaultOpen={
                                enabledCountres?.length < 10
                                    ? 'single'
                                    : 'multi'
                            }
                        />
                    </div>
                ) : (
                    <>
                        <div className={styles.form}>
                            <p className={styles.label}>
                                To which countries do your payments go?
                            </p>
                            <div
                                className={classNames(
                                    styles.countryValidity,
                                    styles.dark,
                                    styles.rounded,
                                    styles.large,
                                )}
                            >
                                <span>Country validity</span>
                                <Button
                                    onClick={() => {
                                        setShowCountryValidity(true);
                                    }}
                                    variant="text"
                                >
                                    {enabledCountres.length ===
                                    countries.length ? (
                                        'All countries (edit selection)'
                                    ) : (
                                        <>
                                            <var data-var="nrOfAllowedCountries">
                                                {enabledCountres?.length}
                                            </var>
                                            &nbsp;
                                            {enabledCountres?.length === 1
                                                ? 'country'
                                                : 'countries'}
                                            &nbsp;(edit selection)
                                        </>
                                    )}
                                </Button>
                            </div>
                        </div>
                        <PaymentCustomers name="outgoingPaymentCustomers" />
                        <div className={styles.form}>
                            <NextStepButton loading={isLoading} />
                        </div>
                    </>
                )}
            </Form>
        </>
    );
};

export default OutgoingPayments;
