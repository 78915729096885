import {useFieldArray, useFormContext} from 'react-hook-form';
import TextInput from 'components/ui/Form/TextInput/TextInput';
import CountryAutoComplete from 'components/ui/Form/AutoComplete/CountryAutoComplete';
import {useCountries} from 'api/countries';
import Button from 'components/ui/Button';
import IconSVG from 'components/ui/Icon/IconSVG';
import FileUploadButton from 'components/ui/Form/FileUploadButton/FileUploadButton';
import useFileUploadStatuses, {
    isNewFileUploadStatus,
} from 'components/ui/Form/FileUploadButton/useFileUploadStatuses';
import {useBusinessPartner} from 'context/business-partner-context';
import {v4 as uuidv4} from 'uuid';
import {PaymentCustomer} from 'types/api';
import {
    useDownloadBusinessPartnerDocuments,
    useUploadBusinessPartnerDocument,
} from 'api/business-partner';
import {IDocument} from 'types/documents';
import classNames from 'classnames';
import NumberInput from 'components/ui/Form/NumberInput/NumberInput';
import {MISSING_PAYMENT_CUSTOMER_ERROR_KEY} from 'form/validation/businessPartner';
import styles from './PaymentCustomers.module.scss';

interface IProps {
    name: 'incomingPaymentCustomers' | 'outgoingPaymentCustomers';
}

const CONSTANTS = {
    DOCUMENT_CATEGORY_MAPPING: {
        incomingPaymentCustomers: 'incoming_payment_customer',
        outgoingPaymentCustomers: 'outgoing_payment_customer',
    },
    TITLE_MAPPING: {
        incomingPaymentCustomers:
            'Please provide the names and location of the company or person for incoming payments.',
        outgoingPaymentCustomers:
            'Please provide the names and location of the company or person for outgoing payments.',
    },
    ERROR_TEXT_MAPPING: {
        incomingPaymentCustomers: 'Please provide at least one main customer.',
        outgoingPaymentCustomers:
            'Please provide at least one main supplier/partner.',
    },
    ADD_ROW_MAPPING: {
        incomingPaymentCustomers: 'Add customer',
        outgoingPaymentCustomers: 'Add Supplier/Partner',
    },
};

const PaymentCustomers = ({name}: IProps) => {
    const {activeBusinessPartner} = useBusinessPartner();
    const {updateStatus, uploadStatuses} = useFileUploadStatuses();
    const {countries} = useCountries();
    const {control, getValues, register, errors, clearErrors, watch} =
        useFormContext();
    const {fields, append} = useFieldArray<
        PaymentCustomer & {document?: IDocument}
    >({
        control,
        name: `gwgInfo.${name}`,
    });

    const {data: bpDocuments} = useDownloadBusinessPartnerDocuments(
        activeBusinessPartner['@id'],
    );

    const {mutate: uploadBpDocument} = useUploadBusinessPartnerDocument();

    const handleFileUpload = (files: FileList, index: number) => {
        const customer = getValues()?.gwgInfo[name][index] as PaymentCustomer;
        const file = files[0];
        if (
            !uploadStatuses.find(
                f =>
                    f.name === file.name &&
                    isNewFileUploadStatus(f) &&
                    f.document === file,
            )
        ) {
            updateStatus({
                document: file,
                status: 'loading',
                name: file.name,
            });
            uploadBpDocument(
                {
                    activeBpId: activeBusinessPartner['@id'],
                    files: Array.from(files),
                    type: 'onboarding',
                    category: CONSTANTS.DOCUMENT_CATEGORY_MAPPING[name],
                    uniqueId: customer.uniqueId,
                },
                {
                    onSuccess: data => {
                        updateStatus({
                            document: file,
                            name: file.name,
                            status: 'uploaded',
                        });
                    },
                    onError: (errorResponse: any) => {
                        updateStatus({
                            document: file,
                            status: 'error',
                            name: file.name,
                            errorMessage:
                                errorResponse['hydra:description'] ||
                                'Something went wrong',
                        });
                    },
                },
            );
        }
    };

    const rows = watch(`gwgInfo.${name}`);

    const hasHighRisk = rows.some(
        (field: any) =>
            countries?.find(country => country['@id'] === field.country)
                ?.riskLevel === 'HIGH',
    );

    return (
        <div className={styles.container}>
            <p className={styles.label}>{CONSTANTS.TITLE_MAPPING[name]}</p>
            <div
                className={classNames(styles.wrapper, {
                    [styles.error]: !!errors?.gwgInfo?.[name],
                })}
            >
                <div className={classNames(styles.titleRow, styles.row)}>
                    <div className={styles.inputField}>Company name</div>
                    <div className={styles.inputField}>Country</div>
                    {hasHighRisk ? (
                        <>
                            <div className={styles.percentageInputContainer}>
                                % of payment volume
                            </div>
                            <div className={styles.inputField}>Website</div>
                            <div className={styles.inputField}>
                                Invoice or contract files
                            </div>
                        </>
                    ) : null}
                </div>
                {fields.map((item, index) => {
                    const isHighRiskCountry =
                        countries?.find(
                            country =>
                                country['@id'] ===
                                getValues(`gwgInfo.${name}.${index}.country`),
                        )?.riskLevel === 'HIGH';

                    const customerDocument = bpDocuments?.find(
                        doc => doc.uniqueId === item.uniqueId,
                    );

                    return (
                        <div className={styles.row} key={item.id}>
                            <input
                                name={`gwgInfo.${name}.${index}.uniqueId`}
                                defaultValue={item.uniqueId}
                                ref={register}
                                readOnly
                                hidden
                            />
                            <TextInput
                                name={`gwgInfo.${name}.${index}.name`}
                                className={styles.inputField}
                                defaultValue={item.name}
                                placeholder="Company name"
                                onChange={() => clearErrors()}
                            />
                            <CountryAutoComplete
                                name={`gwgInfo.${name}.${index}.country`}
                                options={countries}
                                containerClassname={styles.inputField}
                                menuPosition="fixed"
                                placeholder="Country"
                                onChange={() => clearErrors()}
                            />
                            {isHighRiskCountry ? (
                                <>
                                    <NumberInput
                                        name={`gwgInfo.${name}.${index}.percentage`}
                                        containerClassName={
                                            styles.percentageInputContainer
                                        }
                                        className={styles.percentageInput}
                                        decimalScale={0}
                                        placeholder="0"
                                    />
                                    <div className={styles.percentageSign}>
                                        %
                                    </div>
                                    <TextInput
                                        name={`gwgInfo.${name}.${index}.website`}
                                        placeholder="Website"
                                        behavior="website"
                                        defaultValue={item.website}
                                        className={styles.inputField}
                                        onChange={() => clearErrors()}
                                    />
                                    <FileUploadButton
                                        name={`gwgInfo.${name}.${index}.document`}
                                        containerClassname={styles.inputField}
                                        hideHelperText
                                        uploadStatuses={uploadStatuses}
                                        updateStatus={updateStatus}
                                        uploadedDocument={
                                            customerDocument
                                                ? {
                                                      id: customerDocument[
                                                          '@id'
                                                      ],
                                                      name:
                                                          customerDocument.originalFileName ??
                                                          '',
                                                  }
                                                : undefined
                                        }
                                        onChange={e => {
                                            handleFileUpload(
                                                e.target.files!,
                                                index,
                                            );
                                            clearErrors();
                                        }}
                                    />
                                </>
                            ) : null}
                        </div>
                    );
                })}

                {errors?.gwgInfo?.[name] &&
                !Array.isArray(errors?.gwgInfo?.[name]) ? (
                    <p className={styles.error}>
                        {errors?.gwgInfo?.[name].message ===
                        MISSING_PAYMENT_CUSTOMER_ERROR_KEY
                            ? CONSTANTS.ERROR_TEXT_MAPPING[name]
                            : errors?.gwgInfo?.[name].message}
                    </p>
                ) : null}
            </div>
            <Button
                className={styles.button}
                icon={<IconSVG name="plus" />}
                onClick={() => {
                    append({
                        name: '',
                        country: '',
                        website: '',
                        uniqueId: uuidv4(),
                    });
                }}
            >
                {CONSTANTS.ADD_ROW_MAPPING[name]}
            </Button>
        </div>
    );
};

export default PaymentCustomers;
