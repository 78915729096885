import * as React from 'react';

const SvgFee = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="M21 20.5H3v-2h18zM21 8.5H3v-2h18zM18 5.5H6v-2h12zM8 9.5v8H6v-8zM13 9.5v8h-2v-8zM18 9.5v8h-2v-8z"
        />
    </svg>
);

export default SvgFee;
