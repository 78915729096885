import classNames from 'classnames';
import AccordionItem from 'components/ui/Accordion/Accordion';
import CheckBoxGroup from 'components/ui/Form/OnboardingCheckBoxGroup/OnboardingCheckBoxGroup';
import TextInput from 'components/ui/Form/TextInput/TextInput';
import {PROFESSIONAL_POSITION_OPTIONS, SIGNER_OPTIONS} from 'constants/common';
import React, {useMemo} from 'react';
import {ICrpBpListItem} from 'api/business-partner';
import AutoComplete from 'components/ui/Form/AutoComplete/AutoComplete';
import styles from '../CrpForm.module.scss';

interface IProps {
    crpData?: ICrpBpListItem;
}

const CrpSignerForm = ({crpData}: IProps) => {
    const signerDone = useMemo(() => {
        return (
            crpData &&
            !!crpData.role &&
            !!crpData.professionalPosition &&
            crpData.collectiveSigningRight !== null
        );
    }, [crpData]);

    return (
        <AccordionItem
            toggleType="title"
            openStatus={!signerDone}
            className={classNames(styles.accordionContainer, {
                [styles.groupDone]: signerDone,
            })}
            accordionKey="signer"
            title={
                <>
                    <span className={styles.groupStatus} />
                    <div className={styles.accordionHeader}>
                        Additional information authorized signatories
                    </div>
                </>
            }
            body={
                <>
                    <CheckBoxGroup
                        options={SIGNER_OPTIONS}
                        label="How are you authorized to sign?"
                        name="collectiveSigningRight"
                        type="radio"
                        variant="dark"
                        size="large"
                    />
                    <AutoComplete
                        name="professionalPosition"
                        label="Professional position"
                        options={PROFESSIONAL_POSITION_OPTIONS}
                        variant="dark"
                        size="large"
                    />
                    <TextInput
                        label="Could you please specify your professional position?"
                        placeholder="CEO"
                        name="role"
                        variant="dark"
                        size="large"
                    />
                </>
            }
        />
    );
};

export default CrpSignerForm;
