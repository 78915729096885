const SearchInsights = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M10 16.02c1.667 0 3.083-.585 4.25-1.753C15.417 13.098 16 11.679 16 10.01c0-1.67-.583-3.088-1.75-4.257C13.083 4.585 11.667 4 10 4c-1.667 0-3.083.585-4.25 1.753C4.583 6.922 4 8.341 4 10.01c0 1.67.583 3.088 1.75 4.257C6.917 15.435 8.333 16.02 10 16.02Zm-.75-3.222v-6.48h1.5v6.48h-1.5Zm-3.423 0V8.27h1.5v4.528h-1.5Zm6.846 0V9.25h1.5v3.548h-1.5Zm7.783 8.702-5.694-5.694a7.55 7.55 0 0 1-2.19 1.247A7.375 7.375 0 0 1 10 17.5c-2.094 0-3.867-.726-5.32-2.179-1.453-1.453-2.18-3.226-2.18-5.32 0-2.092.726-3.866 2.18-5.32 1.452-1.454 3.225-2.18 5.319-2.18 2.093 0 3.866.726 5.32 2.18C16.773 6.132 17.5 7.906 17.5 10a7.417 7.417 0 0 1-1.694 4.752l5.694 5.694-1.044 1.053Z"
        />
    </svg>
);

export default SearchInsights;
