import {enGB, de, fr, it, cs, pl} from 'date-fns/locale';

export const LANGUAGES = ['en', 'de', 'fr', 'it', 'cs', 'pl'] as const;
export type Languages = typeof LANGUAGES[number];
export interface IAdminLanguages {
    id: string;
    name: string;
    code: Languages;
    country: string;
    phoneCode: string;
    locale: Locale;
    defaultCurrency: string;
    faqLanguage: string;
    longFormat: string;
}

export const ADMIN_LANGUAGES: IAdminLanguages[] = [
    {
        id: '/web_api/languages/1',
        code: 'en',
        country: 'us',
        name: 'English',
        phoneCode: 'CH',
        locale: enGB,
        defaultCurrency: 'CHF',
        faqLanguage: 'en',
        longFormat: 'LLLL do yyyy',
    },
    {
        id: '/web_api/languages/2',
        code: 'de',
        country: 'de',
        name: 'Deutsch',
        phoneCode: 'CH',
        locale: de,
        defaultCurrency: 'CHF',
        faqLanguage: 'de',
        longFormat: 'do LLLL yyyy',
    },
    {
        id: '/web_api/languages/3',
        code: 'fr',
        country: 'fr',
        name: 'Français',
        phoneCode: 'CH',
        locale: fr,
        defaultCurrency: 'CHF',
        faqLanguage: 'fr',
        longFormat: 'do LLLL yyyy',
    },
    {
        id: '/web_api/languages/4',
        code: 'it',
        country: 'it',
        name: 'Italiano',
        phoneCode: 'CH',
        locale: it,
        defaultCurrency: 'CHF',
        faqLanguage: 'it',
        longFormat: 'do LLLL yyyy',
    },
    {
        id: '/web_api/languages/5',
        code: 'cs',
        country: 'cz',
        name: 'Czech',
        phoneCode: 'CZ',
        locale: cs,
        defaultCurrency: 'CZK',
        faqLanguage: 'cs',
        longFormat: 'do LLLL yyyy',
    },
    {
        id: '/web_api/languages/6',
        code: 'pl',
        country: 'pl',
        name: 'Polish',
        phoneCode: 'PL',
        locale: pl,
        defaultCurrency: 'PLN',
        faqLanguage: 'en',
        longFormat: 'do LLLL yyyy',
    },
];
