const AttachFile = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1.17 15.775c.091 1.447.685 2.654 1.782 3.622 1.097.967 2.37 1.406 3.816 1.315 1.447-.09 2.654-.685 3.622-1.782l7.148-8.106-.894-.788-7.149 8.106c-.744.844-1.678 1.301-2.802 1.372-1.124.07-2.108-.267-2.952-1.011-.844-.745-1.302-1.679-1.372-2.803-.07-1.123.266-2.107 1.01-2.952l7.15-8.106c.487-.553 1.09-.852 1.809-.898.719-.045 1.355.176 1.908.664.554.488.853 1.092.898 1.811.045.72-.176 1.355-.664 1.909L8.031 15.44c-.223.254-.496.39-.819.41-.322.02-.61-.08-.864-.304a1.154 1.154 0 0 1-.41-.82 1.15 1.15 0 0 1 .305-.863l6.449-7.313-.895-.788-6.448 7.312a2.303 2.303 0 0 0-.602 1.728c.041.65.312 1.196.813 1.638.5.442 1.076.642 1.727.601a2.302 2.302 0 0 0 1.639-.812l6.448-7.313c.696-.79 1.011-1.713.945-2.77-.066-1.058-.494-1.935-1.284-2.631-.789-.696-1.713-1.011-2.77-.945-1.058.067-1.935.494-2.631 1.284L2.485 11.96c-.967 1.097-1.405 2.369-1.315 3.815ZM20 9V7h-2V6h2V4h1v2h2v1h-2v2h-1Z"
            clipRule="evenodd"
        />
    </svg>
);

export default AttachFile;
