const Bell = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M12 20a2 2 0 0 0 2-2h-4a2 2 0 0 0 2 2ZM12 5.918l-.797.161A4.002 4.002 0 0 0 8 10c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C16.134 12.197 16 10.628 16 10a4.002 4.002 0 0 0-3.203-3.92L12 5.917ZM18.22 16c.223.447.482.801.78 1H5c.299-.199.557-.553.78-1C6.68 14.2 7 10.88 7 10c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 17 10c0 .88.32 4.2 1.22 6Z"
        />
    </svg>
);

export default Bell;
