const Edit = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        {...props}
    >
        <path
            fill="currentColor"
            d="M4.295 15.834h1.03l7.717-7.717-1.03-1.03-7.717 7.716v1.03Zm11.42-8.62-2.813-2.787L13.99 3.34c.244-.244.542-.367.892-.367s.648.123.893.367l1.014 1.015c.245.244.372.538.38.88a1.16 1.16 0 0 1-.354.88l-1.1 1.099Zm-.904.916-8.954 8.954H3.045V14.27l8.953-8.953 2.813 2.812Z"
        />
    </svg>
);

export default Edit;
