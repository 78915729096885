import {useCurrencies} from 'api/currencies';
import {useGetBusinessPartnerWallets} from 'api/wallets';
import {useBusinessPartner} from 'context/business-partner-context';

const useSidebarWalletDropdown = () => {
    const {activeBusinessPartner} = useBusinessPartner();
    const {
        data: wallets,
        isLoading: isWalletsLoading,
    } = useGetBusinessPartnerWallets();
    const {data: currencies, isLoading: isCurrenciesLoading} = useCurrencies();
    const currency = currencies?.find(
        c => c['@id'] === activeBusinessPartner.mainCurrency,
    );

    return {
        wallets,
        isLoading: isWalletsLoading || isCurrenciesLoading,
        overallBalance: activeBusinessPartner.availableBalance,
        currency,
    };
};

export default useSidebarWalletDropdown;
