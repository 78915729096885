import * as React from 'react';
// viewBox="0 0 12 12"
const SvgUpArrow = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            d="M10.0035 3.40804L1.41153 12L0 10.5885L8.59097 1.99651H1.01922V0H12V10.9808H10.0035V3.40804Z"
        />
    </svg>
);

export default SvgUpArrow;
