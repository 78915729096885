export const getPrivacyPolicy = (selectedLanguage: string) =>
    `/data-protection${selectedLanguage === 'de' ? '-de' : ''}`;

export const getTermsAndConditions = (selectedLanguage: string) => {
    let suffix = '';
    if (selectedLanguage === 'cs') {
        suffix = '-cz';
    } else if (selectedLanguage === 'de') {
        suffix = '-de';
    }
    return `/terms-and-conditions${suffix}`;
};

export const getDebitCardTerms = (selectedLanguage: string) => {
    let languageSuffix = `-${selectedLanguage}`;
    if (selectedLanguage === 'cs') {
        languageSuffix = '-cz';
    } else if (selectedLanguage === 'en') {
        languageSuffix = '';
    }
    return `/card-terms-and-conditions${languageSuffix}`;
};
