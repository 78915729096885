const Campaign = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M6.604 20.807V14.2H4.803a2.702 2.702 0 0 1-1.984-.819A2.701 2.701 0 0 1 2 11.398V9.529c0-.776.273-1.437.82-1.984a2.702 2.702 0 0 1 1.983-.819h6.473l6.873-4.07V18.27l-6.873-4.07H8.339v6.606H6.604Zm13.46-5.332V5.452c.581.538 1.049 1.245 1.404 2.12S22 9.412 22 10.463c0 1.052-.177 2.016-.532 2.891-.355.876-.823 1.583-1.403 2.12ZM4.804 8.46c-.267 0-.512.112-.734.334-.223.222-.334.467-.334.734v1.869c0 .267.111.511.334.734.222.222.467.333.734.333h6.96l4.65 2.716V5.745l-4.65 2.716h-6.96Z"
        />
    </svg>
);

export default Campaign;
