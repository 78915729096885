import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {devtools} from 'zustand/middleware';

export type ModalKey =
    | 'transactionDetails'
    | 'accountDetails'
    | 'createWallet'
    | 'orderStatus'
    | 'alert'
    | 'confirmation'
    | 'accountSorting'
    | 'paymentGroupMember'
    | 'contactImport'
    | 'createGroup'
    | 'simpleOrder'
    | 'editLimit'
    | 'drawDown'
    | 'compliance'
    | 'crpForm'
    | 'onboardingStepInfo'
    | 'onboardingOverview'
    | 'paymentPicker'
    | 'peerInvite'
    | 'paymentTracking'
    | 'dashboardSorting'
    | 'widgetForm'
    | 'paymentRequestDetails'
    | 'tags'
    | 'addTag'
    | 'automationTypePicker'
    | 'automationDetails'
    | 'dynamicDiscountChart'
    | 'seePin'
    | 'changePin'
    | 'cardDetails'
    | 'activateCard'
    | 'createCardGroup'
    | 'addNewWidget'
    | 'bankAccountForm'
    | 'twoFANotSet'
    | '2FA'
    | 'editCard'
    | 'eeaOnboarding'
    | 'cardOnboarding'
    | 'expense'
    | 'managePeers'
    | 'cobrowse';

type ModalState = {
    open: boolean;
    modalContent: Partial<Record<ModalKey, React.ReactNode>>;
};

type ModalActions = {
    openModalWithContent: (
        modalKey: ModalKey,
        content: React.ReactNode,
    ) => void;
    setOpen: (openState: boolean) => void;
    closeModal: (modalKey?: ModalKey) => void;
};

const defaultState: ModalState = {
    open: false,
    modalContent: {},
};

export const useModalStore = create(
    devtools(
        immer<ModalState & ModalActions>(set => ({
            ...defaultState,
            openModalWithContent: (modalKey, content) => {
                set(state => {
                    state.modalContent[modalKey] = content;
                });
            },
            setOpen: openState => {
                set(state => {
                    state.open = openState;
                });
            },
            closeModal: modalKey => {
                set(state => {
                    if (modalKey) {
                        state.modalContent[modalKey] = null;
                        delete state.modalContent[modalKey];
                    } else {
                        state.modalContent = {};
                    }
                });
            },
        })),
    ),
);
