import React, {MouseEventHandler, useMemo} from 'react';
import classNames from 'classnames';
import {Icons} from 'components/ui/Icon/IconSVGConfig';
import IconSVG from 'components/ui/Icon/IconSVG';
import AmnisSpinner from 'components/ui/AmnisSpinner/AmnisSpinner';
import styles from './AmnisButton.module.scss';

export interface IAmnisButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    className?: string;
    isLoading?: boolean;
    intent?: 'primary' | 'secondary' | 'terciary' | 'input';
    variant?: 'default' | 'error' | 'success' | 'alert' | 'tools' | 'neutral';
    size?: 'large' | 'base' | 'small' | 'xsmall';
    startIcon?: Icons;
    endIcon?: Icons;
    setReferenceElement?: (ref: HTMLButtonElement) => void;
    isDisabled?: boolean;
    ref?: any; // TODO
}

const AmnisButton = React.forwardRef<HTMLButtonElement, IAmnisButtonProps>(
    (
        {
            intent = 'primary',
            variant = 'default',
            size = 'base',
            type = 'button',
            children,
            isLoading,
            className,
            isDisabled,
            startIcon,
            endIcon,
            setReferenceElement,
            ...props
        },
        ref,
    ) => {
        const getStartIcon = useMemo(() => {
            if (isLoading) return <AmnisSpinner />;
            if (startIcon) return <IconSVG customDimensions name={startIcon} />;
            return null;
        }, [isLoading, startIcon]);

        return (
            <button
                className={classNames(
                    styles.button,
                    styles[size],
                    styles[intent],
                    styles[isDisabled ? 'disabled' : variant],
                    className,
                )}
                {...props}
                ref={setReferenceElement || ref}
                type={type}
                disabled={isDisabled}
            >
                {getStartIcon}
                {children}
                {endIcon ? <IconSVG customDimensions name={endIcon} /> : null}
            </button>
        );
    },
);

export default AmnisButton;
