import {useMutation} from '@tanstack/react-query';

import {useClient} from 'context/auth-context';
import {IToken} from 'types/api';

const useGetMercureToken = () => {
    const client = useClient();

    return useMutation<IToken, unknown, string>({
        mutationFn: token =>
            client('/web_api/mercure/token', {token, method: 'GET'}),
    });
};

const useTriggerMercureSuccessTest = () => {
    const client = useClient();

    return useMutation({
        mutationFn: () =>
            client('/web_api/mercure/test-publish', {method: 'GET'}),
    });
};

const useTriggerMercureErrorTest = () => {
    const client = useClient();

    return useMutation({
        mutationFn: () =>
            client('/web_api/mercure/test-publish-error', {method: 'GET'}),
    });
};

export {
    useGetMercureToken,
    useTriggerMercureErrorTest,
    useTriggerMercureSuccessTest,
};
