import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import {ILoginToken, EnableEmailTypes, IUserLogin} from 'types/api';
import {ICrpBpData} from 'api/crp';
import {client} from 'helpers/api-client';
import {useClient} from 'context/auth-context';
import {getItemFromStorage} from 'helpers/localStorage';
import {LOCAL_STORAGE_AUTH_KEY} from 'constants/common';
import {parseJwt} from 'helpers/common';
import useAnalytics from 'hooks/useAnalytics';
import {
    EMAIL_VERIFICATION_CACHE,
    SINGLE_BP_CACHE,
    USER_CACHE,
} from '../constants/cache';
import {PATCH_HEADERS} from './constants';

export type User = {
    '@id': string;
    '@type': string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    crp: string;
    businessPartner?: {
        '@context': string;
        '@id': string;
        '@type': string;
    }[];
    gender: number;
    defaultBusinessPartner?: {
        '@id': 'string';
        '@type': 'string';
    };
    language: string;
    needsIdentification: boolean;
    WIRCustomer: boolean;
    futurae?: string;
    enableEmailTypes: EnableEmailTypes;
    intercomHash?: string;
    emailVerified?: boolean;
    createdAt?: string;
    experimental: boolean;
};

const useGetUser = (
    token?: string | null,
    logout?: (invalidToken?: any) => void,
) => {
    const parsedToken = getItemFromStorage(LOCAL_STORAGE_AUTH_KEY)
        ? parseJwt(getItemFromStorage(LOCAL_STORAGE_AUTH_KEY))
        : null;
    return useQuery({
        queryKey: [USER_CACHE, {userId: parsedToken?.id}],
        queryFn: () =>
            client(`/web_api/users/${parsedToken.id}`, {
                token: token ?? undefined,
                logout,
            }),
        enabled: parsedToken !== null && token !== null,
    });
};

const useUpdateUser = () => {
    const authenticatedClient = useClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: any) =>
            authenticatedClient(data['@id'], {
                data,
                method: 'PATCH',
                ...PATCH_HEADERS,
            }),
        onSuccess: () =>
            queryClient.invalidateQueries({queryKey: [USER_CACHE]}),
    });
};

const useLogin = () => {
    return useMutation<ILoginToken, unknown, IUserLogin>({
        mutationFn: data => {
            return client('/web_api/login', {data, method: 'POST'});
        },
    });
};

const useLoginBexio = () => {
    return useMutation({
        mutationFn: (data: {code: string; state: any}) => {
            return client('/web_api/bexio/authenticate', {
                data,
                method: 'POST',
            });
        },
    });
};

// this call is used only, if the user is already logged in
const useConnectBexio = () => {
    const authenticatedClient = useClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: {
            code: string;
            state: string;
            businessPartner: number;
        }) => {
            return authenticatedClient('/web_api/bexio/authenticate', {
                data,
                method: 'POST',
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [SINGLE_BP_CACHE],
            });
        },
    });
};

const useTriggerResetPassword = () => {
    return useMutation({
        mutationFn: (data: {email: string; captcha: string}) => {
            return client('/web_api/reset_passwords', {data, method: 'POST'});
        },
    });
};

const useCheckResetPasswordHash = (hash: string) =>
    useQuery<{token: string}>({
        queryKey: ['check_pw_hash', {hash}],
        queryFn: () =>
            client(`/web_api/reset_passwords/auth_token/${hash}`, {
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                },
            }),
    });

const useSetPassword = () => {
    return useMutation({
        mutationFn: ({
            plainPassword,
            token,
            userId,
        }: {
            plainPassword: string;
            token: string;
            userId: string;
        }) => {
            // should be solved differently
            return client(`${userId}/set_password`, {
                data: {plainPassword},
                token,
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                },
                method: 'PATCH',
            });
        },
    });
};

const useVerifyEmail = (hash: string) => {
    const {addEvent} = useAnalytics();
    return useQuery<unknown, {message?: string}>({
        queryKey: [EMAIL_VERIFICATION_CACHE, {hash}],
        queryFn: async () => {
            const response = await client(
                `/web_api/users/verify-email/check/${hash}`,
                {
                    headers: {
                        'Content-Type': 'application/vnd.api+json',
                    },
                },
            ).then(() => addEvent('app_email_verification'));

            return response;
        },
        enabled: !!hash,
    });
};

const useGetCrp = (
    activeBusinessPartnerId: string,
    crp?: string,
    status?: string,
) => {
    const authenticatedClient = useClient();

    return useQuery<ICrpBpData>({
        queryKey: ['CRP', {id: crp}],
        queryFn: () =>
            authenticatedClient(
                `${crp}?crpBusinessPartners.businessPartner=${activeBusinessPartnerId
                    .split('/')
                    .pop()}`,
                {},
            ),
        enabled: crp !== null && status === 'open',
    });
};

const useChangePassword = () => {
    const authenticatedClient = useClient();

    return useMutation({
        mutationFn: (data: {
            id: string;
            oldPassword: string;
            plainPassword: string;
        }) => {
            const {id, ...restData} = data;
            return authenticatedClient(`${id}/change_password`, {
                data: restData,
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                },
            });
        },
    });
};

export interface IInviteUserRequest {
    businessPartner: string;
    email: string;
}

const useInviteUser = () => {
    const authenticatedClient = useClient();

    return useMutation({
        mutationFn: (data: IInviteUserRequest) => {
            return authenticatedClient('/web_api/referral_invites', {
                data,
                method: 'POST',
            });
        },
    });
};

const useLogout = () => {
    const authenticatedClient = useClient();

    return useMutation({
        mutationFn: () => {
            return authenticatedClient('/web_api/logout', {});
        },
    });
};

export {
    useGetCrp,
    useGetUser,
    useUpdateUser,
    useLogin,
    useLoginBexio,
    useConnectBexio,
    useTriggerResetPassword,
    useSetPassword,
    useCheckResetPasswordHash,
    useVerifyEmail,
    useChangePassword,
    useInviteUser,
    useLogout,
};
