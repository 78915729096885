/* eslint-disable */
import * as React from 'react';

const Login = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className=""
        aria-hidden="true"
        data-prefix="fal"
        data-icon="sign-out"
        viewBox="0 0 512 512"
        width="1em"
        height="1em"
        {...props}
    >
        <path
            fill="currentColor"
            d="m375.023438 276h-355.023438c-11.046875 0-20-8.953125-20-20s8.953125-20 20-20h355.023438l-72.09375-85.070312c-7.140626-8.425782-6.097657-21.046876 2.328124-28.1875 8.425782-7.140626 21.046876-6.097657 28.1875 2.328124l100 118c6.304688 7.433594 6.292969 18.441407 0 25.859376l-100 118c-7.113281 8.394531-19.730468 9.496093-28.1875 2.328124-8.425781-7.140624-9.46875-19.761718-2.328124-28.1875zm116.976562-276h-173.8125c-11.042969 0-20 8.953125-20 20s8.957031 20 20 20h153.8125v432h-153.8125c-11.042969 0-20 8.953125-20 20s8.957031 20 20 20h173.8125c11.046875 0 20-8.953125 20-20v-472c0-11.046875-8.953125-20-20-20zm0 0"
        />
    </svg>
);

export default Login;
