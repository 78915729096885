const PaymentTypeFee = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 58 58"
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            stroke="#33F"
            strokeWidth="2"
            d="M57 29c0 15.464-12.536 28-28 28S1 44.464 1 29 13.536 1 29 1s28 12.536 28 28Z"
        />
        <path
            fill="#33F"
            d="M43.5 41.688h-29v-3.199h29v3.199ZM43.5 22.496h-29v-3.198h29v3.198ZM38.667 17.698H19.333V14.5h19.334v3.198ZM22.556 24.096V36.89h-3.223V24.096h3.223ZM30.611 24.096V36.89H27.39V24.096h3.222ZM38.667 24.096V36.89h-3.223V24.096h3.223Z"
        />
    </svg>
);

export default PaymentTypeFee;
