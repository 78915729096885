import * as React from 'react';

const SvgRegisterPeer = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
        {...props}
    >
        <path
            fill="#B3DFD8"
            d="M17.34070108 20.72432084h-1.71429v10.3152h1.71429v-10.3152zm5.14062 0h-1.7143v10.3152h1.7143v-10.3152zm5.1445 0h-1.7143v10.3152h1.7143v-10.3152zm5.1407 0h-1.7143v10.3152h1.7143v-10.3152zm3.4303-3.43849h-24.0001v1.71919h24.0001v-1.71919zm-5.1412-5.08911h-13.71437v1.7192h13.71437v-1.7192z"
        />
        <path
            fill="#B3DFD8"
            d="M34.48312108 15.63520084h-20.57156v1.7192h20.57156v-1.7192zm-1.7138-1.78833h-17.14291v1.7192h17.14291v-1.7192zm3.4275 18.98025h-24.0001v1.7192h24.0001v-1.7192z"
        />
        <path
            fill="#B3DFD8"
            d="M36.19682108 34.47792084h-24.0001v1.7193h24.0001v-1.7193z"
        />
        <rect width="47" height="47" x=".5" y=".5" stroke="#66F" rx="23.5" />
    </svg>
);

export default SvgRegisterPeer;
