import {Icons} from 'components/ui/Icon/IconSVGConfig';
import IconSVG from 'components/ui/Icon/IconSVG';
import {Link, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import styles from './AmnisNavLink.module.scss';

interface IProps {
    to: string;
    icon?: Icons;
    label: string;
    onClick: () => void;
}

const AmnisNavLink = ({icon, label, onClick, to}: IProps) => {
    const location = useLocation();

    return (
        <Link
            to={to}
            className={classNames(styles.container, {
                [styles.active]: to === `/${location.pathname.split('/')[1]}`,
            })}
        >
            {icon ? <IconSVG customDimensions name={icon} /> : null}
            <span>{label}</span>
        </Link>
    );
};

export default AmnisNavLink;
