import {useQuery} from '@tanstack/react-query';

import {useClient} from 'context/auth-context';
import {BUSINESS_INDUSTRIES} from 'constants/cache';

export interface IBusinessIndustry {
    '@id': string;
    value: string;
}

function useBusinessIndustries() {
    const client = useClient();

    return useQuery<IBusinessIndustry[]>({
        queryKey: [BUSINESS_INDUSTRIES],
        queryFn: () => {
            return client('/web_api/business_industries', {
                params: {
                    'order[position]': 'ASC',
                    pagination: false,
                },
            });
            // .then((businessIndustries: IBusinessIndustry[]) => {
            //     return businessIndustries.map(businessIndustry => {
            //         const label = translate(businessIndustry.value);
            //         return {
            //             ...businessIndustry,
            //             id: businessIndustry['@id'].split('/').pop(),
            //             label,
            //         };
            //     });
            // });
        },
    });
}

export {useBusinessIndustries};
