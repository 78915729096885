const Merchant = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M5.495 3h13.011L22 6.977v1.776c0 .99-.393 1.889-1.028 2.556V21H3.028v-9.69A3.697 3.697 0 0 1 2 8.753V6.967L5.495 3ZM3.5 8.502v.25c0 .66.293 1.256.77 1.672M3.5 8.502h4.667v.25c0 1.225-1.026 2.252-2.334 2.252a2.37 2.37 0 0 1-1.563-.58m4.647.559a3.863 3.863 0 0 1-3.084 1.52 3.9 3.9 0 0 1-1.305-.223v7.22h4.666v-2.25c0-1.54 1.276-2.752 2.806-2.752s2.806 1.213 2.806 2.751V19.5h4.666v-7.22a3.902 3.902 0 0 1-1.305.224 3.863 3.863 0 0 1-3.084-1.521A3.863 3.863 0 0 1 12 12.503a3.863 3.863 0 0 1-3.083-1.52Zm5.416-2.23c0 1.224-1.025 2.25-2.333 2.25-1.308 0-2.333-1.026-2.333-2.25v-.25h4.666v.25Zm1.5 0c0 1.224 1.026 2.25 2.334 2.25.604 0 1.15-.22 1.563-.579a2.21 2.21 0 0 0 .77-1.671v-.25h-4.667v.25Zm4.193-1.75H3.968L6.172 4.5h11.655l2.199 2.502ZM13.306 19.5v-2.25c0-.673-.566-1.252-1.306-1.252-.74 0-1.306.58-1.306 1.251V19.5h2.611Z"
            clipRule="evenodd"
        />
    </svg>
);

export default Merchant;
