import styles from './SourceOfWealthInfo.module.scss';

const SourceOfWealthInfo = () => {
    return (
        <div className={styles.container}>
            <h3>Business relationship types</h3>
            <h5>B2B (business-to-business):</h5>
            <ul>
                <li>
                    B2B refers to business transactions and interactions that
                    take place between two or more businesses or organizations.
                </li>

                <li>
                    Examples of B2B transactions include a manufacturer selling
                    parts to an assembly company, a software company providing
                    software licenses to a corporation, or a consulting firm
                    offering its services to other businesses.
                </li>
            </ul>
            <h5>B2C (business-to-consumer):</h5>
            <ul>
                <li>
                    B2C involves transactions and interactions between a
                    business or organization and individual consumers or
                    end-users.
                </li>
                <li>
                    Examples of B2C companies include e-commerce platforms,
                    clothing retailers, and fast-food chains.
                </li>
            </ul>
            <h5>C2C (consumer-to-consumer):</h5>
            <ul>
                <li>
                    C2C refers to transactions and interactions where individual
                    consumers sell products or services directly to other
                    individual consumers.
                </li>
                <li>Examples of C2C platforms include eBay and Airbnb.</li>
            </ul>
            <h5>C2B (consumer-to-business):</h5>
            <ul>
                <li>
                    C2B represents a model where individual consumers or users
                    offer products, services, or expertise to businesses.
                </li>
                <li>
                    Examples of C2B interactions include freelance graphic
                    designers providing design services to companies, social
                    media influencers promoting products, or consumers
                    submitting product reviews and feedback to businesses.
                </li>
            </ul>
        </div>
    );
};

export default SourceOfWealthInfo;
