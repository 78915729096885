const SvgCopy = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2.75005 19.3077C2.24493 19.3077 1.81738 19.1327 1.46738 18.7827C1.11738 18.4327 0.942375 18.0051 0.942375 17.5V4.80775H2.44235V17.5C2.44235 17.5769 2.4744 17.6474 2.5385 17.7115C2.6026 17.7756 2.67312 17.8077 2.75005 17.8077H12.4423V19.3077H2.75005ZM6.25003 15.8077C5.74489 15.8077 5.31732 15.6327 4.96732 15.2827C4.61734 14.9327 4.44235 14.5051 4.44235 14V2.61542C4.44235 2.11031 4.61734 1.68275 4.96732 1.33275C5.31732 0.98275 5.74489 0.80775 6.25003 0.80775H14.6346C15.1397 0.80775 15.5673 0.98275 15.9173 1.33275C16.2673 1.68275 16.4423 2.11031 16.4423 2.61542V14C16.4423 14.5051 16.2673 14.9327 15.9173 15.2827C15.5673 15.6327 15.1397 15.8077 14.6346 15.8077H6.25003ZM6.25003 14.3077H14.6346C14.7115 14.3077 14.782 14.2757 14.8462 14.2115C14.9103 14.1474 14.9423 14.0769 14.9423 14V2.61542C14.9423 2.53849 14.9103 2.46797 14.8462 2.40387C14.782 2.33977 14.7115 2.30772 14.6346 2.30772H6.25003C6.17309 2.30772 6.10257 2.33977 6.03848 2.40387C5.97436 2.46797 5.9423 2.53849 5.9423 2.61542V14C5.9423 14.0769 5.97436 14.1474 6.03848 14.2115C6.10257 14.2757 6.17309 14.3077 6.25003 14.3077Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgCopy;
