import * as React from 'react';

const SvgExchange = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        {...props}
    >
        <path fill="currentColor" d="m4 8 4.5-3.464v6.928L4 8Z" />
        <path
            fill="currentColor"
            d="M7.863 7h10.818v2H7.863zM19.682 16l-4.5 3.464v-6.928l4.5 3.464Z"
        />
        <path fill="currentColor" d="M15.819 17H5v-2h10.818z" />
    </svg>
);

export default SvgExchange;
