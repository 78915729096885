import styles from './CrpContractSignerInfo.module.scss';

const CrpContractSignerInfo = () => {
    return (
        <div className={styles.container}>
            <h3>Contract signer</h3>
            <p>List who will sign the contract with amnis</p>
            <h5>What do we mean with contract signer?</h5>
            <p data-isolate>
                In this context, a&nbsp;
                <span className={styles.strong}>contract signer</span> is the
                person or persons who will actually sign the contract with
                amnis. Not all legally authorized individuals need to be listed,
                but only the person(s) signing this specific contract.
                Generally, one or two persons are designated for this role.
            </p>
        </div>
    );
};

export default CrpContractSignerInfo;
