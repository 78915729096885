export const ariaLiveMessages = {
    guidance: (props: any) => {
        // const {
        //     isSearchable,
        //     isMulti,
        //     isDisabled,
        //     tabSelectsValue,
        //     context,
        // } = props;
        // switch (context) {
        //     case 'menu': {
        //         const msg = `Use Up and Down to choose options${
        //             isDisabled
        //                 ? ''
        //                 : ', press Enter to select the currently focused option'
        //         }, press Escape to exit the menu${
        //             tabSelectsValue
        //                 ? ', press Tab to select the option and exit the menu'
        //                 : ''
        //         }.`;
        //         return translate(msg);
        //     }
        //     case 'input': {
        //         const msg = `Select is focused ${
        //             isSearchable ? ',type to refine list' : ''
        //         }, press Down to open the menu, ${
        //             isMulti ? ' press left to focus selected values' : ''
        //         }`;
        //         return translate(msg);
        //     }
        //     case 'value':
        //         return translate(
        //             'Use left and right to toggle between focused values, press Backspace to remove the currently focused value',
        //         );
        //     default:
        //         return '';
        // }
        return '';
    },

    onChange: (props: any) => {
        // const {action, label = '', isDisabled} = props;
        // switch (action) {
        //     case 'deselect-option':
        //     case 'pop-value':
        //     case 'remove-value': {
        //         const msg = 'option <var label></var>, deselected';
        //         return translate(msg, {label});
        //     }
        //     case 'select-option': {
        //         const msg = isDisabled
        //             ? 'option <var label></var> is disabled. Select another option.'
        //             : 'option <var label></var>, selected.';
        //         return translate(msg, {label});
        //     }
        //     default:
        //         return '';
        // }
        return '';
    },

    onFocus: (props: any) => {
        // const {
        //     context,
        //     focused,
        //     options,
        //     label = '',
        //     selectValue,
        //     isDisabled,
        //     isSelected,
        // } = props;

        // const getArrayIndex = (arr, item) =>
        //     arr && arr.length
        //         ? translate(
        //               `<var currentCount></var> of <var totalCount></var>`,
        //               {
        //                   currentCount: arr.indexOf(item) + 1,
        //                   totalCount: arr.length,
        //               },
        //           )
        //         : '';

        // if (context === 'value' && selectValue) {
        //     return translate(
        //         `value <var label></var> focused, <var counts></var>.`,
        //         {label, counts: getArrayIndex(selectValue, focused)},
        //     );
        // }

        // if (context === 'menu') {
        //     const disabled = isDisabled ? ' disabled' : '';
        //     const status = `${isSelected ? 'selected' : 'focused'}${disabled}`;
        //     return translate(
        //         `option <var label></var> <var status></var>, <var counts></var>.`,
        //         {label, status, counts: getArrayIndex(options, focused)},
        //     );
        // }
        return '';
    },

    onFilter: (props: any) => {
        // const {inputValue, resultsMessage} = props;
        // return translate(
        //     `<var resultsMessage></var>${
        //         inputValue ? ` for search term <var inputValue></var>` : ''
        //     }.`,
        //     {resultsMessage, inputValue},
        // );
        return '';
    },
};
