import {Link, useHistory} from 'react-router-dom';
import classNames from 'classnames';

import {useBusinessPartner} from 'context/business-partner-context';
import SelectBusinessPartner from 'components/pages/CurrenciesPage/SelectBusinessPartner/SelectBusinessPartner';
import {PATH} from 'constants/common';
import {createOnboardingUrlPath} from 'components/pages/OnboardingPage/onboardingSteps';
import {StepTypes} from 'types/onboarding';
import {BusinessPartnerStatus} from 'types/api';
import Button from 'components/ui/Button';
import {useTriggerReviewProcess} from 'api/business-partner';
import IconSVG from '../Icon/IconSVG';
import styles from './BusinessPartner.module.scss';

export class IBusinessPartnerProps {
    className?: string;
    onOnboarding?: boolean;
    white?: boolean;
}

const BUTTON_TEXT: Partial<Record<BusinessPartnerStatus, string>> = {
    'ready for compliance': 'Compliance review pending',
    in_compliance_review: 'Review in progress',
    in_sales_review: 'Review in progress',
};

const BusinessPartner = ({
    className = '',
    onOnboarding = false,
    white = false,
}: IBusinessPartnerProps) => {
    const {activeBusinessPartner, businessPartners, setSelectedBpId} =
        useBusinessPartner();
    const history = useHistory();
    const {mutate: triggerReviewProcess, isPending: isLoading} =
        useTriggerReviewProcess();

    if (!activeBusinessPartner) {
        return null;
    }

    const handleTriggerReviewProcess = () => {
        triggerReviewProcess(undefined, {
            onSuccess: () =>
                history.push(createOnboardingUrlPath(StepTypes.CREATE_BP)),
        });
    };

    const renderBPLink = () => {
        if (
            activeBusinessPartner?.status === 'dormant' &&
            !activeBusinessPartner?.reviewProcess &&
            !!activeBusinessPartner.permission?.admin
        ) {
            return (
                <Button
                    className="mr-2"
                    onClick={handleTriggerReviewProcess}
                    isLoading={isLoading}
                >
                    Start review process
                </Button>
            );
        }
        if (
            activeBusinessPartner?.permission?.onlyCardUser ||
            activeBusinessPartner?.reviewProcess?.status ===
                'ready_for_compliance'
        ) {
            return null;
        }

        if (activeBusinessPartner.sandbox) {
            return (
                <Link
                    to={createOnboardingUrlPath(StepTypes.CREATE_BP)}
                    className="btn btn-primary mr-2"
                >
                    Get onboarded
                </Link>
            );
        }
        if (
            !activeBusinessPartner.identified ||
            activeBusinessPartner.reviewProcess !== null
        ) {
            return (
                <Link
                    to={PATH.ONBOARDING_OVERVIEW}
                    className={`btn mr-2 ${
                        activeBusinessPartner.status ===
                            'in_compliance_review' ||
                        activeBusinessPartner.status === 'ready for compliance'
                            ? 'btn-outline-primary'
                            : 'btn-primary'
                    }`}
                >
                    {activeBusinessPartner.reviewProcess !== null
                        ? 'Pending review process'
                        : BUTTON_TEXT[activeBusinessPartner.status] ||
                          'Check onboarding'}
                </Link>
            );
        }

        if (
            activeBusinessPartner.activePackage === 0 &&
            !activeBusinessPartner.discountName
        ) {
            return (
                <Link
                    to={PATH.PRICING}
                    className={classNames(
                        styles.pricingButton,
                        'btn btn-primary mr-2',
                    )}
                >
                    <IconSVG
                        name="upgrade"
                        customDimensions
                        height="1rem"
                        className="mr-2"
                    />
                    Upgrade
                </Link>
            );
        }

        return null;
    };

    if (businessPartners.length === 1) {
        return (
            <div className={styles.container}>
                {renderBPLink()}
                <span
                    data-notranslate
                    className={white ? styles.white : undefined}
                >
                    {activeBusinessPartner.companyName}
                </span>
            </div>
        );
    }

    return (
        <>
            {onOnboarding ? null : renderBPLink()}
            <SelectBusinessPartner
                className={className}
                businessPartners={businessPartners}
                activeBusinessPartner={activeBusinessPartner}
                setSelectedBpId={setSelectedBpId}
            />
        </>
    );
};

export default BusinessPartner;
