import {useUpdateCrp} from 'api/crp';
import Form, {FormOnSubmit} from 'components/ui/Form/Form/Form';
import OnboardingCheckBoxGroup from 'components/ui/Form/OnboardingCheckBoxGroup/OnboardingCheckBoxGroup';
import TextArea from 'components/ui/Form/TexArea/TextArea';
import {useAuth} from 'context/auth-context';
import {pepValidationSchema} from 'form/validation/businessPartner';
import {handleTypedFormError} from 'helpers/handleFormError';
import React, {useEffect, useState} from 'react';
import PepInfo from '../components/Infos/PepInfo';
import NextStepButton from '../components/NextStepButton';
import StepTitle from '../components/StepTitle';
import {IDefaultStepProps} from '../useOnboarding';

import styles from './Steps.module.scss';

const PEP_OPTIONS = [
    {
        label: 'Yes',
        value: 'yes',
    },
    {label: 'No', value: 'no'},
];

export interface IPepFormValues {
    pep: string;
    pepDescription: string | undefined;
}

const Pep = (props: IDefaultStepProps) => {
    const {crpList, onSuccess, isSubmitLoading} = props;
    const [showDescription, setShowDescription] = useState<boolean>(false);
    const [defaultValues, setDefaultValues] = useState<IPepFormValues>({
        pep: '',
        pepDescription: '',
    });
    const {user} = useAuth();
    const {mutate: updateCrp, isPending: isLoading} = useUpdateCrp();
    const ownCrp = crpList?.find(crpBp => crpBp.crp['@id'] === user.crp);

    useEffect(() => {
        let defaultPepValue = '';
        if (ownCrp?.crp.pep === true) defaultPepValue = 'yes';
        if (ownCrp?.crp.pep === false) defaultPepValue = 'no';
        setDefaultValues({
            pep: defaultPepValue,
            pepDescription: ownCrp?.crp.pepDescription,
        });
        setShowDescription(!!ownCrp?.crp.pep);
    }, [ownCrp]);

    const handleSubmit: FormOnSubmit<IPepFormValues> = (values, setError) => {
        const request = {
            ...values,
            pep: values.pep === 'yes',
            '@id': ownCrp!.crp['@id'],
            firstName: ownCrp!.crp.firstName,
        };

        updateCrp(request, {
            onSuccess: () => onSuccess({refetchCrpList: true}),
            onError: (requestErrors: any) =>
                handleTypedFormError(requestErrors, setError, values),
        });
    };
    return (
        <>
            <StepTitle title="Politically exposed person" info={<PepInfo />} />
            <Form<IPepFormValues>
                onSubmit={handleSubmit}
                className={styles.form}
                onControlValueChange={values =>
                    setShowDescription(values.pep === 'yes')
                }
                validationSchema={pepValidationSchema}
                defaultValues={defaultValues}
            >
                <OnboardingCheckBoxGroup
                    type="radio"
                    name="pep"
                    variant="dark"
                    options={PEP_OPTIONS}
                    label="Do you hold or have you exercised (within the last 12 months) an important public function of national or regional significance, regardless of where you exercise this function? See also info box 'Politically exposed person' above."
                    size="large"
                />
                {showDescription ? (
                    <TextArea
                        name="pepDescription"
                        label="Please describe the function and active period:"
                        variant="dark"
                        size="large"
                    />
                ) : null}
                <NextStepButton loading={isLoading || isSubmitLoading} />
            </Form>
        </>
    );
};

export default Pep;
