const SvgAmnis = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 22 22"
        {...props}
    >
        <rect
            width="21"
            height="21"
            x=".5"
            y=".5"
            fill="transparent"
            rx="10.5"
        />
        <path
            fill="currentColor"
            d="M14.448 13.227a3.691 3.691 0 0 1-2.427.906 3.695 3.695 0 0 1-2.418-.933A6.303 6.303 0 0 0 6 11.714a5.342 5.342 0 0 0 1.268 2.738c.404.49.902.88 1.459 1.147a4.029 4.029 0 0 0 1.772.396 3.487 3.487 0 0 0 1.7-.34 3.743 3.743 0 0 0 1.37-1.132v1.19H16v-3.438a8.464 8.464 0 0 0-1.55.952"
        />
        <path
            fill="currentColor"
            d="M16 11.875V8.143c-.467.253-.913.56-1.33.913-.341.305-.717.551-1.117.73.167.418.25.875.243 1.337a3.23 3.23 0 0 1-.153 1.088 2.88 2.88 0 0 1-.5.932c1.031-.11 2.019-.548 2.857-1.267M6 10.463c.78.072 1.52.388 2.118.903.16.113.326.231.504.348a3.299 3.299 0 0 1-.115-.889 2.791 2.791 0 0 1 .177-1.087 2.676 2.676 0 0 1 .887-1.199 6.54 6.54 0 0 0-2.464-1.11A5.253 5.253 0 0 0 6 10.463ZM13.631 7.235a3.583 3.583 0 0 0-1.335-.946 3.882 3.882 0 0 0-1.656-.285c-.9-.01-1.778.255-2.497.753.064.04.13.081.197.125a6.73 6.73 0 0 0 4.009 1.26h.044A6.507 6.507 0 0 0 16 7.149v-.91h-2.368l-.001.996Z"
        />
        <rect
            width="21"
            height="21"
            x=".5"
            y=".5"
            stroke="currentColor"
            rx="10.5"
        />
    </svg>
);

export default SvgAmnis;
