export interface IStateOptions {
    CA: {label: string; '@id': string}[];
    MX: {label: string; '@id': string}[];
    US: {label: string; '@id': string}[];
}

export const COUNTRY_CODES_FOR_STATE = ['CA', 'US', 'MX'];
export const STATE_OPTIONS: IStateOptions = {
    CA: [
        {label: 'Alberta', '@id': 'AB'},
        {label: 'British Columbia', '@id': 'BC'},
        {label: 'Manitoba', '@id': 'MB'},
        {label: 'New Brunswick', '@id': 'NB'},
        {
            label: 'Newfoundland and Labrador',
            '@id': 'NL',
        },
        {label: 'Nova Scotia', '@id': 'NS'},
        {label: 'Ontario', '@id': 'ON'},
        {label: 'Prince Edward Island', '@id': 'PE'},
        {label: 'Québec', '@id': 'QC'},
        {label: 'Saskatchewan', '@id': 'SK'},
        {label: 'Northwest Territories', '@id': 'NT'},
        {label: 'Nunavut', '@id': 'NU'},
        {label: 'Yukon', '@id': 'YT'},
    ],
    US: [
        {label: 'Alabama', '@id': 'AL'},
        {label: 'Alaska', '@id': 'AK'},
        {label: 'Arizona', '@id': 'AZ'},
        {label: 'Arkansas', '@id': 'AR'},
        {label: 'California', '@id': 'CA'},
        {label: 'Colorado', '@id': 'CO'},
        {label: 'Connecticut', '@id': 'CT'},
        {label: 'Delaware', '@id': 'DE'},
        {label: 'Florida', '@id': 'FL'},
        {label: 'Georgia', '@id': 'GA'},
        {label: 'Hawaii', '@id': 'HI'},
        {label: 'Idaho', '@id': 'ID'},
        {label: 'Illinois', '@id': 'IL'},
        {label: 'Indiana', '@id': 'IN'},
        {label: 'Iowa', '@id': 'IA'},
        {label: 'Kansas', '@id': 'KS'},
        {label: 'Kentucky', '@id': 'KY'},
        {label: 'Louisiana', '@id': 'LA'},
        {label: 'Maine', '@id': 'ME'},
        {label: 'Maryland', '@id': 'MD'},
        {label: 'Massachusetts', '@id': 'MA'},
        {label: 'Michigan', '@id': 'MI'},
        {label: 'Minnesota', '@id': 'MN'},
        {label: 'Mississippi', '@id': 'MS'},
        {label: 'Missouri', '@id': 'MO'},
        {label: 'Montana', '@id': 'MT'},
        {label: 'Nebraska', '@id': 'NE'},
        {label: 'Nevada', '@id': 'NV'},
        {label: 'New Hampshire', '@id': 'NH'},
        {label: 'New Jersey', '@id': 'NJ'},
        {label: 'New Mexico', '@id': 'NM'},
        {label: 'New York', '@id': 'NY'},
        {label: 'North Carolina', '@id': 'NC'},
        {label: 'North Dakota', '@id': 'ND'},
        {label: 'Ohio', '@id': 'OH'},
        {label: 'Oklahoma', '@id': 'OK'},
        {label: 'Oregon', '@id': 'OR'},
        {label: 'Pennsylvania', '@id': 'PA'},
        {label: 'Rhode Island', '@id': 'RI'},
        {label: 'South Carolina', '@id': 'SC'},
        {label: 'South Dakota', '@id': 'SD'},
        {label: 'Tennessee', '@id': 'TN'},
        {label: 'Texas', '@id': 'TX'},
        {label: 'Utah', '@id': 'UT'},
        {label: 'Vermont', '@id': 'VT'},
        {label: 'Virginia', '@id': 'VA'},
        {label: 'Washington', '@id': 'WA'},
        {label: 'West Virginia', '@id': 'WV'},
        {label: 'Wisconsin', '@id': 'WI'},
        {label: 'Wyoming', '@id': 'WY'},
    ],
    MX: [
        {label: 'Mexiko-City', '@id': 'CMX'},
        {label: 'Aguascalientes', '@id': 'AGU'},
        {label: 'Baja California', '@id': 'BCN'},
        {label: 'Baja California Sur', '@id': 'BCS'},
        {label: 'Campeche', '@id': 'CAM'},
        {label: 'Coahuila', '@id': 'COA'},
        {label: 'Colima', '@id': 'COL'},
        {label: 'Chiapas', '@id': 'CHP'},
        {label: 'Chihuahua', '@id': 'CHH'},
        {label: 'Durango', '@id': 'DUR'},
        {label: 'Guanajuato', '@id': 'GUA'},
        {label: 'Guerrero', '@id': 'GRO'},
        {label: 'Hidalgo', '@id': 'HID'},
        {label: 'Jalisco', '@id': 'JAL'},
        {label: 'México', '@id': 'MEX'},
        {label: 'Michoacán', '@id': 'MIC'},
        {label: 'Morelos', '@id': 'MOR'},
        {label: 'Nayarit', '@id': 'NAY'},
        {label: 'Nuevo León', '@id': 'NLE'},
        {label: 'Oaxaca', '@id': 'OAX'},
        {label: 'Puebla', '@id': 'PUE'},
        {label: 'Querétaro', '@id': 'QUE'},
        {label: 'Quintana Roo', '@id': 'ROO'},
        {label: 'San Luis Potosí', '@id': 'SLP'},
        {label: 'Sinaloa', '@id': 'SIN'},
        {label: 'Sonora', '@id': 'SON'},
        {label: 'Tabasco', '@id': 'TAB'},
        {label: 'Tamaulipas', '@id': 'TAM'},
        {label: 'Tlaxcala', '@id': 'TLA'},
        {label: 'Veracruz', '@id': 'VER'},
        {label: 'Yucatán', '@id': 'YUC'},
        {label: 'Zacatecas', '@id': 'ZAC'},
    ],
};
