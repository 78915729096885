import useModal from 'components/ui/ModalContainer/useModal';
import ConfirmationOverlay from 'components/ui/Overlays/ConfirmationOverlay/ConfirmationOverlay';
import {useAuth} from 'context/auth-context';
import {useBusinessPartner} from 'context/business-partner-context';
//@ts-ignore
import EventEmitter from 'events';

declare global {
    // eslint-disable-next-line @typescript-eslint/interface-name-prefix
    interface Window {
        CobrowseIO: {
            client: () => Promise<any>;
            start: () => Promise<any>;
            createSessionCode: () => Promise<string>;
            createSession: () => Promise<any>;
            confirmSession: () => Promise<any>;
            confirmRemoteControl: () => Promise<any>;
            confirmFullDevice: () => Promise<any>;
            currentSession: EventEmitter;
            customData: Record<string, any>;
        };
    }
}

const useCobrowse = () => {
    const {openModalWithContent, closeModal} = useModal();
    const {user} = useAuth();
    const {activeBusinessPartner} = useBusinessPartner();

    window.CobrowseIO.confirmSession = () => {
        return new Promise((resolve, reject) => {
            openModalWithContent(
                'confirmation',
                <ConfirmationOverlay
                    title="Support Request"
                    description="A support agent would like to temporarily access this webpage with you. Would you like to allow this?"
                    onConfirm={() => {
                        resolve(true);
                        closeModal();
                    }}
                    onCancel={() => {
                        reject();
                        closeModal();
                    }}
                    cancelLabel="Deny"
                    confirmLabel="Allow"
                />,
            );
        });
    };

    window.CobrowseIO.confirmRemoteControl = () => {
        return new Promise((resolve, reject) => {
            openModalWithContent(
                'confirmation',
                <ConfirmationOverlay
                    title="Remote Control Request"
                    description="A support agent would like to temporarily control this page remotely. Would you like to allow this?"
                    onConfirm={() => {
                        resolve(true);
                        closeModal();
                    }}
                    onCancel={() => {
                        reject();
                        closeModal();
                    }}
                    cancelLabel="Deny"
                    confirmLabel="Allow"
                />,
            );
        });
    };

    window.CobrowseIO.confirmFullDevice = () => {
        return new Promise((resolve, reject) => {
            openModalWithContent(
                'confirmation',
                <ConfirmationOverlay
                    title="Screen Capture Request"
                    description="A support agent would like to view your entire screen temporarily. Would you like to allow this?"
                    onConfirm={() => {
                        resolve(true);
                        closeModal();
                    }}
                    onCancel={() => {
                        reject();
                        closeModal();
                    }}
                    cancelLabel="Deny"
                    confirmLabel="Allow"
                />,
            );
        });
    };

    window.CobrowseIO.customData = {
        userId: user['@id'].split('/').pop(),
        userName: `${user.firstName} ${user.lastName}`,
        userEmail: user.email,
        businessPartnerId: activeBusinessPartner['@id'].split('/').pop(),
        businessPartnerCompanyName: activeBusinessPartner.companyName,
    };

    const handleStartCobrowse = async () => {
        await window.CobrowseIO.client();
        await window.CobrowseIO.start();
        await window.CobrowseIO.createSession();
        const code = await window.CobrowseIO.createSessionCode();
        window.CobrowseIO.currentSession.on('ended', () => {
            closeModal();
        });
        openModalWithContent(
            'cobrowse',
            <ConfirmationOverlay
                title="Start co-browsing session"
                description={
                    <span>
                        Your co-browsing code: <var data-var="code">{code}</var>
                    </span>
                }
                onConfirm={() => closeModal()}
            />,
        );
    };

    return {handleStartCobrowse};
};

export default useCobrowse;
