import {useModalStore} from 'components/ui/ModalContainer/modalStore';

const useModal = () => {
    const openModalWithContent = useModalStore(
        state => state.openModalWithContent,
    );
    const closeModal = useModalStore(state => state.closeModal);
    const open = useModalStore(state => state.open);

    return {openModalWithContent, closeModal, open};
};

export default useModal;
