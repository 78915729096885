const Work = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M4.308 20.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.282V8.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525H8.5V4.808c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h3.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283V6.5h4.192c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v10.385c0 .505-.175.932-.525 1.282-.35.35-.778.525-1.283.525H4.308Zm0-1.5h15.384a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.211V8.308a.294.294 0 0 0-.096-.211.294.294 0 0 0-.212-.097H4.308a.294.294 0 0 0-.212.097.294.294 0 0 0-.096.211v10.385c0 .077.032.147.096.211a.294.294 0 0 0 .212.096ZM10 6.5h4V4.808a.294.294 0 0 0-.096-.211.294.294 0 0 0-.212-.097h-3.384a.294.294 0 0 0-.212.097.294.294 0 0 0-.096.211V6.5Z"
        />
    </svg>
);

export default Work;
