const NewDebitCard = (props: React.HTMLAttributes<SVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M1.031 3.651h18.804v7.413h-1.343V9.859H2.374v5.888h11.434v1.344H1.031V3.65Zm18.804 11.993v.103h3.134v1.344H19.89v3.259h-1.344v-3.26h-3.293v-1.343h3.238V12.51h1.343v3.134ZM2.375 4.995v2.061h16.117V4.995H2.374Z"
                clipRule="evenodd"
            />
            <path
                fill="currentColor"
                d="M6.673 13.76a1.658 1.658 0 1 1 0-1.834 1.658 1.658 0 1 1 0 1.834Z"
            />
        </svg>
    );
};

export default NewDebitCard;
