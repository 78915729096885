import classNames from 'classnames';
import useModal from 'components/ui/ModalContainer/useModal';
import React, {useRef} from 'react';
import FA from 'react-fontawesome';

import styles from './OnboardingOverlay.module.scss';

export interface IOnboardingOverlay {
    children: React.ReactNode;
    align?: 'left' | 'center' | 'right';
    hideCloseButton?: boolean;
    fullHeight?: boolean;
    gray?: boolean;
}

const OnboardingOverlay = ({
    children,
    align = 'center',
    hideCloseButton,
    fullHeight,
    gray,
}: IOnboardingOverlay) => {
    const {closeModal} = useModal();
    const containerRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick: React.MouseEventHandler = event => {
        if (event.target === containerRef?.current) {
            closeModal();
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        <div
            onClick={handleOutsideClick}
            ref={containerRef}
            className={classNames(styles.overlayContainer, {
                [styles[align]]: true,
            })}
        >
            <div
                className={classNames(styles.modal, {
                    [styles.fullHeight]: fullHeight,
                    [styles.gray]: gray,
                })}
            >
                {hideCloseButton ? null : (
                    <button
                        className={styles.closeButton}
                        onClick={() => closeModal()}
                    >
                        <FA size="lg" name="times" />
                    </button>
                )}
                {children}
            </div>
        </div>
    );
};

export default OnboardingOverlay;
