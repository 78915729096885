import React, {useMemo} from 'react';
import {ICrp, ICrpBpListItem} from 'api/business-partner';
import {useCountries} from 'api/countries';
import classNames from 'classnames';
import AccordionItem from 'components/ui/Accordion/Accordion';
import {CrpVariants} from 'constants/businessPartnerAttributes';
import {crpFormBaseValidationSchema} from 'form/validation/businessPartner';
import TextInput from 'components/ui/Form/TextInput/TextInput';
import PhoneInput from 'components/ui/Form/PhoneInput/PhoneInput';
import CheckBoxGroup from 'components/ui/Form/OnboardingCheckBoxGroup/OnboardingCheckBoxGroup';
import {
    GENDER_OPTIONS,
    HIGHEST_EDUCATION_OPTIONS,
    PROFESSIONAL_POSITION_OPTIONS,
} from 'constants/common';
import CountryAutoComplete from 'components/ui/Form/AutoComplete/CountryAutoComplete';
import DatePicker from 'components/ui/Form/DatePicker/DatePicker';
import {subYears} from 'date-fns';
import AutoComplete from 'components/ui/Form/AutoComplete/AutoComplete';
import {LegalType} from 'types/onboarding';
import styles from '../CrpForm.module.scss';

interface IProps {
    crpData?: ICrpBpListItem;
    variant: CrpVariants;
    isLoggedInUser: boolean;
    userCrp?: ICrp;
    legalType: LegalType | null;
}

const CrpPersonalForm = ({
    crpData,
    variant,
    isLoggedInUser,
    userCrp,
    legalType,
}: IProps) => {
    const {countries} = useCountries();

    const showPersonalData = !crpData || crpData.confidential === false;

    const personalDone = useMemo(() => {
        if (crpData) {
            if (crpData.confidential) {
                return true;
            }
            try {
                crpFormBaseValidationSchema(variant).validateSync({
                    ...crpData.crp,
                    ...crpData,
                });
            } catch (error) {
                return false;
            }
            return true;
        }
        return false;
    }, [crpData, variant]);

    const canChangeName = isLoggedInUser
        ? userCrp?.canChangeName
        : crpData?.crp.canChangeName;

    return (
        <AccordionItem
            toggleType="title"
            openStatus={!personalDone}
            className={classNames(styles.accordionContainer, {
                [styles.groupDone]: personalDone,
            })}
            accordionKey="personal"
            title={
                <>
                    <span className={styles.groupStatus} />
                    <div className={styles.accordionHeader}>
                        Personal information
                    </div>
                </>
            }
            body={
                <>
                    <div className={styles.nameInputs}>
                        <TextInput
                            name="firstName"
                            label="First name(s)"
                            info="Please provide all your first names according to your passport"
                            variant="dark"
                            disabled={!showPersonalData}
                            readOnly={canChangeName === false}
                            size="large"
                        />
                        <TextInput
                            name="lastName"
                            label="Last name"
                            variant="dark"
                            disabled={!showPersonalData}
                            readOnly={canChangeName === false}
                            size="large"
                        />
                    </div>
                    <TextInput
                        name="email"
                        label="Email"
                        variant="dark"
                        disabled={!showPersonalData}
                        readOnly={isLoggedInUser}
                        hidden={
                            variant === CrpVariants.SIGNER ||
                            variant === CrpVariants.OWNER
                        }
                        size="large"
                    />
                    <PhoneInput
                        name="phoneNumber"
                        label="Phone number (mobile)"
                        variant="dark"
                        hidden={
                            !showPersonalData ||
                            variant === CrpVariants.SIGNER ||
                            variant === CrpVariants.OWNER
                        }
                        size="large"
                    />
                    <CheckBoxGroup
                        options={GENDER_OPTIONS}
                        name="gender"
                        type="radio"
                        label="Gender"
                        variant="dark"
                        horizontal
                        hidden={!showPersonalData}
                        readOnly={
                            canChangeName === false &&
                            crpData?.crp.gender !== null
                        }
                        size="large"
                    />
                    <CountryAutoComplete
                        label="Nationality"
                        name="nationality"
                        options={countries}
                        variant="dark"
                        hidden={
                            !showPersonalData || variant === CrpVariants.SIGNER
                        }
                        size="large"
                    />
                    <DatePicker
                        label="Birthday"
                        name="dateOfBirth"
                        placeholder="01.01.1999."
                        maxDate={subYears(new Date(), 18)}
                        variant="dark"
                        hidden={
                            !showPersonalData || variant === CrpVariants.SIGNER
                        }
                        size="large"
                    />
                    <AutoComplete
                        name="highestEducation"
                        label="Highest education degree"
                        options={HIGHEST_EDUCATION_OPTIONS}
                        variant="dark"
                        size="large"
                    />
                    <TextInput
                        name="street"
                        label="Address (privat)"
                        placeholder="Main street 8"
                        variant="dark"
                        hidden={
                            !showPersonalData || variant === CrpVariants.SIGNER
                        }
                        size="large"
                    />
                    <div className={styles.addressRow}>
                        <TextInput
                            name="zip"
                            label="Zip"
                            placeholder="1234"
                            variant="dark"
                            className={styles.zip}
                            hidden={
                                !showPersonalData ||
                                variant === CrpVariants.SIGNER
                            }
                            size="large"
                        />
                        <TextInput
                            name="city"
                            label="City"
                            placeholder="Zurich"
                            variant="dark"
                            hidden={
                                !showPersonalData ||
                                variant === CrpVariants.SIGNER
                            }
                            size="large"
                        />
                    </div>
                    <CountryAutoComplete
                        label="Country"
                        name="country"
                        options={countries}
                        variant="dark"
                        hidden={!showPersonalData}
                        size="large"
                    />
                    {variant === CrpVariants.OWNER &&
                    legalType === LegalType.INDIVIDUAL ? (
                        <>
                            <AutoComplete
                                name="professionalPosition"
                                label="Professional position"
                                options={PROFESSIONAL_POSITION_OPTIONS}
                                variant="dark"
                                size="large"
                            />
                            <TextInput
                                name="role"
                                label="Could you please specify your professional position?"
                                placeholder="CEO"
                                variant="dark"
                                size="large"
                            />
                        </>
                    ) : null}
                </>
            }
        />
    );
};

export default CrpPersonalForm;
