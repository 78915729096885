export enum StepTypes {
    CREATE_BP = 'create-business-partner',
    BUSINESS_ACTIVITIES = 'business-activities',
    BUSINESS_ATTRIBUTES = 'business-attributes',
    REASON_FOR_AMNIS = 'reason-for-amnis',
    INCOMING_PAYMENTS = 'incoming-payments',
    OUTGOING_PAYMENTS = 'outgoing-payments',
    GENERAL_PAYMENTS = 'general-payments',
    FX = 'fx',
    DEBIT_CARD = 'debit-card',
    SOURCE_OF_WEALTH = 'source-of-wealth',
    COMPANY_FINISH = 'company-finish',
    OWNER_CRPS = 'crp-owner',
    SIGNER_CRPS = 'crp-signer',
    CONTRACT_SIGNER_CRPS = 'crp-contract-signer',
    ADMIN_CRPS = 'crp-admin',
    GENERATE_CONTRACT = 'generate-contract',
    SIGNATORIES_FINISH = 'signatories-finish',
    TWOFA = '2fa',
    PEP = 'pep',
    PEP_CRP = 'pep-crp',
    IDENTITY = 'identity',
    CONTRACT_SIGN = 'contract-sign',
    FINISH_ONBOARDING = 'finish-onboarding',
    FINISH_REASON = 'finish-reason',
    FILE_UPLOADS = 'file-uploads',
}

export enum LegalType {
    INDIVIDUAL = 'individual',
    COMPANY = 'company',
}

export enum OnboardingPageType {
    WHITE = 'white',
    BLUE = 'blue',
}

export type StepGroupTypes =
    | 'company'
    | 'crp'
    | 'identification'
    | 'sign'
    | 'uploads';
export interface IStepGroup {
    type: StepGroupTypes;
    title: string;
    children: StepTypes[];
}

export type OnboardingSuccessCallback = (args?: {
    refetchCrpList?: boolean;
    refetchContractList?: boolean;
    overWriteDisabled?: number[];
}) => void;
