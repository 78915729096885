const PeerPayments = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 22 21"
        {...props}
    >
        <path
            fill="currentColor"
            fill-rule="evenodd"
            d="m14.446 10.932 4.712-4.773-4.712-4.773-1.067 1.096 2.87 2.908H8.877v1.538h7.372l-2.87 2.907 1.067 1.097ZM5.71 18.175.999 13.402 5.71 8.629l1.067 1.096-2.87 2.908h7.372v1.538H3.908l2.87 2.907-1.067 1.097Zm15.408-2.276a3.878 3.878 0 1 1-7.757 0 3.878 3.878 0 0 1 7.757 0Zm-3.555-1.976c.762 0 1.35.587 1.35 1.327s-.588 1.327-1.35 1.327h-.695v1.298h-.78v-3.952h1.475Zm0 1.925c.333 0 .576-.26.576-.598 0-.345-.243-.599-.576-.599h-.695v1.197h.695Z"
            clip-rule="evenodd"
        />
    </svg>
);

export default PeerPayments;
