import * as React from 'react';

const AccountsNew = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="ad372756-12ca-435b-b4c6-951be548dfcd"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 170 170"
        {...props}
    >
        <path
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            d="M95,163.34H40c4.2-28.26,29.46-50,60-50s55.8,21.74,60,50H105"
        />
        <path
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={11.62}
            stroke="#b5e3d8"
            d="M109.22,90.43a27.68,27.68,0,1,0-18.45,0"
        />
    </svg>
);

export default AccountsNew;
