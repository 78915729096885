import {useHistory} from 'react-router';
import {FormGroup} from 'react-bootstrap';
import Select, {
    components,
    MenuListComponentProps,
    OptionProps,
} from 'react-select';
import {FixedSizeList} from 'react-window';

import {BusinessPartner} from 'types/api';
import {PATH} from 'constants/common';

interface IProps {
    className?: string;
    businessPartners: BusinessPartner[];
    activeBusinessPartner: BusinessPartner;
    setSelectedBpId: React.Dispatch<React.SetStateAction<number | null>>;
}

const SelectBusinessPartner: React.FC<IProps> = ({
    className,
    businessPartners,
    activeBusinessPartner,
    setSelectedBpId,
}) => {
    const history = useHistory();

    const onBusinessPartnerChange = (bp: BusinessPartner | null) => {
        if (!bp) return null;
        setSelectedBpId(bp.id);
        history.push(`${PATH.DASHBOARD}`);
    };

    return (
        <FormGroup className={className} data-notranslate>
            <Select
                classNamePrefix="amnis"
                options={businessPartners}
                getOptionLabel={option => option.companyName}
                getOptionValue={option => option.id.toString()}
                isSearchable={businessPartners.length > 5}
                isClearable={false}
                value={businessPartners.find(
                    obj => obj.id === activeBusinessPartner.id,
                )}
                components={{Option, MenuList: MenuList}}
                onChange={onBusinessPartnerChange}
            />
        </FormGroup>
    );
};

const Option = (props: OptionProps<BusinessPartner, false>) => {
    const {children} = props;
    return (
        <>
            <components.Option {...props}>
                <span data-notranlsate style={{whiteSpace: 'nowrap'}}>
                    {children}
                </span>
            </components.Option>
        </>
    );
};

const MenuList = ({
    options,
    children,
    getValue,
}: MenuListComponentProps<any, any>) => {
    const [value] = getValue();
    const OPTION_HEIGHT = 37;
    const ROWS = 6;
    const initialOffset =
        options.indexOf(value) !== -1
            ? Array.isArray(children) && children.length >= ROWS
                ? options.indexOf(value) >= ROWS
                    ? options.indexOf(value) * OPTION_HEIGHT - OPTION_HEIGHT * 5
                    : 0
                : 0
            : 0;

    return Array.isArray(children) ? (
        <FixedSizeList
            width={''}
            height={
                children.length >= ROWS
                    ? OPTION_HEIGHT * ROWS
                    : children.length * OPTION_HEIGHT + 20
            }
            itemCount={children.length}
            itemSize={OPTION_HEIGHT}
            initialScrollOffset={initialOffset}
        >
            {({style, index}) => {
                return <div style={style}>{children[index]}</div>;
            }}
        </FixedSizeList>
    ) : (
        <div>{children}</div>
    );
};

export default SelectBusinessPartner;
