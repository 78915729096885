/* eslint-disable */
import * as React from 'react';

const SvgChartValuations = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="87.31" height="87" {...props}>
            <path fill="#0f3e82" d="M5.116 81.884V0H0v87h87.31v-5.116z" />
            <path fill="#b5e3d8" d="M11.742 23.174H28.6v52.084H11.742z" />
            <path fill="#fecf84" d="M35.226 11.432h16.858v63.826H35.226z" />
            <path fill="#e03e52" d="M58.71 35.071h16.858v40.187H58.71z" />
        </svg>
    );
};

export default SvgChartValuations;
