const SvgPay = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="m17.573 7.554-1.188 9.118-8.004-9.106 9.192-.012Z"
        />
        <path
            fill="currentColor"
            d="M13.165 12.763 7.41 17.821l-1.32-1.502 5.753-5.058z"
        />
    </svg>
);

export default SvgPay;
