const SvgAddFile = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 38 48"
        {...props}
    >
        <path
            fill="currentColor"
            d="M18.585 19.5c.851 0 1.542.672 1.542 1.5v4.5h4.625c.851 0 1.541.672 1.541 1.5s-.69 1.5-1.541 1.5h-4.625V33c0 .828-.69 1.5-1.542 1.5-.852 0-1.542-.672-1.542-1.5v-4.5h-4.625c-.851 0-1.541-.672-1.541-1.5s.69-1.5 1.541-1.5h4.625V21c0-.828.69-1.5 1.542-1.5Z"
        />
        <path
            fill="currentColor"
            d="M37.085 13.5V42c0 3.314-2.76 6-6.167 6H6.252C2.846 48 .085 45.314.085 42V6c0-3.314 2.76-6 6.167-6H23.21l13.875 13.5Zm-9.25 0c-2.554 0-4.625-2.015-4.625-4.5V3H6.252C4.549 3 3.168 4.343 3.168 6v36c0 1.657 1.38 3 3.084 3h24.666c1.703 0 3.084-1.343 3.084-3V13.5h-6.167Z"
        />
    </svg>
);

export default SvgAddFile;
