import classNames from 'classnames';

import {Spinner} from 'react-bootstrap';
import FA from 'react-fontawesome';

import styles from './NextStepButton.module.scss';

interface INextStepButtonProps {
    disabled?: boolean;
    loading?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    className?: string;
    containerClassName?: string;
    label?: string;
    type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

const NextStepButton = (props: INextStepButtonProps) => {
    const {
        disabled,
        loading,
        onClick,
        className,
        containerClassName,
        label = 'Next',
        type = 'submit',
    } = props;

    return (
        <div className={classNames(styles.container, containerClassName)}>
            <button
                className={classNames(styles.button, className, {
                    [styles.disabled]: disabled,
                    [styles.loading]: loading,
                })}
                disabled={disabled || loading}
                type={type}
                onClick={onClick}
            >
                {label}
                {loading ? (
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className={styles.icon}
                    />
                ) : (
                    <FA name="arrow-right" className={styles.icon} />
                )}
            </button>
        </div>
    );
};

export default NextStepButton;
