const SvgGroup = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="25"
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            d="M5.25 10.714c1.914 0 3.5-1.562 3.5-3.571 0-1.953-1.586-3.572-3.5-3.572-1.969 0-3.5 1.619-3.5 3.572 0 2.009 1.531 3.571 3.5 3.571Zm24.5 0c1.914 0 3.5-1.562 3.5-3.571 0-1.953-1.586-3.572-3.5-3.572-1.969 0-3.5 1.619-3.5 3.572 0 2.009 1.531 3.571 3.5 3.571ZM31.5 12.5H28c-1.378 0-1.255 1.765-.345 2.8.417.474.78.998 1.079 1.561.793 1.49 2.158 2.782 3.846 2.782h.67c.93 0 1.75-.781 1.75-1.786v-1.786c0-1.953-1.586-3.571-3.5-3.571Zm-14 0c3.336 0 6.125-2.79 6.125-6.25 0-3.404-2.79-6.25-6.125-6.25-3.39 0-6.125 2.846-6.125 6.25 0 3.46 2.734 6.25 6.125 6.25Zm4.156 1.786c-.288 0-.57.068-.835.182a8.375 8.375 0 0 1-3.321.71 8.464 8.464 0 0 1-3.376-.71 2.114 2.114 0 0 0-.835-.182C9.79 14.286 7 17.188 7 20.759v1.562C7 23.828 8.148 25 9.625 25h15.75C26.797 25 28 23.828 28 22.321V20.76c0-3.572-2.844-6.473-6.344-6.473Zm-14.33.974C8.223 14.25 8.351 12.5 7 12.5H3.5c-1.969 0-3.5 1.618-3.5 3.571v1.786c0 1.005.766 1.786 1.75 1.786h.615c1.688 0 3.054-1.291 3.847-2.782a8.186 8.186 0 0 1 1.113-1.6Z"
        />
    </svg>
);

export default SvgGroup;
