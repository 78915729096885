/* eslint-disable */
import * as React from 'react';

const BackArrow = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_30047_39695"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="25"
            >
                <rect y="0.582031" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_30047_39695)">
                <path
                    d="M13.9995 18.2373L8.3457 12.5835L13.9995 6.92969L15.0534 7.98351L10.4534 12.5835L15.0534 17.1835L13.9995 18.2373Z"
                    fill="#3333FF"
                />
            </g>
        </svg>
    );
};

export default BackArrow;
