import classNames from 'classnames';
import React, {ButtonHTMLAttributes, MouseEventHandler} from 'react';
import {Spinner} from 'react-bootstrap';
import FA from 'react-fontawesome';

import styles from './Button.module.sass';

export interface IButtonProps {
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
    children?: React.ReactNode;
    isLoading?: boolean;
    variant?: 'outline' | 'primary' | 'text';
    color?:
        | 'primary'
        | 'secondary'
        | 'danger'
        | 'success'
        | 'lightgray'
        | 'white'
        | 'warning';
    icon?: string | React.ReactNode;
    iconOnly?: boolean;
    setReferenceElement?: (ref: HTMLButtonElement) => void;
    iconPosition?: 'left' | 'right';
    title?: string;
    translate?: boolean;
}

const Icon = ({
    icon,
    iconOnly,
    right,
}: {
    icon: IButtonProps['icon'];
    iconOnly: boolean;
    right?: boolean;
}) => {
    if (!icon) return null;

    if (typeof icon === 'string') {
        return (
            <FA
                name={icon}
                className={classNames(styles.faIcon, {
                    [styles.iconOnly]: iconOnly,
                    [styles.right]: right,
                })}
            />
        );
    }
    if (React.isValidElement(icon)) {
        return React.cloneElement(
            icon as React.ReactElement<{className: string}>,
            {
                className: classNames(styles.icon, icon.props.className, {
                    [styles.iconOnly]: iconOnly,
                    [styles.right]: right,
                }),
            },
        );
    }

    return null;
};

const Button = (props: IButtonProps) => {
    const {
        type = 'button',
        className,
        disabled,
        onClick,
        children,
        isLoading,
        variant = 'primary',
        color = 'primary',
        icon,
        iconOnly = false,
        setReferenceElement,
        iconPosition = 'left',
        title,
        translate = true,
    } = props;

    return (
        <button
            title={title}
            type={type}
            className={classNames(
                styles.button,
                className,
                {
                    [styles.outline]: variant === 'outline',
                    [styles.text]: variant === 'text',
                    [styles.withIcon]: !!icon,
                    [styles.rightIcon]: iconPosition === 'right',
                    [styles.loading]: isLoading,
                },
                styles[color],
            )}
            onClick={onClick}
            disabled={disabled || isLoading}
            ref={setReferenceElement}
            onMouseEnter={() => {
                //
            }}
            onMouseLeave={() => {
                //
            }}
            translate={translate ? 'yes' : 'no'}
        >
            {isLoading ? (
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{marginRight: '5px'}}
                />
            ) : null}
            {(!iconOnly || (iconOnly && !isLoading)) &&
            icon &&
            iconPosition === 'left' ? (
                <Icon icon={icon} iconOnly={iconOnly} />
            ) : null}
            {!iconOnly ? children : null}
            {(!iconOnly || (iconOnly && !isLoading)) &&
            icon &&
            iconPosition === 'right' ? (
                <Icon icon={icon} iconOnly={iconOnly} right />
            ) : null}
        </button>
    );
};

export default Button;
