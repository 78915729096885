import classNames from 'classnames';
import {translate} from 'helpers/localize';
import {useFormContext} from 'react-hook-form';
import InfoPopover from 'components/ui/InfoPopover/InfoPopover';
import {getDeepValue} from 'helpers/common';
import ErrorFeedback from '../ErrorFeedback/ErrorFeedback';
import {IDefaultControlProps} from '../Form/Form';

import styles from './TextArea.module.scss';

const TextArea = (props: IDefaultControlProps) => {
    const {
        name,
        label,
        placeholder,
        className,
        info,
        disabled,
        readOnly,
        variant,
        rounded = true,
        autoComplete,
        size,
    } = props;
    const {register, errors, setValue} = useFormContext();

    return (
        <div className={classNames(styles.container, className)}>
            <label htmlFor={name} className={styles.label}>
                {label}
                {info ? <InfoPopover content={info} /> : null}
            </label>
            <textarea
                className={classNames(styles.input, className, {
                    [styles.invalid]: getDeepValue(errors, name),
                    [styles.dark]: variant === 'dark',
                    [styles.rounded]: rounded,
                    [styles.disabled]: disabled || readOnly,
                    [styles.large]: size === 'large',
                })}
                name={name}
                ref={register}
                placeholder={translate(placeholder)}
                disabled={disabled}
                readOnly={readOnly}
                data-notranslate
                autoComplete={autoComplete}
                onBlur={e => {
                    setValue(name, e.target.value.trim());
                }}
            />
            {getDeepValue(errors, name)?.message ? (
                <ErrorFeedback message={getDeepValue(errors, name).message} />
            ) : null}
        </div>
    );
};

export default TextArea;
