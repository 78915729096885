import {components, OptionProps, SingleValueProps} from 'react-select';

import {ICountry} from 'types/api';
import CountryFlagSelect from 'components/ui/CountryFlag/CountryFlagSelect';
import AutoComplete, {IAutoCompleteProps} from './AutoComplete';

const CountryAutoComplete = (props: IAutoCompleteProps) => {
    const Option = (optionProps: OptionProps<ICountry, false>) => {
        const {data, children} = optionProps;
        return (
            <components.Option {...optionProps}>
                <CountryFlagSelect code={data.code} /> {` `}
                {children}
            </components.Option>
        );
    };

    const SingleValue = (singleValueProps: SingleValueProps<ICountry>) => {
        const {data, children} = singleValueProps;
        return (
            <components.SingleValue {...singleValueProps}>
                <CountryFlagSelect code={data.code} /> {` `}
                {children}
            </components.SingleValue>
        );
    };

    return (
        <AutoComplete
            components={{Option, SingleValue}}
            isClearable
            labelKey="label"
            sortKey="label"
            {...props}
            translateInputContent={false}
        />
    );
};

export default CountryAutoComplete;
