import {useLanguage} from 'context/language-context';

const analyticsEventKeys = [
    'app_demo_registration',
    'app_email_verification',
    'open_business_account',
    'open_private_account',
    'contract_generated',
] as const;
export type AnalyticsEventKey = typeof analyticsEventKeys[number];

const useAnalytics = () => {
    const {selectedLanguageParams} = useLanguage();

    const addEvent = (
        key: AnalyticsEventKey,
        params?: Record<string, string>,
    ) => {
        if (!window.dataLayer) return;

        window.dataLayer.push({
            event: key,
            language: selectedLanguageParams.code,
            ...params,
        });
    };

    return {addEvent};
};

export default useAnalytics;
