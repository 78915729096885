import styles from './PepInfo.module.scss';

const PepInfo = () => {
    return (
        <div className={styles.container}>
            <h3>Definition of PEP</h3>
            <h5>
                ‘politically exposed person’ means a natural person who is or
                who has been entrusted with prominent public functions and
                includes the following:
            </h5>
            <ol>
                <li>
                    heads of State, heads of government, ministers and deputy or
                    assistant ministers;
                </li>
                <li>members of parliament or of similar legislative bodies;</li>
                <li>members of the governing bodies of political parties;</li>
                <li>
                    members of supreme courts, of constitutional courts or of
                    other high-level judicial bodies, the decisions of which are
                    not subject to further appeal, except in exceptional
                    circumstances;
                </li>
                <li>
                    members of courts of auditors or of the boards of central
                    banks;
                </li>
                <li>
                    ambassadors, chargés d&apos;affaires and high-ranking
                    officers in the armed forces;
                </li>
                <li>
                    members of the administrative, management or supervisory
                    bodies of State-owned enterprises;
                </li>
                <li>
                    directors, deputy directors and members of the board or
                    equivalent function of an international organisation. No
                    public function referred to in points (a) to (h) shall be
                    understood as covering middle-ranking or more junior
                    officials;
                </li>
            </ol>
            <h5>‘family members’ includes the following:</h5>
            <ol>
                <li>
                    the spouse, or a person considered to be equivalent to a
                    spouse, of a politically exposed person;
                </li>
                <li>
                    the children and their spouses, or persons considered to be
                    equivalent to a spouse, of a politically exposed person;
                </li>
                <li>the parents of a politically exposed person;</li>
            </ol>
            <h5>‘persons known to be close associates’ means:</h5>
            <ol>
                <li>
                    natural persons who are known to have joint beneficial
                    ownership of legal entities or legal arrangements, or any
                    other close business relations, with a politically exposed
                    person;
                </li>
                <li>
                    natural persons who have sole beneficial ownership of a
                    legal entity or legal arrangement which is known to have
                    been set up for the de facto benefit of a politically
                    exposed person.
                </li>
            </ol>
        </div>
    );
};

export default PepInfo;
