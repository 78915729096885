import ContentLoader from 'react-content-loader';

const SidebarWalletDropdownLoader = () => (
    <ContentLoader
        speed={2}
        width={240}
        height={80}
        viewBox="0 0 240 80"
        backgroundColor="#0F3E82"
        foregroundColor="#fafcff"
    >
        <circle cx="210" cy="22" r="12" />
        <rect x="12" y="11" rx="5" ry="5" width="130" height="24" />
        <rect x="12" y="49" rx="5" ry="5" width="80" height="14" />
        <rect x="108" y="50" rx="5" ry="5" width="80" height="12" />
        <rect x="204" y="52" rx="36" ry="36" width="10" height="10" />
    </ContentLoader>
);

export default SidebarWalletDropdownLoader;
