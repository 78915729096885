const InsightsIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 200 200"
        {...props}
    >
        <path
            fill="none"
            stroke="#0f3e82"
            strokeMiterlimit="10"
            strokeWidth="10"
            d="M155.16 108.06a45 45 0 0 0-63.22-63.22m-7.07 7.07a45 45 0 0 0 63.22 63.22"
        />
        <path
            fill="none"
            stroke="#b5e3d8"
            strokeMiterlimit="10"
            strokeWidth="10"
            d="M102.34 62.31a25 25 0 0 0 35.35 35.35"
        />
        <path
            fill="none"
            stroke="#0f3e82"
            strokeMiterlimit="10"
            strokeWidth="10"
            d="m77.61 122.39-42.6 42.6"
        />
    </svg>
);

export default InsightsIcon;
