import {lazy, Suspense, useEffect} from 'react';
import * as Sentry from '@sentry/react';
import qs from 'qs';

import Loader from 'components/ui/AmnisLoader/AmnisLoader';
import {useAuth} from 'context/auth-context';
import {useBusinessPartner} from 'context/business-partner-context';
import {useLanguage} from 'context/language-context';
import {useEventSource} from 'context/event-source-context';
import {WALLETS_CACHE, SINGLE_BP_CACHE, WALLET_CACHE} from 'constants/cache';
import {BusinessPartner} from 'types/api';
import {useHistory} from 'react-router';
import useModal from 'components/ui/ModalContainer/useModal';
import ConfirmationOverlay from 'components/ui/Overlays/ConfirmationOverlay/ConfirmationOverlay';
import {PATH} from 'constants/common';
import {useQueryClient} from '@tanstack/react-query';

declare global {
    // eslint-disable-next-line @typescript-eslint/interface-name-prefix
    interface Window {
        Intercom: any;
        dataLayer: {
            push: (args: Record<string, string> & {event: string}) => void;
        };
        __env__: {
            MAINTENANCE_ACTIVE: '0' | '1';
            MAINTENANCE_START: string;
        };
    }
}

const AuthenticatedApp = lazy(
    () => import(/* webpackPrefetch: true */ './AuthenticatedApp'),
);
const UnauthenticatedApp = lazy(() => import('./UnauthenticatedApp'));

const INTERCOM_APP_ID = 'jk38lwyv';

const App = () => {
    const {user, redirectUrl} = useAuth();
    const {activeBusinessPartner, businessPartners} = useBusinessPartner();
    const {selectedLanguage, updateLanguage} = useLanguage();
    const {registerListener} = useEventSource();
    const queryClient = useQueryClient();

    const params = qs.parse(window.location.search, {ignoreQueryPrefix: true});

    const {openModalWithContent, closeModal} = useModal();
    const history = useHistory();

    useEffect(() => {
        if (params?.error === 'export-expired') {
            openModalWithContent(
                'confirmation',
                <ConfirmationOverlay
                    title="Download link expired"
                    description={
                        <p>
                            Your download link for the zip archive has expired.
                            To access the required debit card information,
                            please create a new export.
                        </p>
                    }
                    onConfirm={() => {
                        closeModal('confirmation');
                        history.push({
                            pathname: PATH.STATEMENTS,
                        });
                    }}
                    confirmLabel={user ? 'Create new export' : 'Ok'}
                />,
            );
        }
    }, [closeModal, history, openModalWithContent, params, user]);

    useEffect(() => {
        if (params.language && !user?.language) {
            updateLanguage(params.language.toString().toLowerCase());
        }
    }, [params, updateLanguage, user]);

    useEffect(() => {
        if (window.Intercom) {
            window.Intercom('boot', {
                app_id: INTERCOM_APP_ID,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.Intercom]); // Stupid TS
    useEffect(() => {
        if (activeBusinessPartner && window.Intercom) {
            window.Intercom('update', {
                relation_ship_manager_id:
                    activeBusinessPartner.relationshipManagerId,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeBusinessPartner, window.Intercom]); // Stupid TS
    useEffect(() => {
        if (businessPartners && window.Intercom) {
            window.Intercom('update', {
                demo: businessPartners.reduce(
                    (
                        accumulator: boolean,
                        currentValue: BusinessPartner,
                    ): boolean => {
                        if (accumulator === false) {
                            return accumulator;
                        }
                        return currentValue.sandbox;
                    },
                    false,
                ),
                demo_in_onboarding: businessPartners.reduce(
                    (
                        accumulator: boolean,
                        currentValue: BusinessPartner,
                    ): boolean => {
                        if (accumulator === true) {
                            return accumulator;
                        }
                        return (
                            currentValue.sandbox === false &&
                            currentValue.identified === false
                        );
                    },
                    false,
                ),
                onboarded: businessPartners.reduce(
                    (
                        accumulator: boolean,
                        currentValue: BusinessPartner,
                    ): boolean => {
                        if (accumulator === true) {
                            return accumulator;
                        }
                        return (
                            currentValue.sandbox === false &&
                            currentValue.identified === true
                        );
                    },
                    false,
                ),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessPartners, window.Intercom]); // Stupid TS
    useEffect(() => {
        if (
            user &&
            Object.keys(user).length &&
            selectedLanguage &&
            window.Intercom
        ) {
            window.Intercom('update', {
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                user_id: user['@id'].split('/').pop(),
                user_hash: user.intercomHash,
                language: selectedLanguage,
                needsIdentification: user.needsIdentification,
                emailVerified: user.emailVerified,
                createdAt: user.createdAt,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, selectedLanguage, window.Intercom]); // Stupid TS

    useEffect(() => {
        const unregister = registerListener('overallBalanceUpdated', {
            area: 'available_balance',
            callback: event => {
                queryClient.invalidateQueries({queryKey: [SINGLE_BP_CACHE]});
                queryClient.invalidateQueries({queryKey: [WALLETS_CACHE]});
                queryClient.invalidateQueries({queryKey: [WALLET_CACHE]});
            },
        });

        return () => unregister();
    }, [queryClient, registerListener]);

    Sentry.setContext('Userinfo', {
        user,
        activeBusinessPartner,
    });

    return (
        <Suspense fallback={<Loader big />}>
            {user ? (
                <AuthenticatedApp redirectUrl={redirectUrl} />
            ) : (
                <UnauthenticatedApp />
            )}
        </Suspense>
    );
};

export default App;
