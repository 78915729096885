import * as React from 'react';

const SvgRegisterMoney = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
        {...props}
    >
        <path
            fill="#B3DFD8"
            fillRule="evenodd"
            d="M13.7094 24.0063H12l.8205.8225.8889-.8225Zm20.5133-.0003h1.7094l-.8206-.8224-.8888.8224Z"
            clipRule="evenodd"
        />
        <path
            fill="#B3DFD8"
            d="M23.9658 35.9998c-2.4615 0-4.8547-.7539-6.8376-2.1931-2.3932-1.7134-4.1026-4.1807-4.7864-7.0592l1.641-.4112c.547 2.4673 2.0513 4.5919 4.1026 6.0311 2.0513 1.4393 4.5813 2.0561 7.1112 1.782 2.5299-.2742 4.7863-1.5078 6.4958-3.3583 1.641-1.919 2.5983-4.3178 2.5983-6.8536h1.7094c0 2.947-1.094 5.757-3.0086 8.0187-1.9829 2.1932-4.6496 3.6324-7.5898 3.9751-.4786 0-.9572.0685-1.4359.0685Zm-10.2564-12.062H12c0-2.947 1.094-5.757 3.0086-7.9501 1.9829-2.1932 4.6496-3.5639 7.5214-3.9066 2.9402-.3427 5.812.4112 8.2736 2.0561 2.3931 1.7134 4.1026 4.1807 4.7863 7.0592l-1.641.4112c-.6154-2.4673-2.0513-4.5919-4.1026-6.0312-2.0513-1.4392-4.5813-2.056-7.0428-1.7819-2.5299.2742-4.7864 1.5078-6.4958 3.3583-1.7094 1.8504-2.5983 4.3177-2.5983 6.785Z"
        />
        <path
            fill="#B3DFD8"
            d="M26.8374 25.6511c0 .2742-.0684.6169-.1368.891-.1367.2741-.2735.5483-.4786.7539-.4786.4112-1.0257.6854-1.7094.7539v.9595h-.8205v-.9595c-.6154 0-1.1624-.2056-1.6411-.5483-.4786-.3427-.8205-.7539-1.0256-1.3022l1.5726-.8909c.0684.2741.2052.4797.4103.6168s.4103.2741.6838.3427v-1.5764h-.0684s-.0684 0-.0684-.0685c-.2735-.0685-.4786-.2056-.6154-.2741-.2051-.0686-.4102-.2057-.547-.3427-.2051-.1371-.3419-.2742-.547-.4798-.1368-.2056-.2735-.4112-.3419-.6168-.0684-.2741-.1367-.5483-.1367-.8224 0-.3427.0683-.6169.1367-.891.1368-.2741.2735-.5483.547-.7539.4787-.4112 1.0257-.6168 1.6411-.6854v-1.028h.8205v1.028c.4786.0686.9573.2057 1.3675.4798.4103.2741.6838.6854.8889 1.1651l-1.5043.891c-.0683-.1371-.1367-.3427-.2735-.4798-.1367-.137-.2735-.2056-.4102-.2741v1.5078l.4102.137c.2052.0686.3419.1371.4103.2056.0684.0686.2051.0686.3419.2057.1367.0685.2735.137.4102.2741l.2735.2741c.1368.1371.2052.2057.2735.4113.0684.137.1368.2741.1368.4797.0684.2056.0684.4112 0 .6168Zm-3.624-3.4268c0 .2056.1368.4113.4787.6169v-1.2337c-.1368 0-.2735.0686-.3419.2056-.0684.1371-.1368.2742-.1368.4112Zm1.2992 4.1122c.1367 0 .2735-.0685.3419-.2056.0683-.1371.1367-.2742.1367-.4112 0-.2742-.1367-.4798-.547-.6169v1.2337h.0684Z"
        />
        <rect width="47" height="47" x=".5" y=".5" stroke="#66F" rx="23.5" />
    </svg>
);

export default SvgRegisterMoney;
