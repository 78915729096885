const Invoice = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 25"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M4 3.039V22.73l1.303-1.19 1.34 1.19 1.339-1.19 1.34 1.19 1.339-1.19L12 22.732l1.34-1.19 1.339 1.19 1.34-1.19 1.338 1.19 1.34-1.19L20 22.732V3.039H4Zm14.588 17.485H5.412V4.488h13.176v16.036ZM7.077 7.962h9.846v1.52H7.077v-1.52Zm0 3.04h5.202v1.52H7.077v-1.52Z"
            clipRule="evenodd"
        />
    </svg>
);

export default Invoice;
