import React, {useEffect, useState} from 'react';
import {IDefaultStepProps} from 'components/pages/OnboardingPage/useOnboarding';
import NextStepButton from 'components/pages/OnboardingPage/components/NextStepButton';
import StepTitle from 'components/pages/OnboardingPage/components/StepTitle';
import Form, {FormOnSubmit, WithFormError} from 'components/ui/Form/Form/Form';
import CountryValidity from 'components/pages/NewCardPage/components/CountryValidity/CountryValidity';
import {useCountries} from 'api/countries';
import Button from 'components/ui/Button';
import classNames from 'classnames';
import {findOnboardingStepByNumber} from 'components/pages/OnboardingPage/onboardingSteps';
import {StepTypes} from 'types/onboarding';
import PaymentCustomers from 'components/pages/OnboardingPage/components/PaymentCustomers';
import {useForm} from 'react-hook-form';
import {useUpdateBusinessPartnerOnboarding} from 'api/business-partner';
import {handleTypedFormError} from 'helpers/handleFormError';
import {ICountry, PaymentCustomer} from 'types/api';
import {v4 as uuidv4} from 'uuid';
import {
    IncomingAndOutgoingPaymentsFormValues,
    incomingAndOutgoingPaymentsValidationSchema,
} from 'form/validation/businessPartner';
import {yupResolver} from '@hookform/resolvers';
import {countryValidityStore} from 'components/pages/NewCardPage/components/CountryValidity/countryValidityStore';
import styles from './Steps.module.scss';

export const INCOMING_AND_OUTGOING_PAYMENTS_STEPS = [
    StepTypes.INCOMING_PAYMENTS,
    StepTypes.OUTGOING_PAYMENTS,
] as const;

export type IcomingAndOutgoingPayments =
    (typeof INCOMING_AND_OUTGOING_PAYMENTS_STEPS)[number];

export const extractCustomerData = (
    customers: PaymentCustomer[],
    countries: ICountry[],
): PaymentCustomer[] | {error: string} => {
    const result: PaymentCustomer[] = [];
    let error: string = '';
    let totalPercentage = 0;
    customers
        .filter(c => !!c.name)
        .forEach(customer => {
            const country = countries.find(c => c['@id'] === customer.country);

            if (country?.riskLevel !== 'HIGH')
                result.push({
                    country: customer.country,
                    name: customer.name,
                    uniqueId: customer.uniqueId,
                });

            if (country?.riskLevel === 'HIGH') {
                if (!customer.percentage) {
                    error = 'Percentage is missing';
                    return;
                }
                totalPercentage += +customer.percentage;
                if (+customer.percentage > 100 || totalPercentage > 100) {
                    error = 'Percentage cannot exceed 100%';
                }
                if (Object.is(Number(customer.percentage), NaN)) {
                    error = 'Invalid percentage';
                    return;
                }
                result.push({
                    ...customer,
                    percentage: Number(customer.percentage),
                });
            }
        });

    return error ? {error} : result;
};

export const findCountriesByIri = (
    countries: ICountry[],
    countryIris: string[],
) => {
    return countries.filter(c => countryIris.includes(c['@id']));
};

const IncomingPayments = (props: IDefaultStepProps) => {
    const {
        onSuccess,
        currentStep,
        setActiveBusinessPartner,
        activeBusinessPartner,
    } = props;

    const step = findOnboardingStepByNumber(currentStep)
        ?.type as IcomingAndOutgoingPayments;

    const {countries} = useCountries();
    const enableCountries = countryValidityStore(
        state => state.setCountriesEnabled,
    );
    const enabledCountres = countryValidityStore(
        state => state.enabledCountries,
    );
    const resetState = countryValidityStore(state => state.resetState);
    const [showCountryValidity, setShowCountryValidity] = useState(false);

    useEffect(() => {
        if (
            countries &&
            activeBusinessPartner.gwgInfo?.incomingPaymentCountries?.length
        ) {
            enableCountries(
                findCountriesByIri(
                    countries,
                    activeBusinessPartner.gwgInfo?.incomingPaymentCountries,
                ),
            );
        } else if (activeBusinessPartner.countryOfIncorporation) {
            enableCountries(
                findCountriesByIri(countries, [
                    activeBusinessPartner.countryOfIncorporation,
                ]),
            );
        }

        return () => resetState();
    }, [activeBusinessPartner, countries, step, enableCountries, resetState]);

    const {mutate: addAdditionalInfo, isPending: isLoading} =
        useUpdateBusinessPartnerOnboarding();

    const formMethods = useForm<
        WithFormError<IncomingAndOutgoingPaymentsFormValues>
    >({
        defaultValues: {
            onlyCountries: activeBusinessPartner.gwgInfo
                ?.incomingPaymentCountries.length
                ? activeBusinessPartner.gwgInfo?.incomingPaymentCountries
                : [activeBusinessPartner.countryOfIncorporation],
            gwgInfo: {
                type: step,
                incomingPaymentCustomers: activeBusinessPartner?.gwgInfo
                    ?.incomingPaymentCustomers.length
                    ? activeBusinessPartner?.gwgInfo?.incomingPaymentCustomers
                    : [
                          {
                              name: '',
                              website: '',
                              country: '',
                              percentage: '',
                              uniqueId: uuidv4(),
                          },
                          {
                              name: '',
                              website: '',
                              country: '',
                              percentage: '',
                              uniqueId: uuidv4(),
                          },
                          {
                              name: '',
                              website: '',
                              country: '',
                              percentage: '',
                              uniqueId: uuidv4(),
                          },
                      ],
            },
        },
        resolver: yupResolver(incomingAndOutgoingPaymentsValidationSchema),
        shouldUnregister: false,
    });

    const {register} = formMethods;
    register('gwgInfo.type', {required: true});

    const handleSubmit: FormOnSubmit<IncomingAndOutgoingPaymentsFormValues> = (
        data,
        setError,
    ) => {
        const customers = extractCustomerData(
            data.gwgInfo.incomingPaymentCustomers || [],
            countries,
        );

        if (!Array.isArray(customers)) {
            setError('gwgInfo.incomingPaymentCustomers', {
                message: customers.error,
            });
            return;
        }
        addAdditionalInfo(
            {
                gwgInfo: {
                    ...data.gwgInfo,
                    id: activeBusinessPartner.gwgInfo?.id,
                    incomingPaymentCountries: enabledCountres,
                    incomingPaymentCustomers: customers,
                },
                step: findOnboardingStepByNumber(currentStep)?.type,
                businessPartnerId: activeBusinessPartner['@id'],
            },
            {
                onSuccess: response => {
                    setActiveBusinessPartner(response);
                    onSuccess({refetchCrpList: true});
                },
                onError: (requestErrors: any) => {
                    handleTypedFormError(requestErrors, setError, data);
                },
            },
        );
    };

    return (
        <>
            <StepTitle title="Tell us about your incoming payments" />
            <Form<IncomingAndOutgoingPaymentsFormValues>
                onSubmit={handleSubmit}
                formMethods={formMethods}
            >
                {showCountryValidity ? (
                    <div className={styles.form}>
                        <CountryValidity
                            title=""
                            description="From which countries do your payments originate?"
                            cancel={() => setShowCountryValidity(false)}
                            saveCountriesByContinent={_countries => {
                                setShowCountryValidity(false);
                            }}
                            defaultOpen={
                                enabledCountres?.length < 10
                                    ? 'single'
                                    : 'multi'
                            }
                        />
                    </div>
                ) : (
                    <>
                        <div className={styles.form}>
                            <p className={styles.label}>
                                From which countries do your payments originate?
                            </p>
                            <div
                                className={classNames(
                                    styles.countryValidity,
                                    styles.dark,
                                    styles.rounded,
                                    styles.large,
                                )}
                            >
                                <span>Country validity</span>
                                <Button
                                    onClick={() => {
                                        setShowCountryValidity(true);
                                    }}
                                    variant="text"
                                >
                                    {enabledCountres.length ===
                                    countries.length ? (
                                        'All countries (edit selection)'
                                    ) : (
                                        <>
                                            <var data-var="nrOfAllowedCountries">
                                                {enabledCountres?.length}
                                            </var>
                                            &nbsp;
                                            {enabledCountres?.length === 1
                                                ? 'country'
                                                : 'countries'}
                                            &nbsp;(edit selection)
                                        </>
                                    )}
                                </Button>
                            </div>
                        </div>
                        <PaymentCustomers name="incomingPaymentCustomers" />
                        <div className={styles.form}>
                            <NextStepButton loading={isLoading} />
                        </div>
                    </>
                )}
            </Form>
        </>
    );
};

export default IncomingPayments;
