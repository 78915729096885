import React, {useState} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {useAuth} from 'context/auth-context';
import {useGetAuthFactors} from 'api/futurae';
import Loader from 'components/ui/AmnisLoader/AmnisLoader';
import TwoFAPage from 'components/pages/TwoFAPage/TwoFAPage';
import {IDENTIFICATION_LIST_CACHE} from 'constants/cache';
import {LISTENER_KEYS} from 'types/eventsource';
import {translate} from 'helpers/localize';
import {parseJwt} from 'helpers/common';
import {IDefaultStepProps} from '../useOnboarding';
import StepTitle from '../components/StepTitle';

import styles from './Steps.module.scss';
import {useQueryClient} from '@tanstack/react-query';

const TwoFactor = (props: IDefaultStepProps) => {
    const {onSuccess, onCancel} = props;
    const {token} = useAuth();
    const [error, setError] = useState(false);
    const queryClient = useQueryClient();

    const {data: authFactors, isLoading: isLoadingAuthFactors} =
        useGetAuthFactors(token);

    if (isLoadingAuthFactors) {
        return <Loader />;
    }

    const handleAuthResponse = (isSuccess: boolean, newToken?: string) => {
        if (isSuccess) {
            onSuccess();
        } else {
            setError(true);
        }
    };

    const verifyErrorProps = {
        title: 'Your account verification FAILED.',
        text: 'Please try to login again.',
        btnTitle: 'Login Again',
        btnSize: '8px',
    };

    const hasSms2faSetup = authFactors?.factors?.includes('sms');

    const render2FA = () => {
        return (
            <TwoFAPage
                token={token}
                handleAuthResponse={handleAuthResponse}
                verifyErrorProps={verifyErrorProps}
                onboarding
                enroll={!hasSms2faSetup}
                type="onboarding"
                listenerKey={LISTENER_KEYS.ONBOARDING_MERCURE_KEY}
                listenerHandler={{
                    area: 'onboarding',
                    callback: message => {
                        queryClient.invalidateQueries({
                            queryKey: [IDENTIFICATION_LIST_CACHE],
                        });
                    },
                }}
            />
        );
    };

    const renderError = () => {
        return (
            <Row>
                <Col>Please try again</Col>
                <Col>
                    <div className={styles.buttons}>
                        <Button variant="outline-primary" onClick={onCancel}>
                            Cancel
                        </Button>
                    </div>
                    <Button
                        variant="outline-primary"
                        onClick={() => setError(false)}
                    >
                        Try again
                    </Button>
                </Col>
            </Row>
        );
    };

    return (
        <div className={styles.form}>
            <StepTitle
                title={
                    hasSms2faSetup
                        ? 'Enter security code'
                        : 'Enhance your security - enable 2FA'
                }
                subTitle={
                    hasSms2faSetup
                        ? translate(
                              'Please enter the security code that we have just sent via SMS to the following telephone number: %{phoneNumber}',
                              {
                                  phoneNumber: parseJwt(token)?.phoneNumber,
                              },
                          )
                        : 'In order to be able to confirm your identity in the next step, we will send you a security code via SMS. Is the phone number below your number and can you receive SMS on this phone? If not, please change the number.'
                }
            />
            {error ? renderError() : render2FA()}
        </div>
    );
};

export default TwoFactor;
