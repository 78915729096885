import Form, {FormOnSubmit} from 'components/ui/Form/Form/Form';
import OnboardingCheckBoxGroup from 'components/ui/Form/OnboardingCheckBoxGroup/OnboardingCheckBoxGroup';
import TextArea from 'components/ui/Form/TexArea/TextArea';
import {
    PepBpFormValues,
    pepBpValidationSchema,
} from 'form/validation/businessPartner';
import {handleTypedFormError} from 'helpers/handleFormError';
import React, {useEffect, useState} from 'react';
import {useUpdateBusinessPartnerOnboarding} from 'api/business-partner';
import {findOnboardingStepByNumber} from 'components/pages/OnboardingPage/onboardingSteps';
import PepInfo from '../components/Infos/PepInfo';
import NextStepButton from '../components/NextStepButton';
import StepTitle from '../components/StepTitle';
import {IDefaultStepProps} from '../useOnboarding';

import styles from './Steps.module.scss';

const PEP_OPTIONS = [
    {
        label: 'Yes',
        value: 'yes',
    },
    {label: 'No', value: 'no'},
];

const PepBp = (props: IDefaultStepProps) => {
    const {onSuccess, activeBusinessPartner, currentStep} = props;
    const [showDescription, setShowDescription] = useState<boolean>(false);
    const [defaultValues, setDefaultValues] = useState<PepBpFormValues>({
        gwgInfo: {
            pep: '',
            pepDescription: '',
        },
    });
    const {mutate: updateBp, isPending: isLoading} =
        useUpdateBusinessPartnerOnboarding();

    useEffect(() => {
        let defaultPepValue = '';
        if (activeBusinessPartner.gwgInfo?.pep === true)
            defaultPepValue = 'yes';
        if (activeBusinessPartner.gwgInfo?.pep === false)
            defaultPepValue = 'no';
        setDefaultValues({
            gwgInfo: {
                pep: defaultPepValue,
                pepDescription:
                    activeBusinessPartner.gwgInfo?.pepDescription || '',
            },
        });
        setShowDescription(!!activeBusinessPartner.gwgInfo?.pep);
    }, [activeBusinessPartner]);

    const handleSubmit: FormOnSubmit<PepBpFormValues> = (values, setError) => {
        updateBp(
            {
                gwgInfo: {
                    ...values.gwgInfo,
                    // TODO - fix after yup upgrade
                    //@ts-ignore
                    pep: values.gwgInfo.pep === 'yes',
                    id: activeBusinessPartner.gwgInfo?.['@id'],
                },
                businessPartnerId: activeBusinessPartner['@id'],
                step: findOnboardingStepByNumber(currentStep)?.type,
            },
            {
                onSuccess: () => onSuccess({refetchCrpList: true}),
                onError: (requestErrors: any) =>
                    handleTypedFormError(requestErrors, setError, values),
            },
        );
    };
    return (
        <>
            <StepTitle title="Politically exposed person" info={<PepInfo />} />
            <Form<PepBpFormValues>
                onSubmit={handleSubmit}
                className={styles.form}
                onControlValueChange={values => {
                    // TODO - fix after yup upgrade
                    //@ts-ignore
                    setShowDescription(values.gwgInfo.pep === 'yes');
                }}
                validationSchema={pepBpValidationSchema}
                defaultValues={defaultValues}
            >
                <OnboardingCheckBoxGroup
                    type="radio"
                    name="gwgInfo.pep"
                    variant="dark"
                    options={PEP_OPTIONS}
                    label="Do you or any Management board member / Ultimate Beneficiary Owner or Controlling person within your company currently hold or have exercised (within last 12 months) an important public function of national or regional significance, regardless of where you exercise this function?"
                />
                {showDescription ? (
                    <TextArea
                        name="gwgInfo.pepDescription"
                        label="Please specify the name(s) and date(s) of birth of the individual(s), their function, and the duration of the period:"
                        variant="dark"
                    />
                ) : null}
                <NextStepButton loading={isLoading} />
            </Form>
        </>
    );
};

export default PepBp;
