import {useEffect, useState} from 'react';

import {IBusinessIndustry} from 'api/business-industries';
import {
    ICrpBpListItem,
    useGetBusinessActivities,
    useGetBusinessPartnerCardPurposes,
    useGetBusinessPartnerPaymentTypes,
} from 'api/business-partner';
import {BusinessPartner, GwgInfo, ICountry} from 'types/api';
import {useLegalForm} from 'api/legal-forms';
import {useParameters} from 'api/parameters';
import {
    BUDGET_LIST,
    CrpVariants,
    CRP_ROLE_MAPPING,
    REASON_FOR_AMNIS_LABEL_MAPPING,
    PAYMENT_SIZES,
    PAYMENT_VOLUME,
    CARD_VOLUME,
    CUSTOMER_RELATION_INCOMING_FLOW_OPTIONS,
    GENERATE_INCOMING_FLOW_OPTIONS,
    OWNER_SOURCE_OF_FUNDS,
    INVESTMENT_AMOUNTS,
} from 'constants/businessPartnerAttributes';
import {OWNER_PERCENTAGE_OPTIONS, SOURCE_OF_FUNDS} from 'constants/common';
import {
    formatDateInZurichTZ,
    formatDateWithoutTZConversion,
} from 'helpers/dates';
import {translate} from 'helpers/localize';
import {LegalType, StepTypes} from 'types/onboarding';
import {ICrpBpData, ICrpBpPersonalData} from 'api/crp';
import {convertToTranslatedOptions} from './steps/BusinessActivities';

const CUSTOM_KEYS = [
    'personalInformation',
    'ownerInfo',
    'signersInfo',
    'email',
] as const;
export type CustomKeys = (typeof CUSTOM_KEYS)[number];

export type FixedKeys =
    | keyof GwgInfo
    | keyof ICrpBpData
    | keyof ICrpBpPersonalData
    | keyof BusinessPartner
    | StepTypes;

export const DETAILS_MAPPING: Partial<
    Record<
        FixedKeys | CustomKeys,
        {[LegalType.COMPANY]: string; [LegalType.INDIVIDUAL]: string}
    >
> = {
    [StepTypes.CREATE_BP]: {
        [LegalType.COMPANY]: 'Company information',
        [LegalType.INDIVIDUAL]: 'Business Partner information',
    },
    [StepTypes.BUSINESS_ACTIVITIES]: {
        [LegalType.COMPANY]: 'Business activity',
        [LegalType.INDIVIDUAL]: 'Business activity',
    },
    [StepTypes.BUSINESS_ATTRIBUTES]: {
        [LegalType.COMPANY]: 'Business attributes',
        [LegalType.INDIVIDUAL]: 'Business attributes',
    },
    [StepTypes.REASON_FOR_AMNIS]: {
        [LegalType.COMPANY]: 'Reason for amnis',
        [LegalType.INDIVIDUAL]: 'Reason for amnis',
    },
    [StepTypes.GENERAL_PAYMENTS]: {
        [LegalType.COMPANY]: 'Payments',
        [LegalType.INDIVIDUAL]: 'Payments',
    },
    [StepTypes.DEBIT_CARD]: {
        [LegalType.COMPANY]: 'Debit cards',
        [LegalType.INDIVIDUAL]: 'Debit cards',
    },
    [StepTypes.FX]: {
        [LegalType.COMPANY]: 'Currency exchange',
        [LegalType.INDIVIDUAL]: 'Currency exchange',
    },
    [StepTypes.SOURCE_OF_WEALTH]: {
        [LegalType.COMPANY]: 'Source of wealth',
        [LegalType.INDIVIDUAL]: 'Source of wealth',
    },
    companyName: {
        [LegalType.COMPANY]: 'Company name',
        [LegalType.INDIVIDUAL]: 'Name',
    },
    legalForm: {
        [LegalType.COMPANY]: 'Legal form',
        [LegalType.INDIVIDUAL]: 'Legal form',
    },
    incorporationNumber: {
        [LegalType.COMPANY]: 'Company registration number',
        [LegalType.INDIVIDUAL]: 'Company registration number',
    },
    address: {
        [LegalType.COMPANY]: 'Street and house number',
        [LegalType.INDIVIDUAL]: 'Street and house number',
    },
    zip: {
        [LegalType.COMPANY]: 'Postal code',
        [LegalType.INDIVIDUAL]: 'Postal code',
    },
    city: {
        [LegalType.COMPANY]: 'City',
        [LegalType.INDIVIDUAL]: 'City',
    },
    countryOfIncorporation: {
        [LegalType.COMPANY]: 'Company headquarters',
        [LegalType.INDIVIDUAL]: 'Country',
    },
    countryOfBusiness: {
        [LegalType.COMPANY]: 'Main business activity (country)',
        [LegalType.INDIVIDUAL]: 'Main business activity (country)',
    },
    businessIndustry: {
        [LegalType.COMPANY]: 'Branch',
        [LegalType.INDIVIDUAL]: 'Branch',
    },
    numberEmployees: {
        [LegalType.COMPANY]: 'Employee',
        [LegalType.INDIVIDUAL]: 'Employee',
    },
    returnBudget: {
        [LegalType.COMPANY]: 'Budgeted annual sales',
        [LegalType.INDIVIDUAL]: 'Budgeted annual sales',
    },
    sourceOfFunds: {
        [LegalType.COMPANY]: 'Origin of the funds',
        [LegalType.INDIVIDUAL]: 'Origin of the funds',
    },
    operationalBusiness: {
        [LegalType.COMPANY]:
            'I confirm that the company is operational and has its own office.',
        [LegalType.INDIVIDUAL]:
            'I confirm that the company is operational and has its own office.',
    },
    personalInformation: {
        [LegalType.COMPANY]: 'Personal information',
        [LegalType.INDIVIDUAL]: 'Personal information',
    },
    firstName: {
        [LegalType.COMPANY]: 'First name(s)',
        [LegalType.INDIVIDUAL]: 'First name(s)',
    },
    lastName: {
        [LegalType.COMPANY]: 'Last name',
        [LegalType.INDIVIDUAL]: 'Last name',
    },
    gender: {
        [LegalType.COMPANY]: 'Gender',
        [LegalType.INDIVIDUAL]: 'Gender',
    },
    nationality: {
        [LegalType.COMPANY]: 'Nationality',
        [LegalType.INDIVIDUAL]: 'Nationality',
    },
    dateOfBirth: {
        [LegalType.COMPANY]: 'Date of birth',
        [LegalType.INDIVIDUAL]: 'Date of birth',
    },
    ownerInfo: {
        [LegalType.COMPANY]: 'Additional information beneficial owners',
        [LegalType.INDIVIDUAL]: 'Additional information beneficial owners',
    },
    directOwner: {
        [LegalType.COMPANY]: 'Direct owner',
        [LegalType.INDIVIDUAL]: 'Direct owner',
    },
    ownerPercentage: {
        [LegalType.COMPANY]: 'Share in percent in the company',
        [LegalType.INDIVIDUAL]: 'Share in percent in the company',
    },
    signersInfo: {
        [LegalType.COMPANY]: 'Additional information authorized signatories',
        [LegalType.INDIVIDUAL]: 'Additional information authorized signatories',
    },
    collectiveSigningRight: {
        [LegalType.COMPANY]: 'Type of authority to sign',
        [LegalType.INDIVIDUAL]: 'Type of authority to sign',
    },
    role: {
        [LegalType.COMPANY]: 'Position in company',
        [LegalType.INDIVIDUAL]: 'Position in company',
    },
    email: {
        [LegalType.COMPANY]: 'E-mail address',
        [LegalType.INDIVIDUAL]: 'E-mail address',
    },
    phoneNumber: {
        [LegalType.COMPANY]: 'Phone number',
        [LegalType.INDIVIDUAL]: 'Phone number',
    },
    dateOfIncorporation: {
        [LegalType.COMPANY]: 'Date of incorporation',
        [LegalType.INDIVIDUAL]: 'Date of incorporation',
    },
    businessActivities: {
        [LegalType.COMPANY]: 'Company activities',
        [LegalType.INDIVIDUAL]: 'Company activities',
    },
    physicalPresence: {
        [LegalType.COMPANY]: 'Physical presence',
        [LegalType.INDIVIDUAL]: 'Physical presence',
    },
    website: {
        [LegalType.COMPANY]: 'Company website',
        [LegalType.INDIVIDUAL]: 'Company website',
    },
    reasonForAmnis: {
        [LegalType.COMPANY]: 'Purpose of amnis account',
        [LegalType.INDIVIDUAL]: 'Purpose of amnis account',
    },
    paymentTypes: {
        [LegalType.COMPANY]: 'Payment types',
        [LegalType.INDIVIDUAL]: 'Payment types',
    },
    paymentSize: {
        [LegalType.COMPANY]: 'Average amount',
        [LegalType.INDIVIDUAL]: 'Average amount',
    },
    paymentVolume: {
        [LegalType.COMPANY]: 'Estimateted payment volume',
        [LegalType.INDIVIDUAL]: 'Estimateted payment volume',
    },
    informationCollected: {
        [LegalType.COMPANY]: 'Company collects card holder data',
        [LegalType.INDIVIDUAL]: 'Company collects card holder data',
    },
    cardPurpose: {
        [LegalType.COMPANY]: 'Purpose of debit cards',
        [LegalType.INDIVIDUAL]: 'Purpose of debit cards',
    },
    currencyVolume: {
        [LegalType.COMPANY]: 'Foreign currency volume per year',
        [LegalType.INDIVIDUAL]: 'Foreign currency volume per year',
    },
    relevantCurrencies: {
        [LegalType.COMPANY]: 'Relevant currencies',
        [LegalType.INDIVIDUAL]: 'Relevant currencies',
    },
    customerRelationIncomingFlow: {
        [LegalType.COMPANY]: 'Customer relation incoming flow',
        [LegalType.INDIVIDUAL]: 'Customer relation incoming flow',
    },
    generateIncomingFlow: {
        [LegalType.COMPANY]: 'Income generating activity',
        [LegalType.INDIVIDUAL]: 'Income generating activity',
    },
    ownerSourceOfFund: {
        [LegalType.COMPANY]: 'Owner source of funds',
        [LegalType.INDIVIDUAL]: 'Owner source of funds',
    },
    investedAmount: {
        [LegalType.COMPANY]: 'Invested amount',
        [LegalType.INDIVIDUAL]: 'Invested amount',
    },
    cardVolume: {
        [LegalType.COMPANY]: 'Estimated monthly turnover',
        [LegalType.INDIVIDUAL]: 'Estimated monthly turnover',
    },
    country: {
        [LegalType.COMPANY]: 'Country',
        [LegalType.INDIVIDUAL]: 'Country',
    },
};

const getCountry = (id: string | null, countries: ICountry[]) => {
    const current = countries.find(country => country['@id'] === id);
    return current?.name;
};

export type OnboardingOverviewCardDataDetails = Partial<
    Record<
        FixedKeys | CustomKeys,
        Partial<Record<FixedKeys | CustomKeys, string | null>>
    >
>;

export interface IOnboardingOverviewCardData {
    title: string;
    subtitles?: string[];
    summary: string[];
    details: OnboardingOverviewCardDataDetails;
}

interface IProps {
    businessPartnerInfo: BusinessPartner;
    countries: ICountry[];
    crpList: ICrpBpListItem[];
    businessIndustries: IBusinessIndustry[];
    isIndividual: boolean;
    legalType: LegalType | null;
}

export const useOnboardingOverview = ({
    businessIndustries,
    businessPartnerInfo,
    countries,
    crpList,
    isIndividual,
    legalType,
}: IProps) => {
    const {data: legalForm} = useLegalForm(businessPartnerInfo?.legalForm);
    const {data: parameters} = useParameters();
    const {data: businessActivities} = useGetBusinessActivities();
    const {data: paymentTypes} = useGetBusinessPartnerPaymentTypes();
    const {data: cardPurposes} = useGetBusinessPartnerCardPurposes();
    const [crpStatus, setCrpStatus] = useState<{
        admin: boolean;
        signer: boolean;
        owner: boolean;
    }>({admin: false, signer: false, owner: false});
    const [company, setCompany] = useState<IOnboardingOverviewCardData>();
    const [crps, setCrps] = useState<IOnboardingOverviewCardData[]>();

    useEffect(() => {
        if (
            !businessPartnerInfo ||
            !crpList?.length ||
            !parameters ||
            !businessIndustries?.length
        ) {
            return;
        }

        const {companyName, city, address, zip, countryOfIncorporation} =
            businessPartnerInfo;

        const country = getCountry(countryOfIncorporation || null, countries);
        const employees = parameters.find(
            parameter => parameter.name === 'numberEmployeesList',
        )?.value ?? [{}];
        const employeesTranslated = convertToTranslatedOptions(employees);

        const companyData: IOnboardingOverviewCardData = {
            title:
                legalType === LegalType.COMPANY
                    ? translate('Company / Business Partner')
                    : translate('Business Partner'),
            summary: [companyName, address, `${zip} ${city}`, country || ''],
            details: {
                [StepTypes.CREATE_BP]: {
                    countryOfIncorporation: translate(country),
                    companyName,
                    legalForm: translate(
                        legalForm?.value
                            ? legalForm.value.replace(/(^|\s)\S/g, letter =>
                                  letter.toUpperCase(),
                              )
                            : '',
                    ),
                    incorporationNumber:
                        businessPartnerInfo.gwgInfo?.incorporationNumber,
                    address,
                    city,
                    zip,
                    dateOfIncorporation: formatDateWithoutTZConversion(
                        businessPartnerInfo.dateOfIncorporation,
                    ),
                },
                [StepTypes.BUSINESS_ACTIVITIES]: {
                    countryOfBusiness:
                        businessPartnerInfo.gwgInfo?.countryOfBusiness
                            ?.map(c => translate(getCountry(c, countries)))
                            .join(', '),
                    businessIndustry: translate(
                        businessIndustries.find(
                            industry =>
                                industry['@id'] ===
                                businessPartnerInfo.gwgInfo?.businessIndustry,
                        )?.value,
                    ),
                    businessActivities: businessActivities
                        ?.filter(a =>
                            businessPartnerInfo.gwgInfo?.businessActivities?.includes(
                                a['@id'],
                            ),
                        )
                        .map(a => translate(a.value))
                        .join(', '),
                    physicalPresence:
                        businessPartnerInfo.gwgInfo?.physicalPresence
                            ?.map(p => translate(p))
                            .join(', '),
                    numberEmployees: employeesTranslated.find(
                        employee =>
                            employee['@id'] ===
                            businessPartnerInfo.gwgInfo?.numberEmployees,
                    )?.label,

                    operationalBusiness: isIndividual
                        ? undefined
                        : translate(
                              businessPartnerInfo.gwgInfo
                                  ?.operationalBusiness === true
                                  ? 'Yes'
                                  : 'No',
                          ),
                },
                [StepTypes.BUSINESS_ATTRIBUTES]: {
                    returnBudget: BUDGET_LIST.find(
                        budget =>
                            budget['@id'] ===
                            businessPartnerInfo.gwgInfo?.returnBudget,
                    )?.label,
                    sourceOfFunds:
                        (legalType
                            ? translate(
                                  SOURCE_OF_FUNDS[legalType]?.find(
                                      s =>
                                          s['@id'] ===
                                          businessPartnerInfo.gwgInfo
                                              ?.sourceOfFunds,
                                  )?.label,
                              )
                            : null) ??
                        businessPartnerInfo.gwgInfo?.sourceOfFunds,
                    website: businessPartnerInfo.gwgInfo?.website,
                },
                [StepTypes.REASON_FOR_AMNIS]: {
                    reasonForAmnis: businessPartnerInfo.gwgInfo?.reasonForAmnis
                        ?.map(r => translate(REASON_FOR_AMNIS_LABEL_MAPPING[r]))
                        .join(', '),
                },
                [StepTypes.GENERAL_PAYMENTS]:
                    businessPartnerInfo.gwgInfo?.reasonForAmnis?.includes(
                        'incoming_payment',
                    ) ||
                    businessPartnerInfo.gwgInfo?.reasonForAmnis?.includes(
                        'outgoing_payment',
                    )
                        ? {
                              paymentTypes: paymentTypes
                                  ?.filter(pt =>
                                      businessPartnerInfo.gwgInfo?.paymentTypes?.includes(
                                          pt['@id'],
                                      ),
                                  )
                                  .map(pt => translate(pt.value))
                                  .join(', '),
                              paymentSize: translate(
                                  PAYMENT_SIZES.find(
                                      ps =>
                                          ps['@id'] ===
                                          businessPartnerInfo.gwgInfo
                                              ?.paymentSize,
                                  )?.label,
                              ),
                              paymentVolume: legalType
                                  ? translate(
                                        PAYMENT_VOLUME[legalType]?.find(
                                            pv =>
                                                pv['@id'] ===
                                                businessPartnerInfo.gwgInfo
                                                    ?.paymentVolume,
                                        )?.label,
                                    )
                                  : undefined,
                          }
                        : {},
                [StepTypes.DEBIT_CARD]:
                    businessPartnerInfo.gwgInfo?.reasonForAmnis?.includes(
                        'debit_card',
                    )
                        ? {
                              informationCollected:
                                  legalType === LegalType.COMPANY
                                      ? translate(
                                            businessPartnerInfo.gwgInfo
                                                ?.informationCollected
                                                ? 'Yes'
                                                : 'No',
                                        )
                                      : undefined,
                              cardPurpose: cardPurposes
                                  ?.filter(cp =>
                                      businessPartnerInfo.gwgInfo?.cardPurpose?.includes(
                                          cp['@id'],
                                      ),
                                  )
                                  .map(cp => translate(cp.value))
                                  .join(', '),
                              cardVolume: CARD_VOLUME.find(
                                  cv =>
                                      cv['@id'] ===
                                      businessPartnerInfo.gwgInfo?.cardVolume,
                              )?.label,
                          }
                        : {},
                [StepTypes.FX]:
                    businessPartnerInfo.gwgInfo?.reasonForAmnis?.includes('fx')
                        ? {
                              currencyVolume: `${businessPartnerInfo.gwgInfo?.currencyVolumeLower} - ${businessPartnerInfo.gwgInfo?.currencyVolumeUpper}`,
                              relevantCurrencies:
                                  businessPartnerInfo.gwgInfo?.relevantCurrencies
                                      ?.map(c => c.split('/').pop())
                                      .join(', '),
                          }
                        : {},
                [StepTypes.SOURCE_OF_WEALTH]: {
                    customerRelationIncomingFlow:
                        CUSTOMER_RELATION_INCOMING_FLOW_OPTIONS.filter(o =>
                            businessPartnerInfo.gwgInfo?.customerRelationIncomingFlow?.includes(
                                o['@id'],
                            ),
                        )
                            .map(o => translate(o.label))
                            .join(','),
                    generateIncomingFlow: GENERATE_INCOMING_FLOW_OPTIONS.filter(
                        o =>
                            businessPartnerInfo.gwgInfo?.generateIncomingFlow?.includes(
                                o['@id'],
                            ),
                    )
                        .map(o => translate(o.label))
                        .join(','),
                    ownerSourceOfFund: translate(
                        OWNER_SOURCE_OF_FUNDS.find(
                            o =>
                                businessPartnerInfo.gwgInfo
                                    ?.ownerSourceOfFund === o['@id'],
                        )?.label,
                    ),
                    investedAmount: translate(
                        INVESTMENT_AMOUNTS.find(
                            o =>
                                businessPartnerInfo.gwgInfo?.investedAmount ===
                                o['@id'],
                        )?.label,
                    ),
                },
            },
        };

        const crpsData = crpList
            .filter(crp => crp.admin || crp.isOwner || crp.hasSigningRights)
            .reduce<IOnboardingOverviewCardData[]>((acc, curr) => {
                const subtitles: string[] = [];

                if (curr.admin) {
                    subtitles.push(
                        translate(CRP_ROLE_MAPPING[CrpVariants.ADMIN]),
                    );
                    setCrpStatus(prev => ({...prev, admin: true}));
                }
                if (curr.isOwner) {
                    subtitles.push(
                        translate(CRP_ROLE_MAPPING[CrpVariants.OWNER]),
                    );
                    setCrpStatus(prev => ({...prev, owner: true}));
                }
                if (curr.hasSigningRights) {
                    subtitles.push(
                        translate(CRP_ROLE_MAPPING[CrpVariants.SIGNER]),
                    );
                    setCrpStatus(prev => ({...prev, signer: true}));
                }
                if (curr.contractSigner) {
                    subtitles.push(
                        translate(
                            CRP_ROLE_MAPPING[CrpVariants.CONTRACT_SIGNER],
                        ),
                    );
                }

                const crp: IOnboardingOverviewCardData = {
                    title: `${curr.crp.firstName} ${curr.crp.lastName}`,
                    subtitles,
                    summary: [
                        curr.crp.email,
                        curr.crp.dateOfBirth &&
                            formatDateInZurichTZ(curr.crp.dateOfBirth),
                    ],
                    details: {
                        personalInformation: {
                            firstName: curr.crp.firstName,
                            lastName: curr.crp.lastName,
                            gender: curr.crp.gender
                                ? translate(curr.crp.gender)
                                : null,
                            country: !curr.crp.country
                                ? undefined
                                : translate(
                                      getCountry(curr.crp.country, countries),
                                  ),
                            dateOfBirth: !curr.crp.dateOfBirth
                                ? undefined
                                : formatDateInZurichTZ(curr.crp.dateOfBirth),
                            address: curr.crp.street,
                            zip: curr.crp.zip,
                            city: curr.crp.city,
                        },
                    },
                };

                if (curr.isOwner) {
                    if (businessPartnerInfo.gwgInfo?.formOfControl === 1) {
                        crp.details.ownerInfo = {
                            directOwner: translate(
                                curr.directOwner ? 'Yes' : 'No',
                            ),
                            ownerPercentage:
                                OWNER_PERCENTAGE_OPTIONS.find(
                                    o => o.value === curr.ownerPercentage,
                                )?.label || '',
                        };
                    } else {
                        crp.details.ownerInfo = {
                            role: curr.role,
                        };
                    }
                }
                if (curr.hasSigningRights) {
                    crp.details.signersInfo = {
                        collectiveSigningRight: translate(
                            curr.collectiveSigningRight
                                ? 'Collective'
                                : 'Individual',
                        ),
                        role: curr.role,
                        email: curr.crp.email,
                        phoneNumber: curr.crp.phoneNumber,
                    };
                }
                acc.push(crp);
                return acc;
            }, []);

        setCompany(companyData);
        setCrps(crpsData);
    }, [
        businessActivities,
        businessIndustries,
        businessPartnerInfo,
        cardPurposes,
        countries,
        crpList,
        isIndividual,
        legalForm,
        legalType,
        parameters,
        paymentTypes,
    ]);

    const extractValues = (
        data: Partial<
            Record<
                FixedKeys | 'personalInformation' | 'ownerInfo' | 'signersInfo',
                string
            >
        >,
    ) => {
        return Object.keys(data).reduce((acc, curr) => {
            //@ts-ignore
            const val = data[curr];
            if (val) {
                //@ts-ignore
                acc[
                    //@ts-ignore
                    DETAILS_MAPPING[curr]?.[legalType] ||
                        `MISSING TEXT FOR KEY: ${curr}`
                ] = val;
            }
            return acc;
        }, {});
    };

    return {
        cardData: {
            company,
            crps,
            missingCrp:
                !crpStatus.admin || !crpStatus.owner || !crpStatus.signer,
        },
        extractValues,
    };
};
