import React, {useEffect, useState, useCallback, useRef} from 'react';
import {useFormContext} from 'react-hook-form';

import CheckBoxGroup from 'components/ui/Form/OnboardingCheckBoxGroup/OnboardingCheckBoxGroup';
import {IDefaultControlProps} from 'components/ui/Form/Form/Form';
import {useBusinessPartner} from 'context/business-partner-context';
import {translate} from 'helpers/localize';

const formOfControlItems = (companyName: string) => [
    {
        label: translate(
            'Is there a person who directly or indirectly owns equal to or more than 25% of the shares (capital shares or voting rights) of %{companyName}?',
            {companyName},
        ),
        yesValue: 1,
    },
    {
        label: translate(
            'If there are no capital shares or voting rights of 25% or more, the following person(s) listed below is / are controlling the contracting partner in other ways.',
        ),
        yesValue: 2,
    },
    {
        label: translate(
            'In case no person(s) exist(s) who exercise(s) control over the contracting partner in a different capacity, the contracting partner hereby declares that the person(s) listed below is / are the managing director(s).',
        ),
        yesValue: 3,
    },
];

export interface IControllingOptionsFormProps extends IDefaultControlProps {
    defaultValue?: number;
}

const ControllingOptionsForm = (props: IControllingOptionsFormProps) => {
    const {errors, control, clearErrors, setValue, watch} = useFormContext();
    const {activeBusinessPartner} = useBusinessPartner();
    const [showOption, setShowOption] = useState<number>(1);
    const {defaultValue, name, variant, rounded} = props;
    const firsRenderRef = useRef(false);

    const formOfControl = watch('gwgInfo.formOfControl');

    control.register(name);

    const handleChange = useCallback(
        (value: string, type: number) => {
            if (value === 'no') {
                if (type === 1) {
                    if (!showOption || showOption < 2) {
                        setShowOption(2);
                    }
                    setValue(name, 2);
                }
                if (type === 2 && showOption < 3) {
                    setShowOption(3);
                    setValue(name, 3);
                }
            }

            if (value === 'yes') {
                if (errors[name]) clearErrors(name);
                setShowOption(type);
                setValue(name, type);
            }
        },
        [clearErrors, errors, name, setValue, showOption],
    );

    useEffect(() => {
        for (let i = 1; i <= formOfControl; i++) {
            setValue(`type-${i}`, Number(formOfControl) === i ? 'yes' : 'no');
        }
    }, [formOfControl, setValue]);

    useEffect(() => {
        if (!firsRenderRef.current) {
            setShowOption(defaultValue ?? 1);
            handleChange('yes', defaultValue ?? 1);
        }
        firsRenderRef.current = true;
    }, [defaultValue, handleChange, name, setValue]);

    const getOptions = (yesValue: number) => {
        if (yesValue === 3) {
            return [
                {
                    label: 'Yes',
                    value: 'yes',
                },
            ];
        }
        return [
            {
                label: 'Yes',
                value: 'yes',
            },
            {label: 'No', value: 'no'},
        ];
    };

    return (
        <>
            {formOfControlItems(activeBusinessPartner.companyName).map(item => {
                return showOption >= item.yesValue ||
                    (!showOption && item.yesValue === 1) ? (
                    <CheckBoxGroup
                        key={item.yesValue}
                        defaultValue={control.getValues(
                            `type-${item.yesValue}`,
                        )}
                        options={getOptions(item.yesValue)}
                        name={`type-${item.yesValue}`}
                        label={item.label}
                        type="radio"
                        onChange={e =>
                            handleChange(e.target.value, item.yesValue)
                        }
                        variant={variant}
                        rounded={rounded}
                        translateLabel={false}
                        size="large"
                    />
                ) : null;
            })}
        </>
    );
};

export default ControllingOptionsForm;
