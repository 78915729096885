import {ICountry} from 'types/api';
import {PaymentSettingType} from 'types/common';

export const parseJwt = (token: string | null) => {
    if (!token || typeof token !== 'string') return null;
    const base64Url = token.split('.')[1];
    if (!base64Url) return null;

    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join(''),
    );
    return JSON.parse(jsonPayload);
};

export const concatenateArray = (input: any) => {
    if (Array.isArray(input)) {
        return input;
    }

    return input !== undefined ? input.split(',') : [];
};

// This function return only countries which founded in Settings
// The Settings we get from /web_api/payment_field_settings?currency={currency}&itemsPerPage=1000
export const getCountriesRelatedToSettings = (
    settings: PaymentSettingType[],
    countries: ICountry[],
) =>
    countries.filter((country: ICountry) => {
        for (let i = 0; i < settings.length; i++) {
            const setting: PaymentSettingType = settings[i];
            if (country['@id'] === setting.country) {
                return country;
            }
        }

        return false;
    });

export const getRoutingesForCountryAndCurrency = (
    routings: PaymentSettingType[],
    country: string,
    currency: string,
) =>
    routings
        .filter(
            routing =>
                routing.country === country && routing.currency === currency,
        )
        .map(routing => routing['@id']);

export const extractValueToKey = (input: [], prop: string): any => {
    const newObj = {};

    input.forEach(element => {
        newObj[element[prop]] = element;
    });

    return newObj;
};

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export type GetQueryWithParamsOptions = {
    removeNulls?: boolean;
    removeFalse?: boolean;
};
// This function get object and return object
// with properties only if property has value
export const getQueryWithParams = <T extends Record<string, any>>(
    formObject: T,
    options: GetQueryWithParamsOptions = {},
): Required<T> => {
    const query: Record<any, any> = {};

    Object.keys(formObject).forEach(key => {
        const filterValue = formObject[key];
        const isObject =
            typeof filterValue === 'object' && filterValue !== null;

        if (isObject) {
            Object.keys(filterValue).forEach(k => {
                if (filterValue[k]) {
                    //@ts-ignore
                    if (!query[key as keyof typeof query]) query[key] = {};
                    query[key][k] = filterValue[k];
                }
            });
        } else if (
            !isObject &&
            formObject[key] !== '' &&
            formObject[key] !== 'undefined' &&
            formObject[key] !== undefined &&
            (options.removeNulls ? formObject[key] !== null : true) &&
            (options.removeFalse ? formObject[key] !== false : true)
        ) {
            //@ts-ignore
            if (!query[key]) query[key] = {};
            //@ts-ignore
            query[key] = formObject[key];
        } else {
            delete query[key];
        }
    });

    return query as Required<T>;
};

// This function return array of filters which have selected value
export const getChipsList = (filters: any) => {
    const filtersKeys = Object.keys(filters);
    const chips = [];

    for (let i = 0; i < filtersKeys.length; i += 1) {
        const filterKey = filtersKeys[i];
        const filterValue = filters[filterKey];

        if (typeof filterValue === 'boolean' && filterValue) {
            chips.push({
                filterKey,
                filterValue,
            });
        }

        if (typeof filterValue === 'string' && filterValue) {
            chips.push({
                filterKey,
                filterValue,
            });
        }

        if (typeof filterValue === 'object' && filterValue !== null) {
            const keys = Object.keys(filterValue);

            for (let x = 0; x < keys.length; x += 1) {
                const value = filterValue[keys[x]];

                if (value) {
                    chips.push({
                        filterKey,
                        filterValue,
                    });
                    break;
                }
            }
        }
    }

    return chips;
};

export const formatNumber = (value: any, decimalScale = 2) => {
    const tmpValue = value ?? 0;
    const formatConfig = {
        minimumFractionDigits: decimalScale,
        maximumFractionDigits: decimalScale,
    };

    return new Intl.NumberFormat('de-CH', formatConfig).format(tmpValue);
};

export const formatNumberWithAbbreviation = (
    value: string | number,
    formatOver = 10_000_000,
) => {
    if (+value < formatOver) return formatNumber(value);

    return new Intl.NumberFormat('en-GB', {
        // TODO: remove after TS upgrade
        // @ts-ignore
        notation: 'compact',
        compactDisplay: 'short',
    }).format(+value);
};

export const removeThousandSeparator = (value: any) => {
    if (!value || !(typeof value === 'string' || value instanceof String)) {
        return value;
    }

    return Number(value.replace(/’/g, '')).toString();
};

export const getId = (length = 10) => {
    let result = '';
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength),
        );
    }

    return result;
};

export const range = (start: number, end: number) => {
    return Array(end - start + 1)
        .fill('')
        .map((_, idx) => start + idx);
};

export const formatIban = (iban: string): string => {
    return iban
        .slice()
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();
};

export const obfuscatePhone = (phone: string): string => {
    const first = phone.substring(0, 3);
    const third = phone.substring(phone.length - 3, phone.length);

    let second = '';
    for (let i = 0; i <= phone.length - 8; i++) {
        second += 'X';
    }

    return first + second + third;
};

export const getCurrencyFromIri = (
    currencyIri: string | null | undefined,
): string => {
    if (typeof currencyIri !== 'string') return '';
    return currencyIri.split('/').pop()!;
};

export const getIdFromIri = (path: string | null) => {
    const numberValue = Number(path?.split('/').pop());

    return isNaN(numberValue) ? undefined : numberValue;
};

export const isStringNumeric = (str: string) => {
    if (typeof str !== 'string') return false;

    return !Number.isNaN(+str) && !Number.isNaN(+parseFloat(str));
};

export const isIframe = () => {
    return window !== window.parent;
};

/** CIF (Constraint Identity Function) helper */
export const createMaps = <ObjectMapType extends Record<string, unknown>>(
    obj: ObjectMapType,
) => obj;

/**
 * Helper function which transforms an object to T, extracting common properties and
 * discarding everything "extra"
 *
 * @param object which implements T, and the properties are set to TRUE, so the object literal is guaranteed
 * by the compiler to have no more and no less properties then T
 * @returns T
 */
export const extract = <T>(properties: Record<keyof T, true>) => {
    return <TActual extends T>(value: TActual) => {
        const result = {} as T;
        (Object.keys(properties) as Array<keyof T>).forEach(property => {
            result[property] = value[property];
        });
        return result;
    };
};

// TODO - better typing (eg. return type)
/**
 * Function to look up with a string path the value of an object key N levels down.
 * @param obj any object
 * @param path string path with . separator, eg: 'gwgInfo.countryOfBusiness'
 * @returns the value if found the path in {@link obj}, undefined otherwise
 */
export const getDeepValue = (obj: Record<string, any>, path: string) => {
    return path
        .split('.')
        .reduce(
            (acc, val) =>
                typeof acc === 'object' &&
                acc !== null &&
                Object.hasOwnProperty.call(acc, val)
                    ? acc[val]
                    : undefined,
            obj,
        );
};

export const splitFractions = (amount: number) => {
    return formatNumber(amount, 2).split('.');
};

export const hasObjectAnyTruthyValue = <T = {}>(obj: T) => {
    return (
        typeof obj === 'object' &&
        //@ts-ignore
        Object.keys(obj).reduce<boolean>((result, key) => {
            if (
                //@ts-ignore
                obj[key] === null ||
                //@ts-ignore
                obj[key] === '' ||
                //@ts-ignore
                obj[key] === undefined ||
                //@ts-ignore
                obj[key] === false
            ) {
                return result;
            }

            //@ts-ignore
            if (typeof obj[key] === 'object') {
                if (
                    //@ts-ignore
                    hasObjectAnyTruthyValue(obj[key] as Record<string, unknown>)
                )
                    return true;
            } else {
                return true;
            }
            return result;
        }, false)
    );
};

// create a function which takes 2 arguments, and replaces the part in the first argument after : with the second argument
export const createUrlWithParam = (baseUrl: string, param: string | number) =>
    baseUrl.replace(/:.*/, param.toString());

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export function findLastIndex<T>(
    array: Array<T>,
    predicate: (value: T, index: number, obj: T[]) => boolean,
): number {
    let l = array.length;
    // eslint-disable-next-line no-plusplus
    while (l--) {
        if (predicate(array[l], l, array)) return l;
    }
    return -1;
}

export const checkRecursivlyIfObjectsAreEqual = (
    obj1: Record<string, any>,
    obj2: Record<string, any>,
): boolean => {
    if (!obj1) return false;
    const keys = Object.keys(obj1);
    if (!keys.length) return false;
    let objectAreEqual = true;
    keys.forEach(key => {
        const _formValue = obj1[key];
        const _filterValue = obj2[key];
        if (
            typeof _formValue === 'object' &&
            _formValue !== null &&
            _filterValue === 'object' &&
            _filterValue !== null
        ) {
            checkRecursivlyIfObjectsAreEqual(_formValue, _filterValue);
        } else if (
            JSON.stringify(_formValue) !== JSON.stringify(_filterValue)
        ) {
            objectAreEqual = false;
        }
    });
    return objectAreEqual;
};
