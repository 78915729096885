const Document = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M7.25 16.75h6.5v-1.5h-6.5v1.5Zm0-4h9.5v-1.5h-9.5v1.5Zm0-4h9.5v-1.5h-9.5v1.5ZM5.308 20.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.282V5.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h13.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v13.385c0 .505-.175.932-.525 1.282-.35.35-.778.525-1.283.525H5.308Zm0-1.5h13.384a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.211V5.308a.294.294 0 0 0-.096-.211.294.294 0 0 0-.212-.097H5.308a.294.294 0 0 0-.212.097.294.294 0 0 0-.096.211v13.385c0 .077.032.147.096.211a.294.294 0 0 0 .212.096Z"
        />
    </svg>
);

export default Document;
