import * as React from 'react';

const SvgRegisterCollect = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201 201" {...props}>
        <defs />
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path
                    d="M92.58 61.07a43.57 43.57 0 1 0 47.35 47.35"
                    fill="none"
                    stroke="#b5e3d8"
                    strokeMiterlimit="10"
                    strokeWidth="7.92px"
                    fillRule="evenodd"
                />
                <path
                    d="M148 96.54A43.57 43.57 0 0 0 104.46 53v43.54Z"
                    fill="none"
                    stroke="#b5e3d8"
                    strokeMiterlimit="10"
                    strokeWidth="7.92px"
                    fillRule="evenodd"
                />
                <circle
                    cx="100.5"
                    cy="100.5"
                    r="100"
                    fill="none"
                    stroke="#b5e3d8"
                    strokeMiterlimit="10"
                />
            </g>
        </g>
    </svg>
);

export default SvgRegisterCollect;
