import {useState} from 'react';

type Common = {
    name: string;
    status: 'uploaded' | 'error' | 'loading';
    errorMessage?: string;
    category?: string;
};

type NewFileUploadStatus = Common & {
    document: File;
};

type UploadedFileUploadStatus = Common & {
    id: string;
};

export type IUploadStatus = NewFileUploadStatus | UploadedFileUploadStatus;

export const isNewFileUploadStatus = (
    status: IUploadStatus,
): status is NewFileUploadStatus => {
    return (status as NewFileUploadStatus).document !== undefined;
};

export const isUploadedFileUploadStatus = (
    status: IUploadStatus,
): status is UploadedFileUploadStatus => {
    return (status as UploadedFileUploadStatus).id !== undefined;
};

export const isSameDocument = (document: File, compareWith: File): boolean => {
    return (
        document.lastModified === compareWith.lastModified &&
        document.name === compareWith.name &&
        document.size === compareWith.size &&
        document.type === compareWith.type
    );
};

const useFileUploadStatuses = () => {
    const [uploadStatuses, setUploadStatuses] = useState<IUploadStatus[]>([]);

    const updateStatus = (status: IUploadStatus) => {
        setUploadStatuses(prev => [
            ...prev.filter(s => {
                if (isNewFileUploadStatus(status)) {
                    return (
                        (s as NewFileUploadStatus).document !== status.document
                    );
                }
                if (isUploadedFileUploadStatus(status)) {
                    return (s as UploadedFileUploadStatus).id !== status.id;
                }
                return false;
            }),
            status,
        ]);
    };

    const removeStatus = (idOrDocument: string | File) => {
        if (typeof idOrDocument === 'string') {
            setUploadStatuses(prev => [
                ...prev.filter(
                    s => isNewFileUploadStatus(s) || s.id !== idOrDocument,
                ),
            ]);
        } else {
            setUploadStatuses(prev => [
                ...prev.filter(s => {
                    return (
                        isUploadedFileUploadStatus(s) ||
                        !isSameDocument(s.document, idOrDocument)
                    );
                }),
            ]);
        }
    };

    return {uploadStatuses, updateStatus, removeStatus};
};

export default useFileUploadStatuses;
