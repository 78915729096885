import {NotificationList} from 'context/notification-context';

import {createPortal} from 'react-dom';
import Notification from './components/Notification/Notification';

import styles from './Notifications.module.scss';

export interface INotificationContainerProps {
    notifications: NotificationList;
}

const NotificationContainer = ({
    notifications,
}: INotificationContainerProps) => {
    return createPortal(
        <div className={styles.container}>
            {notifications.map(n => (
                <Notification key={n.id} data={n} />
            ))}
        </div>,
        document.body,
    );
};

export default NotificationContainer;
