import * as React from 'react';

const SvgXMark = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 512 512" height="1em" width="1em" {...props}>
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="32"
            d="M368 368L144 144m224 0L144 368"
        />
    </svg>
);

export default SvgXMark;
