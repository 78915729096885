import React, {useEffect, useMemo} from 'react';
import {FormLabel, FormControl} from 'react-bootstrap';
import {Controller, Control} from 'react-hook-form';
import {
    formatDateInZurichTZ,
    getDefaultDateForFX,
    getMaxDateByOffset,
    getMinDateForCurrencies,
    isWeekDay,
} from 'helpers/dates';
import {useGetHolidays} from 'api/holidays';
import {useCurrencies} from 'api/currencies';
import DatePicker from './DatePicker';
import styles from './HolidayDatePicker.module.scss';
import DateToggle from '../DateToggle/DateToggle';

interface IHolidayDatePickerProps {
    currencies: string[];
    label?: string | JSX.Element;
    name: string;
    placeholder: string;
    error?: any;
    includeHolidays?: boolean;
    control?: Control;
    rules?: object;
    setClosestToDefault?: boolean;
    simple?: boolean;
    maxDayOffset?: number;
    value?: string;
    placeholderText?: string;
    maxDate?: Date;
    minDate?: Date;
    className?: string;
    disabled?: boolean;
    filterDate?: (date: Date) => boolean;
    allDay?: boolean;
    dateFormat?: string;
    showMonthYearPicker?: boolean;
    isClearable?: boolean;
    considerValueDay?: boolean;
}

export interface IPickerConfig {
    minDate: Date;
    filterDate: ((date: Date) => boolean) | undefined;
    excludeDates: Date[] | undefined;
    maxDate: Date | null;
}

const HolidayDatePicker: React.FC<IHolidayDatePickerProps> = ({
    control,
    currencies,
    error,
    label,
    name,
    placeholderText,
    includeHolidays,
    rules = {},
    setClosestToDefault = false,
    simple = false,
    maxDayOffset,
    filterDate,
    allDay,
    dateFormat,
    showMonthYearPicker = false,
    isClearable,
    considerValueDay,
}) => {
    const {data: holidays} = useGetHolidays(currencies);

    const {data: currencyList} = useCurrencies();

    const memoizedDates = React.useMemo(() => {
        const holidaysDates: Date[] =
            holidays && holidays.length
                ? holidays.map((h: any) => new Date(h))
                : [];
        return {
            minDate: getMinDateForCurrencies(
                currencyList || [],
                currencies,
                holidaysDates,
                allDay,
                considerValueDay,
            ),
            holidaysDates,
        };
    }, [currencyList, currencies, holidays, allDay, considerValueDay]);

    const config: IPickerConfig = {
        minDate: memoizedDates.minDate,
        filterDate: filterDate || (includeHolidays ? undefined : isWeekDay),
        excludeDates: includeHolidays ? undefined : memoizedDates.holidaysDates,
        maxDate: null,
    };

    if (maxDayOffset !== undefined) {
        config.maxDate = getMaxDateByOffset(
            config.minDate,
            config.excludeDates || [],
            maxDayOffset,
        );
    }

    const defaultValue = useMemo(() => {
        return setClosestToDefault && config?.minDate
            ? formatDateInZurichTZ(getDefaultDateForFX(config.minDate))
            : '';
    }, [setClosestToDefault, config]);

    useEffect(() => {
        if (defaultValue) {
            control?.setValue(name, defaultValue);
        }
    }, [defaultValue, control]);

    return (
        <div className={styles.container}>
            {label ? (
                <FormLabel style={{display: 'block'}}>{label}</FormLabel>
            ) : null}
            <Controller
                render={props => {
                    return simple ? (
                        <DateToggle {...config} {...props} />
                    ) : (
                        <DatePicker
                            {...config}
                            {...props}
                            hasError={!!error}
                            placeholderText={placeholderText}
                            dateFormat={dateFormat}
                            showMonthYearPicker={showMonthYearPicker}
                            isClearable={isClearable}
                        />
                    );
                }}
                control={control}
                name={name}
                className="input"
                rules={rules}
                placeholderText={placeholderText}
            />
            {error ? (
                <FormControl.Feedback
                    type="invalid"
                    className={styles.errorLabel}
                >
                    {error.message}
                </FormControl.Feedback>
            ) : null}
        </div>
    );
};

HolidayDatePicker.defaultProps = {
    includeHolidays: false,
};

export default HolidayDatePicker;
