const Card = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M3.694 5.616h16.612v2.066H3.694V5.616Zm0 4.478v7.244h16.612v-7.244H3.694ZM2.377 4.3h19.245v14.401H2.377V4.3Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            d="M8.05 15.159a1.656 1.656 0 0 1-3.04-.917 1.658 1.658 0 0 1 3.04-.917 1.656 1.656 0 0 1 3.038.917 1.658 1.658 0 0 1-3.039.916Z"
        />
    </svg>
);

export default Card;
