import styles from './CrpOwnerInfo.module.scss';

const CrpOwnerInfo = () => {
    return (
        <div className={styles.container}>
            <h3>Controlling person</h3>
            <h5>
                The controlling person(s) of a company are the UBOs (Ultimate
                Beneficial Owner). If there is no UBO please list the management
                of the company.
            </h5>
            <h3>Explanations about the type of persons to be listed</h3>
            <h5>
                Depending on the company, there can be various controlling
                persons:
            </h5>
            <ul>
                <li data-isolate>
                    <span className={styles.strong}>
                        Ultimate Beneficial Owner:
                    </span>
                    &nbsp;UBO stands for &quot;Ultimate Beneficial Owner&quot;
                    in the context of a company. It refers to the individual(s)
                    who ultimately owns or controls a company, either directly
                    or indirectly, and has the ability to make decisions about
                    the company&apos;s operations, policies, and financial
                    transactions. By identifying the true owners or controllers
                    of a company, we can help prevent illicit activities such as
                    money laundering, fraud, and corruption.
                </li>
                <li data-isolate>
                    <span className={styles.strong}>Board of Directors:</span>
                    &nbsp;Members of the Board of Directors are individuals
                    appointed or elected by a company&apos;s shareholders or
                    stakeholders to oversee the management and direction of the
                    company. The board of directors is responsible for making
                    strategic decisions, setting policies, and providing
                    guidance and supervision to the management team of the
                    company.
                </li>
                <li data-isolate>
                    <span className={styles.strong}>Executive Board:</span>
                    &nbsp;The executive board of a company consists of senior
                    managers, such as the CEO, CFO, and other executives who are
                    responsible for overseeing the day-to-day operations and
                    implementing strategic decisions made by the board of
                    directors.
                </li>
                <li data-isolate>
                    <span className={styles.strong}>Shareholder:</span>
                    &nbsp;Shareholders are individuals who own shares in a
                    company. Shareholders are often investors who contribute
                    capital to the company and, in return, hold ownership stakes
                    representing their ownership interests.
                </li>
            </ul>
        </div>
    );
};

export default CrpOwnerInfo;
