const SvgEditPencil = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="m13.382 7.367 3.25 3.25-7.058 7.06-2.898.32a.61.61 0 0 1-.672-.674l.322-2.9 7.056-7.056Zm5.261-.484-1.526-1.526a1.22 1.22 0 0 0-1.725 0l-1.435 1.436 3.25 3.25 1.436-1.435a1.22 1.22 0 0 0 0-1.725Z"
        />
    </svg>
);

export default SvgEditPencil;
