import React, {useEffect} from 'react';
import classNames from 'classnames';

import {useCountdown} from 'hooks/common';

import styles from './CountdownTimer.module.scss';

const WARNING_THRESHOLD = 30;
const ALERT_THRESHOLD = 10;
const TIME_LIMIT = 60;

export interface ICountdownTimerProps {
    timeLimit?: number;
    beforeTimerText?: string;
    afterTimerText?: string | null;
    warningThreshold?: number;
    alertThreshold?: number;
    timerFinished?: () => void;
}

const CountdownTimer = ({
    timeLimit,
    beforeTimerText,
    afterTimerText,
    warningThreshold,
    alertThreshold,
    timerFinished,
}: ICountdownTimerProps) => {
    const {startCounter, timeLeft, stopCounter} = useCountdown(
        timeLimit || TIME_LIMIT,
        false,
    );
    const wthreshold = warningThreshold || WARNING_THRESHOLD;
    const aThreashold = alertThreshold || ALERT_THRESHOLD;

    const calculateTimeFraction = () => {
        const rawTimeFraction = timeLeft / (timeLimit || TIME_LIMIT);
        return Math.max(
            (rawTimeFraction -
                (1 / (timeLimit || TIME_LIMIT)) * (1 - rawTimeFraction)) *
                98,
            2,
        );
    };

    useEffect(() => {
        startCounter?.();

        return () => stopCounter?.();
    }, [startCounter, stopCounter]);

    useEffect(() => {
        if (timeLeft === 0 && timerFinished) {
            timerFinished();
        }
    }, [timeLeft, timerFinished]);

    return (
        <div className={styles.baseTimer}>
            <svg
                className={styles.svg}
                viewBox="0 0 100 5"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g className={styles.line}>
                    <path className={styles.elapsed} d="M 2 2.5 L 98 2.5" />
                    <path
                        id="base-timer-path-remaining"
                        className={classNames(styles.remaining, {
                            [styles.base]: timeLeft > wthreshold,
                            [styles.warning]:
                                timeLeft <= wthreshold &&
                                timeLeft > aThreashold,
                            [styles.alert]: timeLeft <= aThreashold,
                        })}
                        d={`M 2 2.5 L ${calculateTimeFraction().toFixed(
                            0,
                        )} 2.5`}
                    />
                </g>
            </svg>
            <span id="base-timer-label" className={styles.label} data-isolate>
                {beforeTimerText || 'You have '}
                <span className={styles.seconds}>
                    &nbsp;
                    <var data-var="amount">{timeLeft}</var>
                    &nbsp;seconds
                </span>
                {afterTimerText || afterTimerText === null
                    ? afterTimerText
                    : ' to review this request.'}
            </span>
        </div>
    );
};

export default CountdownTimer;
