import {useQuery} from '@tanstack/react-query';

import {useClient} from 'context/auth-context';
import {translate} from 'helpers/localize';
import {ICountry} from 'types/api';

export const isCountry = (country: any): country is ICountry => {
    return country?.['@type'] === 'Country';
};

function useCountries() {
    const client = useClient();

    const result = useQuery<ICountry[]>({
        queryKey: ['countries'],
        queryFn: () => {
            return client(
                '/web_api/countries?pagination=false&order[position]=ASC',
                {},
            ).then((countries: ICountry[]) =>
                countries.map(item => ({
                    ...item,
                    label: translate(item.name),
                })),
            );
        },
        staleTime: Infinity,
    });

    return {...result, countries: result.data ?? []};
}

export {useCountries};
