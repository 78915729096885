import React, {useRef, useState} from 'react';
import FA from 'react-fontawesome';

import useModal from 'components/ui/ModalContainer/useModal';
import Alert from 'components/ui/Alert/Alert';
import Button, {IButtonProps} from 'components/ui/Button';

import styles from './ConfirmationOverlay.module.scss';

export type ConfirmationOverlaySubmit = (
    event: React.MouseEvent<HTMLButtonElement>,
    setError?: (error: string) => void,
) => void;

export interface IConfirmationOverlay {
    onConfirm: ConfirmationOverlaySubmit;
    onCancel?: () => void;
    title: React.ReactNode;
    description: React.ReactNode;
    isLoading?: boolean;
    confirmLabel?: string;
    cancelLabel?: string;
    cancelVariant?: IButtonProps['variant'];
    confirmColor?: IButtonProps['color'];
}

const ConfirmationOverlay = ({
    onConfirm,
    onCancel,
    title,
    description,
    isLoading,
    confirmLabel = 'Ok',
    cancelLabel = 'Cancel',
    cancelVariant = 'outline',
    confirmColor = 'primary',
}: IConfirmationOverlay) => {
    const {closeModal} = useModal();
    const [error, setError] = useState<string>('');
    const containerRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick: React.MouseEventHandler = event => {
        if (event.target === containerRef?.current) {
            closeModal('confirmation');
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        <div
            onClick={handleOutsideClick}
            ref={containerRef}
            className={styles.overlayContainer}
        >
            <div className={styles.modal}>
                <button
                    className={styles.closeButton}
                    onClick={() => closeModal('confirmation')}
                >
                    <FA size="lg" name="times" />
                </button>
                <h2>{title}</h2>
                <div>{description}</div>
                {error ? (
                    <div>
                        <Alert message={error} className={styles.errorAlert} />
                    </div>
                ) : null}
                <div className={styles.buttonRow}>
                    {onCancel ? (
                        <Button
                            isLoading={isLoading}
                            variant={cancelVariant}
                            onClick={onCancel}
                        >
                            {cancelLabel}
                        </Button>
                    ) : null}
                    <Button
                        isLoading={isLoading}
                        variant="primary"
                        onClick={event => onConfirm(event, setError)}
                        disabled={!!error}
                        color={confirmColor}
                    >
                        {confirmLabel}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationOverlay;
