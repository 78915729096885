const SvgTrash = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        {...props}
    >
        <path
            fill="#E03E52"
            d="M13.328 16.337h.656a.332.332 0 0 0 .329-.327v-5.887c0-.163-.165-.326-.329-.326h-.656a.332.332 0 0 0-.328.326v5.887c0 .19.137.327.328.327Zm4.485-9.157h-2.27l-.93-1.526A1.323 1.323 0 0 0 13.493 5H10.73c-.464 0-.902.273-1.12.654L8.68 7.18H6.438A.449.449 0 0 0 6 7.616v.436c0 .246.191.436.438.436h.437v9.157c0 .736.574 1.308 1.313 1.308h7.874a1.31 1.31 0 0 0 1.313-1.308V8.488h.438c.218 0 .437-.19.437-.436v-.436c0-.218-.219-.436-.438-.436Zm-7.137-.79c.027-.027.082-.082.136-.082h2.598c.055 0 .11.055.137.082l.465.79H10.21l.465-.79Zm5.386 11.255H8.188V8.488h7.876v9.157Zm-5.796-1.308h.656a.332.332 0 0 0 .328-.327v-5.887c0-.163-.164-.326-.328-.326h-.656a.332.332 0 0 0-.329.326v5.887c0 .19.137.327.329.327Z"
        />
    </svg>
);

export default SvgTrash;
