import {BrowserRouter as Router} from 'react-router-dom';
import {CookiesProvider} from 'react-cookie';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {AuthProvider} from './auth-context';
import {BusinessPartnerProvider} from './business-partner-context';
import {LanguageProvider} from './language-context';
import {NotificationProvider} from './notification-context';
import {EventSourceProvider} from './event-source-context';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry(failureCount, error) {
                if ((error as any)?.status > 400) {
                    return false;
                }
                return failureCount < 2;
            },
        },
    },
});

const AppProviders = ({children}: {children: React.ReactNode}) => {
    return (
        <CookiesProvider>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <LanguageProvider>
                        <AuthProvider>
                            <BusinessPartnerProvider>
                                <EventSourceProvider>
                                    <NotificationProvider>
                                        {children}
                                    </NotificationProvider>
                                </EventSourceProvider>
                            </BusinessPartnerProvider>
                        </AuthProvider>
                    </LanguageProvider>
                </Router>
            </QueryClientProvider>
        </CookiesProvider>
    );
};

export default AppProviders;
