import React, {useEffect} from 'react';
import {useModalStore} from 'components/ui/ModalContainer/modalStore';
import classNames from 'classnames';
import styles from './ModalContainer.module.scss';

const ModalContainer = () => {
    const open = useModalStore(state => state.open);
    const setOpen = useModalStore(state => state.setOpen);
    const modalContent = useModalStore(state => state.modalContent);

    useEffect(() => {
        if (open) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [open]);

    useEffect(() => {
        if (Object.keys(modalContent).length === 0) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [modalContent, setOpen]);

    return (
        <>
            {Object.entries(modalContent).map(([key, value]) => {
                return (
                    <div
                        className={classNames(styles.container, {
                            [styles.open]: open,
                        })}
                        key={key.toString()}
                    >
                        {value}
                    </div>
                );
            })}
        </>
    );
};
export default ModalContainer;
