import {CalendarDate} from '@internationalized/date';
import {IAmnisFilters} from 'components/ui/AmnisFilters/types';
import {endOfDay, format, parse, startOfDay} from 'date-fns';
import {ITag} from 'types/api';

export const getIdsFromTags = (tags: ITag[] | null) => {
    return tags?.map(tag => tag['@id']) || null;
};

export const formatExecutionDate = (executionDate: {
    before: string;
    after: string;
}) => {
    if (!executionDate) return null;

    return {
        before: executionDate.before
            ? format(
                  endOfDay(
                      parse(executionDate.before, 'dd.MM.yyyy', new Date()),
                  ),
                  'yyyy-MM-dd HH:mm:ss',
              )
            : undefined,
        after: executionDate.after
            ? format(
                  startOfDay(
                      parse(executionDate.after, 'dd.MM.yyyy', new Date()),
                  ),
                  'yyyy-MM-dd HH:mm:ss',
              )
            : undefined,
    };
};

export const formatExecutionCalendarDate = (
    executionDate: IAmnisFilters['executionDate'],
) => {
    if (!executionDate) return null;

    return {
        before: executionDate.before
            ? format(
                  endOfDay(
                      (executionDate.before as CalendarDate).toDate(
                          'Europe/Zurich',
                      ),
                  ),
                  'yyyy-MM-dd HH:mm:ss',
              )
            : undefined,
        after: executionDate.after
            ? format(
                  startOfDay(
                      (executionDate.after as CalendarDate).toDate(
                          'Europe/Zurich',
                      ),
                  ),
                  'yyyy-MM-dd HH:mm:ss',
              )
            : undefined,
    };
};

export const parseCsv = (
    data: string,
    fieldSeparator = ',',
    newLine = '\n',
) => {
    const nSep = '\x1D';
    const qSep = '\x1E';
    const cSep = '\x1F';
    const nSepRe = new RegExp(nSep, 'g');
    const qSepRe = new RegExp(qSep, 'g');
    const cSepRe = new RegExp(cSep, 'g');
    const fieldRe = new RegExp(
        `(?<=(^|[${fieldSeparator}\\n]))"(|[\\s\\S]+?(?<![^"]"))"(?=($|[${fieldSeparator}\\n]))`,
        'g',
    );
    const grid: string[][] = [];
    data.replace(/\r/g, '')
        .replace(/\n+$/, '')
        .replace(fieldRe, (match, p1, p2) => {
            return p2
                .replace(/\n/g, nSep)
                .replace(/""/g, qSep)
                .replace(/,/g, cSep);
        })
        .split(/\n/)
        .forEach(line => {
            const row = line.split(fieldSeparator).map(cell => {
                return cell
                    .replace(nSepRe, newLine)
                    .replace(qSepRe, '"')
                    .replace(cSepRe, ',');
            });
            grid.push(row);
        });
    return grid;
};
