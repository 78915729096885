import * as React from 'react';

const ToolsNewIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="be68fdb9-3134-4ed2-aeba-5a573444a7dc"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        {...props}
    >
        <line
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            x1="90"
            y1="40"
            x2="110"
            y2="40"
        />
        <line
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            x1="90"
            y1="160"
            x2="110"
            y2="160"
        />
        <line
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            x1="160"
            y1="90"
            x2="160"
            y2="110"
        />
        <line
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            x1="40"
            y1="90"
            x2="40"
            y2="110"
        />
        <line
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            x1="135.36"
            y1="50.5"
            x2="149.5"
            y2="64.64"
        />
        <line
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            x1="50.5"
            y1="135.36"
            x2="64.64"
            y2="149.5"
        />
        <line
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            x1="149.5"
            y1="135.36"
            x2="135.36"
            y2="149.5"
        />
        <line
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            x1="64.64"
            y1="50.5"
            x2="50.5"
            y2="64.64"
        />
        <circle
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            cx="100"
            cy="100.02"
            r="55"
        />
        <path
            fill="none"
            fillRule="evenodd"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#b5e3d8"
            d="M128,121A35,35,0,0,0,128,79"
        />
        <path
            fill="none"
            fillRule="evenodd"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            d="M79,128A35,35,0,0,0,121,128"
        />
        <path
            fill="none"
            fillRule="evenodd"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#b5e3d8"
            d="M72,79A35,35,0,0,0,72,121"
        />
        <path
            fill="none"
            fillRule="evenodd"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            d="M121,72A35,35,0,0,0,79,72"
        />
    </svg>
);

export default ToolsNewIcon;
