import React, {useMemo, useState} from 'react';
import Loader from 'components/ui/AmnisLoader/AmnisLoader';
import {
    useGenerateContract,
    useGetBusinessPartnerRequiredDocuments,
} from 'api/business-partner';
import {useCountries} from 'api/countries';
import {Alert} from 'react-bootstrap';
import {useBusinessIndustries} from 'api/business-industries';
import useAnalytics from 'hooks/useAnalytics';
import {SINGLE_BP_CACHE} from 'constants/cache';
import {useHistory} from 'react-router';
import {StepTypes} from 'types/onboarding';
import {createOnboardingUrlPath} from 'components/pages/OnboardingPage/onboardingSteps';
import {setItemToStorage} from 'helpers/localStorage';
import classNames from 'classnames';
import {IDefaultStepProps} from '../useOnboarding';
import StepTitle from '../components/StepTitle';
import ContractOverviewCard from '../components/ContractOverviewCard';
import {
    DETAILS_MAPPING,
    IOnboardingOverviewCardData,
    useOnboardingOverview,
} from '../useOnboardingOverview';
import NextStepButton from '../components/NextStepButton';

import styles from './Steps.module.scss';
import {useQueryClient} from '@tanstack/react-query';

export const CONTRACT_NOT_NEEDED_KEY = 'review_contract_not_required';

const ContractOverview = (props: IDefaultStepProps) => {
    const history = useHistory();
    const {
        activeBusinessPartner,
        isCrpLoading,
        crpList,
        contract,
        onSuccess,
        isIndividual,
        isSubmitLoading,
        inReview,
        legalType,
    } = props;
    const {data: businessIndustries, isLoading: businessIndustriesIsLoading} =
        useBusinessIndustries();
    const {data: businessPartnerRequiredDocuments} =
        useGetBusinessPartnerRequiredDocuments(activeBusinessPartner);
    const {countries, isLoading: isCountryLoading} = useCountries();
    const [error, setError] = useState<string>('');
    const {addEvent} = useAnalytics();
    const {mutate: generateContract, isPending: isGenerateLoading} =
        useGenerateContract();
    const queryClient = useQueryClient();

    const handleGenerateClick = () => {
        generateContract(activeBusinessPartner['@id'], {
            onSuccess: () => {
                if (contract === null) {
                    addEvent('contract_generated');
                }
                onSuccess({refetchContractList: true});
            },
            onError: (errorResponse: any) => {
                const contractNotNeededErrorMessage =
                    errorResponse?.violations?.find(
                        (v: any) =>
                            v.propertyPath === 'businessPartner' &&
                            v.message === CONTRACT_NOT_NEEDED_KEY,
                    );
                if (contractNotNeededErrorMessage) {
                    setItemToStorage(CONTRACT_NOT_NEEDED_KEY, true);
                    queryClient.invalidateQueries({
                        queryKey: [
                            SINGLE_BP_CACHE,
                            {bpId: activeBusinessPartner.id},
                        ],
                    });
                    if (
                        inReview &&
                        businessPartnerRequiredDocuments &&
                        businessPartnerRequiredDocuments?.length > 0
                    ) {
                        history.push(
                            createOnboardingUrlPath(StepTypes.FILE_UPLOADS),
                        );
                    } else {
                        history.push(
                            createOnboardingUrlPath(
                                StepTypes.FINISH_ONBOARDING,
                            ),
                        );
                    }
                } else
                    setError(
                        contractNotNeededErrorMessage || 'Something went wrong',
                    );
            },
        });
    };

    const renderCrps = (crp: IOnboardingOverviewCardData, index: number) => {
        return (
            <ContractOverviewCard
                className={styles.card}
                key={JSON.stringify(crp) + index}
            >
                <ContractOverviewCard.Summary>
                    <ContractOverviewCard.Title>
                        {crp.title}
                    </ContractOverviewCard.Title>
                    {crp.subtitles?.map(subtitle => (
                        <ContractOverviewCard.Subtitle key={subtitle + index}>
                            {subtitle}
                        </ContractOverviewCard.Subtitle>
                    ))}
                    <ContractOverviewCard.SummaryInfo>
                        {crp.summary.map(s => (
                            <p key={s + index}>{s}</p>
                        ))}
                    </ContractOverviewCard.SummaryInfo>
                </ContractOverviewCard.Summary>
                <ContractOverviewCard.Details>
                    {Object.keys(crp.details).map(detail => {
                        // TODO: solve this typescript headache. I gave up after hours of trying, will get back to it.
                        //@ts-ignore
                        const content = Object.keys(crp.details[detail]).reduce(
                            (acc, curr) => {
                                //@ts-ignore
                                acc[
                                    //@ts-ignore
                                    DETAILS_MAPPING[curr]?.[legalType] ||
                                        `MISSING TEXT FOR KEY: ${curr}`
                                    //@ts-ignore
                                ] = crp.details[detail][curr];
                                return acc;
                            },
                            {},
                        );
                        return (
                            <ContractOverviewCard.DetailRow
                                key={detail}
                                //@ts-ignore
                                title={DETAILS_MAPPING[detail][legalType]}
                                content={content}
                            />
                        );
                    })}
                </ContractOverviewCard.Details>
            </ContractOverviewCard>
        );
    };

    const {cardData, extractValues} = useOnboardingOverview({
        businessPartnerInfo: activeBusinessPartner,
        countries,
        crpList,
        businessIndustries: businessIndustries || [],
        isIndividual,
        legalType,
    });

    const buttonTitle = useMemo(() => {
        if (inReview) {
            return 'Next';
        }
        if (contract !== null && contract !== undefined) {
            return 'Re-generate contract';
        }

        return 'Create contract';
    }, [contract, inReview]);

    if (
        isCrpLoading ||
        isCountryLoading ||
        businessIndustriesIsLoading ||
        !cardData?.company
    ) {
        return (
            <div className="mt-4 mb-4">
                <Loader />
            </div>
        );
    }

    return (
        <div className={styles.contractOverviewContainer}>
            <div className={styles.form}>
                <StepTitle
                    title="Summary"
                    subTitle={`Are all details correct? Then click next on “${buttonTitle}”.`}
                />
                {contract !== null && contract !== undefined && !inReview ? (
                    <Alert variant="danger">
                        You already have a generated contract. Please only
                        generate a new one, if you made changes to any
                        information.
                    </Alert>
                ) : null}
                {cardData?.missingCrp ? (
                    <Alert variant="danger">
                        You haven&apos;t selected at least one owner, signer or
                        admin.
                    </Alert>
                ) : null}
            </div>
            <div className={styles.contractOverview} data-notranslate>
                <ContractOverviewCard
                    className={classNames(styles.card, styles.bpCard)}
                >
                    <ContractOverviewCard.Summary>
                        <ContractOverviewCard.Title>
                            {cardData.company.title}
                        </ContractOverviewCard.Title>
                        <ContractOverviewCard.SummaryInfo>
                            {cardData.company.summary.map(s => (
                                <p key={s}>{s}</p>
                            ))}
                        </ContractOverviewCard.SummaryInfo>
                    </ContractOverviewCard.Summary>
                    <ContractOverviewCard.Details
                        className={styles.bpCardDetails}
                    >
                        {Object.keys(cardData.company.details).map(detail => {
                            const extractedValues = extractValues(
                                //@ts-ignore
                                cardData.company.details[detail],
                            );
                            if (!Object.keys(extractedValues).length) {
                                return null;
                            }

                            return (
                                <ContractOverviewCard.DetailRow
                                    key={detail}
                                    //@ts-ignore
                                    title={DETAILS_MAPPING[detail][legalType]}
                                    content={extractedValues}
                                />
                            );
                        })}
                    </ContractOverviewCard.Details>
                </ContractOverviewCard>
                {cardData.crps?.map((crp, idx) => renderCrps(crp, idx))}
            </div>
            <div className={styles.form}>
                {error ? <Alert variant="danger">{error}</Alert> : null}
                <NextStepButton
                    onClick={handleGenerateClick}
                    loading={isGenerateLoading || isSubmitLoading}
                    label={buttonTitle}
                    disabled={cardData.missingCrp}
                />
            </div>
        </div>
    );
};

export default ContractOverview;
