import * as React from 'react';

const SvgPeerMulti = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="19"
        height="18"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="4.10345" cy="9.2069" r="4.10345" fill="currentColor" />
        <circle cx="13.5002" cy="2.63793" r="2.63793" fill="currentColor" />
        <circle cx="15.2416" cy="15.069" r="2.93103" fill="currentColor" />
        <line
            x1="4.29592"
            y1="8.66109"
            x2="15.6293"
            y2="15.1373"
            stroke="currentColor"
        />
        <line
            x1="3.79592"
            y1="8.66333"
            x2="13.5102"
            y2="2.99666"
            stroke="currentColor"
        />
    </svg>
);

export default SvgPeerMulti;
