const ListView = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M9.308 18.75v-1.5H20.5v1.5H9.308Zm0-6v-1.5H20.5v1.5H9.308Zm0-6v-1.5H20.5v1.5H9.308ZM5.163 19.664c-.457 0-.849-.163-1.174-.489A1.602 1.602 0 0 1 3.5 18c0-.457.163-.849.489-1.174a1.602 1.602 0 0 1 1.174-.489 1.6 1.6 0 0 1 1.175.489c.326.325.489.717.489 1.174a1.6 1.6 0 0 1-.489 1.175 1.602 1.602 0 0 1-1.175.489Zm0-6c-.457 0-.849-.163-1.174-.489A1.602 1.602 0 0 1 3.5 12c0-.457.163-.849.489-1.174a1.602 1.602 0 0 1 1.174-.489 1.6 1.6 0 0 1 1.175.489c.326.325.489.717.489 1.174a1.6 1.6 0 0 1-.489 1.175 1.602 1.602 0 0 1-1.175.489Zm0-6c-.457 0-.849-.163-1.174-.489A1.602 1.602 0 0 1 3.5 6c0-.457.163-.849.489-1.174a1.602 1.602 0 0 1 1.174-.489 1.6 1.6 0 0 1 1.175.489c.326.325.489.717.489 1.174a1.6 1.6 0 0 1-.489 1.175 1.602 1.602 0 0 1-1.175.489Z"
        />
    </svg>
);

export default ListView;
