import {ErrorOption, UseFormMethods} from 'react-hook-form';

/**
 * @deprecated
 * @see use {@link handleTypedFormError} instead
 * @param errors error response from API call
 * @param setError setError function of formMethods
 * @returns sets the errors on the control
 */
export const handleFormError = (
    errors: any,
    setError: UseFormMethods['setError'],
) => {
    if (!('violations' in errors)) {
        // maybe a general error message?!
        if (errors['hydra:description']) {
            setError('formError', {message: errors['hydra:description']});
        }

        return;
    }

    errors.violations.forEach((error: any) => {
        if (error.message === 'Email is already in use') {
            // This should be a key for easier identification and should be delivered on a special key
            setError('userExists', {shouldFocus: false});
        } else if (
            error.propertyPath === 'businessPartner' &&
            error.message === 'input.businessPartner.not_valid'
        ) {
            setError('formError', {
                message: "You don't have access to this resource.",
            });
        } else if (error.propertyPath) {
            setError(error.propertyPath, {message: error.message});
        } else if (error.message !== 'compliance_info') {
            setError('formError', {message: error.message});
        }
    });
};

type Error1 = {
    violations: {
        message: string;
        propertyPath?: string;
    }[];
};

type Error2 = {
    'hydra:description': string;
    'hydra:title': string;
};

interface ICommonFormErrors {
    formError?: string;
}

const isKeyofT = <T>(
    key: string | number | symbol,
    object: T extends Record<string, any> ? T : never,
): key is keyof T => {
    if (object instanceof Object === false) return false;
    const multiKeys = typeof key === 'string' && key.includes('.');

    if (multiKeys) {
        const [current, others] = (key as string).split(/\.(.*)/s);
        return isKeyofT(others, object[current]);
    }

    return key in object;
};

/**
 * If the request returns with validation errors, this will set the appropriate control with the
 * specific error. If a control with the provided path is not found, sets the error as formError
 * @param errors error response from API call
 * @param setError setError function of formMethods
 * @returns sets the errors on the control
 */
export const handleTypedFormError = <T extends ICommonFormErrors>(
    errors: Error1 | Error2,
    setError: (name: string, error: ErrorOption) => void,
    formValues: T extends Record<string, any> ? T : never,
) => {
    const userExistsKey = 'userExists';

    if (!('violations' in errors)) {
        // maybe a general error message?!
        if (errors['hydra:description']) {
            setError('formError', {message: errors['hydra:description']});
        } else {
            setError('formError', {message: 'Something went wrong'});
        }

        return;
    }

    errors.violations.forEach(error => {
        if (
            error.message === 'Email is already in use' &&
            isKeyofT(userExistsKey, formValues)
        ) {
            // This should be a key for easier identification and should be delivered on a special key
            setError(userExistsKey, {shouldFocus: false});
        } else if (
            error.propertyPath === 'businessPartner' &&
            error.message === 'input.businessPartner.not_valid'
        ) {
            setError('formError', {
                message: "You don't have access to this resource.",
            });
        } else if (error.propertyPath) {
            if (isKeyofT(error.propertyPath, formValues)) {
                setError(error.propertyPath, {message: error.message});
            } else {
                setError('formError', {
                    message: `${error.propertyPath}: ${error.message}`,
                });
            }
        } else if (error.message !== 'compliance_info') {
            setError('formError', {message: error.message});
        }
    });
};

type ErrorObjectType<T> = Partial<
    Record<keyof T, string> & {
        formError?: string;
    }
>;

export const handleManualFormErrors = <T>(
    errors: Error1 | Error2,
    setError: (errorObj: ErrorObjectType<T>) => void,
    formValues: T extends Record<string, any> ? T : never,
) => {
    const errorList: ErrorObjectType<T> = {};

    if (!('violations' in errors)) {
        // maybe a general error message?!
        if (errors['hydra:description'] || errors['hydra:title']) {
            errorList.formError =
                errors['hydra:description'] || errors['hydra:title'];
        }
    } else {
        errors.violations.forEach(error => {
            const path = error.propertyPath;

            if (
                error.propertyPath === 'businessPartner' &&
                error.message === 'input.businessPartner.not_valid'
            ) {
                errorList.formError = "You don't have access to this resource.";
            } else if (path) {
                if (isKeyofT(path, formValues) && error.propertyPath) {
                    (errorList[
                        error.propertyPath as keyof T
                    ] as unknown as string) = error.message;
                } else {
                    errorList.formError = `${error.propertyPath}: ${error.message}`;
                }
            } else if (error.message !== 'compliance_info') {
                errorList.formError = error.message;
            }
        });
    }
    setError(errorList);
};
