import React, {ReactNode} from 'react';
import {Alert, Button, Col, Modal, Row} from 'react-bootstrap';
import SubmitButton from 'components/ui/Button/SubmitButton';

interface IProps {
    title?: string;
    text?: string;
    html?: ReactNode;
    buttonText?: string;
    isLoading?: boolean;
    onConfirm: () => void;
    onCancel?: () => void;
}

const OrderStatusModal: React.FC<IProps> = ({
    title,
    text,
    html,
    buttonText,
    onConfirm,
    onCancel,
    isLoading = false,
}) => (
    <>
        <Modal.Header closeButton={false}>
            <Modal.Title as="h5">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col md={12}>
                    <Alert variant="success">{text}</Alert>
                </Col>
                {html ? <Col md={12}>{html}</Col> : null}
            </Row>
        </Modal.Body>
        <Modal.Footer>
            {typeof onCancel === 'function' && (
                <Button variant="light" onClick={onCancel}>
                    Cancel
                </Button>
            )}
            <SubmitButton isLoading={isLoading} onClick={onConfirm}>
                {buttonText}
            </SubmitButton>
        </Modal.Footer>
    </>
);

OrderStatusModal.defaultProps = {
    title: 'Confirmation of your order',
    text: 'You have successfully completed a foreign your order',
    buttonText: 'Close',
};

export default OrderStatusModal;
