import * as React from 'react';

const SvgPlus = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        {...props}
    >
        <mask id="a" fill="currentColor">
            <path
                fillRule="evenodd"
                d="M11.023 10.838h-5.86v1.954h5.86v5.86h1.954v-5.86h5.86v-1.954h-5.86V4.977h-1.954v5.86Z"
                clipRule="evenodd"
            />
        </mask>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11.023 10.838h-5.86v1.954h5.86v5.86h1.954v-5.86h5.86v-1.954h-5.86V4.977h-1.954v5.86Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            d="M5.162 10.838v-1h-1v1h1Zm5.861 0v1h1v-1h-1Zm-5.86 1.954h-1v1h1v-1Zm5.86 0h1v-1h-1v1Zm0 5.86h-1v1h1v-1Zm1.954 0v1h1v-1h-1Zm0-5.86v-1h-1v1h1Zm5.86 0v1h1v-1h-1Zm0-1.954h1v-1h-1v1Zm-5.86 0h-1v1h1v-1Zm0-5.861h1v-1h-1v1Zm-1.954 0v-1h-1v1h1Zm-5.86 6.86h5.86v-2h-5.86v2Zm1 .955v-1.954h-2v1.954h2Zm4.86-1h-5.86v2h5.86v-2Zm1 6.86v-5.86h-2v5.86h2Zm.954-1h-1.954v2h1.954v-2Zm-1-4.86v5.86h2v-5.86h-2Zm6.86-1h-5.86v2h5.86v-2Zm-1-.954v1.954h2v-1.954h-2Zm-4.86 1h5.86v-2h-5.86v2Zm-1-6.861v5.86h2v-5.86h-2Zm-.954 1h1.954v-2h-1.954v2Zm1 4.86v-5.86h-2v5.86h2Z"
            mask="url(#a)"
        />
    </svg>
);

export default SvgPlus;
