import React, {useState} from 'react';
import {usePopper} from 'react-popper';
import styles from './WithTooltip.module.scss';

interface IProps {
    children: React.ReactNode;
    tooltip: React.ReactNode;
}

const WithTooltip = ({children, tooltip}: IProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [referenceElement, setReferenceElement] =
        useState<HTMLSpanElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null,
    );
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(
        null,
    );
    const {styles: popperStyles, attributes} = usePopper(
        referenceElement,
        popperElement,
        {
            modifiers: [
                {
                    name: 'arrow',
                    options: {
                        element: arrowElement,
                    },
                },
                {
                    name: 'offset',
                    options: {
                        offset: [0, 10],
                    },
                },
            ],
        },
    );

    return (
        <>
            <span
                ref={setReferenceElement}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={e => {
                    setOpen(false);
                }}
            >
                {children}
            </span>
            {open ? (
                <div
                    ref={setPopperElement}
                    style={popperStyles.popper}
                    className={styles.tooltip}
                    {...attributes.popper}
                >
                    <div
                        ref={setArrowElement}
                        style={popperStyles.arrow}
                        className={styles.arrow}
                    />
                    {tooltip}
                </div>
            ) : null}
        </>
    );
};

export default WithTooltip;
