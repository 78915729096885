import * as React from 'react';

const SvgWarning = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" {...props}>
        <path
            fill="currentColor"
            d="M6.5 3.25c-.17239 0-.33772.06848-.45962.19038-.1219.1219-.19038.28723-.19038.45962v2.6c0 .17239.06848.33772.19038.45962.1219.1219.28723.19038.45962.19038s.33772-.06848.45962-.19038c.1219-.1219.19038-.28723.19038-.45962V3.9c0-.17239-.06848-.33772-.19038-.45962C6.83772 3.31848 6.67239 3.25 6.5 3.25Zm.598 5.603c-.01423-.04142-.0339-.08077-.0585-.117l-.078-.0975c-.0914-.0902-.20748-.15129-.33357-.17559-.12609-.02429-.25656-.01069-.37493.03909-.07877.03291-.15132.07908-.2145.1365-.06024.06073-.1079.13276-.14025.21196-.03235.07919-.04874.16399-.04825.24954.00103.08494.01869.16885.052.247.02919.08066.07577.15391.13643.21457.06065.06066.13391.10723.21457.13643.07781.03439.16194.05215.247.05215.08507 0 .1692-.01776.247-.05215.08066-.0292.15392-.07577.21458-.13643.06065-.06066.10723-.13391.13642-.21457.03331-.07815.05097-.16206.052-.247.00319-.04328.00319-.08673 0-.13-.01119-.04145-.02873-.08092-.052-.117ZM6.5 0C5.21442 0 3.95771.381218 2.8888 1.09545 1.81988 1.80968.986755 2.82484.494786 4.01256.00281635 5.20028-.125905 6.50721.124899 7.76808.375702 9.02896.994767 10.1872 1.90381 11.0962c.90904.909 2.06723 1.5281 3.32811 1.7789 1.26087.2508 2.5678.1221 3.75552-.3699 1.18776-.492 2.20286-1.3251 2.91716-2.394C12.6188 9.04228 13 7.78558 13 6.5c0-.85359-.1681-1.69883-.4948-2.48744-.3266-.78862-.8054-1.50517-1.409-2.10875C10.4926 1.30022 9.77606.821438 8.98744.494783 8.19883.168127 7.35359 0 6.5 0Zm0 11.7c-1.02846 0-2.03383-.305-2.88896-.8764-.85514-.5713-1.52164-1.38347-1.91521-2.33365-.39358-.95017-.49655-1.99572-.29591-3.00442.20064-1.0087.69589-1.93525 1.42313-2.66249.72723-.72723 1.65378-1.22248 2.66248-1.42312 1.0087-.20065 2.05425-.09767 3.00442.29591.95018.39357 1.76235 1.06007 2.33365 1.9152.5714.85514.8764 1.86051.8764 2.88897 0 1.37912-.5479 2.70176-1.523 3.677-.97523.9751-2.29787 1.523-3.677 1.523Z"
        />
    </svg>
);

export default SvgWarning;
