import classNames from 'classnames';
import {useCountdown} from 'hooks/common';
import React, {useCallback, useEffect, useRef} from 'react';
import FA from 'react-fontawesome';
import useModal from 'components/ui/ModalContainer/useModal';
import {ModalKey} from 'components/ui/ModalContainer/modalStore';
import styles from './ModalOverlay.module.scss';

export interface IModalOverlayProps {
    children: React.ReactNode;
    blue?: boolean;
    scrollable?: boolean;
    closeIcon?: boolean;
    onClose?: () => void;
    modalKey?: ModalKey;
    timerText?: string;
    timerInSec?: number;
    doNotCloseOnOutsideClick?: boolean;
}

const ModalOverlay = ({
    children,
    blue,
    scrollable = true,
    closeIcon = true,
    onClose = () => null,
    modalKey,
    timerText,
    timerInSec,
    doNotCloseOnOutsideClick,
}: IModalOverlayProps) => {
    const {closeModal} = useModal();
    const containerRef = useRef<HTMLDivElement>(null);

    const {timeLeft} = useCountdown(timerInSec);

    const handleClose = useCallback(() => {
        closeModal(modalKey);
        onClose();
    }, [closeModal, modalKey, onClose]);

    const handleOutsideClick: React.MouseEventHandler = event => {
        if (event.target === containerRef?.current) {
            handleClose();
        }
    };

    useEffect(() => {
        if (timeLeft === 0 && timerInSec) {
            handleClose();
        }
    }, [handleClose, timeLeft, timerInSec]);

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        <div
            onClick={doNotCloseOnOutsideClick ? undefined : handleOutsideClick}
            ref={containerRef}
            className={classNames(styles.overlayContainer, {
                [styles.blue]: blue,
            })}
        >
            <div
                className={classNames(styles.modal, {
                    [styles.scrollable]: scrollable,
                })}
            >
                {closeIcon ? (
                    <button
                        className={styles.closeButton}
                        onClick={() => handleClose()}
                    >
                        <FA size="lg" name="times" />
                    </button>
                ) : null}
                {children}
                {timerText && timerInSec ? (
                    <div className={styles.timerText}>
                        {timerText}
                        <var data-var="timeLeft">
                            <span className="text-primary">{timeLeft}</span>
                        </var>{' '}
                        seconds.
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default ModalOverlay;
