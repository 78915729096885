import {useAuth} from 'context/auth-context';

import {ConfirmationModal} from 'components/modals';
import Form, {FormOnSubmit} from 'components/ui/Form/Form/Form';
import useModal from 'components/ui/ModalContainer/useModal';
import {CrpVariants} from 'constants/businessPartnerAttributes';
import OnboardingOverlay from 'components/ui/Overlays/OnboardingOverlay/OnboardingOverlay';
import {crpFormValidationSchema} from 'form/validation/businessPartner';
import CrpForm from 'components/pages/OnboardingPage/components/CrpForm';
import useCrpTable from 'components/pages/OnboardingPage/components/hooks/useCrpTable';
import {translate} from 'helpers/localize';
import CrpSignerInfo from 'components/pages/OnboardingPage/components/Infos/CrpSignerInfo';
import {IDefaultStepProps} from '../useOnboarding';
import StepTitle from '../components/StepTitle';
import NextStepButton from '../components/NextStepButton';
import CrpTable from '../components/CrpTable';

import styles from './Steps.module.scss';

const CrpSigner = ({
    isCrpLoading,
    crpList,
    onSuccess,
    activeBusinessPartner,
    currentStep,
    legalType,
    isSubmitLoading,
    inReview,
}: IDefaultStepProps) => {
    const {closeModal, openModalWithContent} = useModal();
    const {handleCrpEdit} = useCrpTable({crpList, currentStep});

    const {user} = useAuth();

    const checkAllSigners = (): boolean => {
        const signers = crpList.filter(crp => crp.hasSigningRights);
        let result = true;

        signers.forEach(signer => {
            try {
                crpFormValidationSchema(
                    CrpVariants.SIGNER,
                    signer.confidential,
                    legalType,
                ).validateSync({...signer.crp, ...signer});
            } catch (err) {
                result = false;
                openModalWithContent(
                    'crpForm',
                    <OnboardingOverlay fullHeight>
                        <CrpForm
                            legalType={legalType}
                            variant={CrpVariants.SIGNER}
                            handleSubmit={(crpValues, setCrpError) => {
                                handleCrpEdit(
                                    CrpVariants.SIGNER,
                                    signer.crp.user === user['@id'],
                                    crpValues,
                                    true,
                                    signer,
                                    setCrpError,
                                );
                            }}
                            crpBpId={signer['@id']}
                            isLoggedInUser={signer.crp.user === user['@id']}
                            inReview={inReview}
                        />
                    </OnboardingOverlay>,
                );
            }
        });

        return result;
    };

    const checkCrpBeforeSubmit: FormOnSubmit<never> = () => {
        if (!checkAllSigners()) return;

        const self = crpList.find(s => s.crp.user === user['@id']);
        if (!self || (!self.isOwner && !self.hasSigningRights)) {
            openModalWithContent(
                'confirmation',
                <OnboardingOverlay hideCloseButton>
                    <ConfirmationModal
                        title="Warning"
                        description="You didn't select yourself for any of the beneficial owner(s) or persons, who will sign the contract. Is this right?"
                        onClose={() => closeModal('confirmation')}
                        onSubmit={() => {
                            closeModal('confirmation');
                            onSuccess();
                        }}
                    />
                </OnboardingOverlay>,
            );
        } else {
            onSuccess();
        }
    };

    const hasSigners = crpList?.some(crp => crp.hasSigningRights);

    return (
        <Form onSubmit={checkCrpBeforeSubmit}>
            <div className={styles.form}>
                <StepTitle
                    title={
                        inReview
                            ? 'Are the authorized signatories current?'
                            : 'Authorized signatories'
                    }
                    subTitle={translate(
                        `Who is officially registered to act or sign on behalf of %{companyName}?`,
                        {companyName: activeBusinessPartner.companyName},
                    )}
                    translateSubtitle={false}
                    info={<CrpSignerInfo />}
                />
                <div className={styles.labelContainer}>
                    <span className={styles.label}>
                        Select or add anyone who is authorized to do so.
                    </span>
                </div>
            </div>
            <CrpTable
                crpList={crpList}
                isCrpLoading={isCrpLoading}
                variant={CrpVariants.SIGNER}
                currentStep={currentStep}
                legalType={legalType}
                inReview={inReview}
            />
            <div className={styles.form}>
                <NextStepButton
                    disabled={!hasSigners}
                    loading={isSubmitLoading}
                />
            </div>
        </Form>
    );
};

export default CrpSigner;
