/* eslint-disable */
import * as React from 'react';

const SvgChart = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="chart_svg__svg-inline--fa chart_svg__fa-chart-line chart_svg__fa-w-16 chart_svg__fa-lg chart_svg__text-center"
        aria-hidden="true"
        data-prefix="fal"
        data-icon="chart-line"
        viewBox="0 0 512 512"
        width="1em"
        height="1em"
        {...props}
    >
        <path
            fill="currentColor"
            d="M504 416H32V72c0-4.42-3.58-8-8-8H8c-4.42 0-8 3.58-8 8v360c0 8.84 7.16 16 16 16h488c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM98.34 263.03c-3.12 3.12-3.12 8.19 0 11.31l11.31 11.31c3.12 3.12 8.19 3.12 11.31 0l72.69-72.01 84.69 84.69c6.25 6.25 16.38 6.25 22.63 0l93.53-93.53 44.04 44.04c4.95 4.95 11.03 7.16 17 7.16 12.48 0 24.46-9.7 24.46-24.34V112.19c0-8.94-7.25-16.19-16.19-16.19H344.34c-21.64 0-32.47 26.16-17.17 41.46l44.71 44.71-82.22 82.22-84.63-84.63c-6.23-6.23-16.32-6.25-22.57-.05l-84.12 83.32zM362.96 128H448v85.04L362.96 128z"
        />
    </svg>
);

export default SvgChart;
