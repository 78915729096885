import * as React from 'react';

const SvgCollateral = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="25"
        fill="none"
        {...props}
    >
        <path
            fill="#33F"
            d="m10 0 9.13 2.075c.247.056.467.197.626.398.158.202.244.453.244.711v11.349a6.942 6.942 0 0 1-.789 3.217 6.766 6.766 0 0 1-2.18 2.456L10 25l-7.031-4.794a6.766 6.766 0 0 1-2.18-2.456A6.942 6.942 0 0 1 0 14.534V3.184c0-.258.086-.51.244-.71.159-.202.38-.343.626-.399L10 0Zm0 6.818c-.49 0-.965.165-1.353.47a2.276 2.276 0 0 0-.793 1.215c-.127.483-.094.995.093 1.458.187.462.518.848.942 1.098v4.85h2.222l.001-4.85c.424-.25.755-.636.942-1.099.187-.462.22-.975.093-1.458a2.276 2.276 0 0 0-.794-1.215A2.19 2.19 0 0 0 10 6.818Z"
        />
    </svg>
);

export default SvgCollateral;
