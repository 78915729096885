/* eslint-disable */
import * as React from 'react';
const HedgeMonitorChart = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="89.822" height="87" {...props}>
            <path fill="#0f3e82" d="M5.116 81.884V0H0v87h87.31v-5.116z" />
            <path
                fill="none"
                stroke="#fecf84"
                strokeWidth="5"
                d="M13.655 69.333l34-30 12 8.667 26.667-26.667"
            />
            <path
                fill="none"
                stroke="#fecf84"
                strokeWidth="5"
                d="M74.988 20.52h12.334v11.793"
            />
        </svg>
    );
};

export default HedgeMonitorChart;
