import {ConfirmationModal} from 'components/modals';
import {Location} from 'history';
import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {Prompt} from 'react-router-dom';

export interface IRouteLeavingGuardProps {
    when?: boolean | undefined;
    navigate: (path: string) => void;
    shouldBlockNavigation: (location: Location) => boolean;
}
const RouteLeavingGuard = ({
    when,
    navigate,
    shouldBlockNavigation,
}: IRouteLeavingGuardProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(
        false,
    );

    const handleConfirmNavigationClick = () => {
        setModalOpen(false);
        setConfirmedNavigation(true);
    };

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalOpen(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            // Navigate to the previous blocked location
            navigate(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation, navigate]);
    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
                {modalOpen ? (
                    <ConfirmationModal
                        onSubmit={handleConfirmNavigationClick}
                        onClose={() => setModalOpen(false)}
                        title="Warning"
                        description="If you leave the page now, your changes won't be saved. Are you sure you want to leave?"
                    />
                ) : null}
            </Modal>
        </>
    );
};
export default RouteLeavingGuard;
