interface IProps extends React.SVGProps<SVGSVGElement> {
    filled?: boolean;
}

const Bookmark = ({filled, ...rest}: IProps) => {
    return filled ? (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <mask
                id="mask0_22709_23829"
                style={{maskType: 'alpha'}}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="32"
                height="32"
            >
                <rect width="32" height="32" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_22709_23829)">
                <path
                    d="M7.3335 26.9999V7.07697C7.3335 6.40346 7.56683 5.83337 8.0335 5.3667C8.50016 4.90004 9.07025 4.6667 9.74376 4.6667H22.2565C22.93 4.6667 23.5001 4.90004 23.9668 5.3667C24.4334 5.83337 24.6668 6.40346 24.6668 7.07697V26.9999L16.0001 23.282L7.3335 26.9999Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    ) : (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <mask
                id="mask0_24892_582"
                style={{maskType: 'alpha'}}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_24892_582)">
                <path
                    d="M5.5 20.2499V5.30773C5.5 4.80259 5.675 4.37502 6.025 4.02502C6.375 3.67502 6.80257 3.50002 7.3077 3.50002H16.6922C17.1974 3.50002 17.625 3.67502 17.975 4.02502C18.325 4.37502 18.5 4.80259 18.5 5.30773V20.2499L12 17.4615L5.5 20.2499ZM6.99997 17.95L12 15.8L17 17.95V5.30773C17 5.23079 16.9679 5.16027 16.9038 5.09615C16.8397 5.03205 16.7692 5 16.6922 5H7.3077C7.23077 5 7.16024 5.03205 7.09613 5.09615C7.03202 5.16027 6.99997 5.23079 6.99997 5.30773V17.95Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
};

export default Bookmark;
