import React, {ChangeEventHandler} from 'react';
import classNames from 'classnames';
import {useFormContext} from 'react-hook-form';
import InfoPopover from 'components/ui/InfoPopover/InfoPopover';
import {getDeepValue} from 'helpers/common';
import ErrorFeedback from '../ErrorFeedback/ErrorFeedback';
import {IDefaultControlProps} from '../Form/Form';

import styles from './OnboardingCheckBoxGroup.module.scss';

interface IOnboardingCheckBoxOption {
    label: string | React.ReactNode;
    value: string | number;
    disabled?: boolean;
}

type OnboardingCheckBoxType = 'checkbox' | 'radio';

interface IOnboardingCheckBoxGroupProps extends IDefaultControlProps {
    options: IOnboardingCheckBoxOption[];
    type?: OnboardingCheckBoxType;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    checked?: boolean;
    defaultValue?: string | number;
    horizontal?: boolean;
    optionClassName?: string;
    translateLabel?: boolean;
}

const CheckBoxGroup = (props: IOnboardingCheckBoxGroupProps) => {
    const {
        options,
        className,
        optionClassName,
        name,
        label,
        info,
        type = 'checkbox',
        onChange,
        variant,
        defaultValue,
        rounded = true,
        horizontal = false,
        disabled,
        readOnly,
        hidden,
        translateLabel = true,
        size,
    } = props;
    const {register, errors} = useFormContext();

    return (
        <div
            className={classNames(styles.container, className, {
                [styles.hidden]: hidden,
            })}
        >
            {label ? (
                <label
                    htmlFor={name}
                    className={styles.label}
                    translate={translateLabel ? 'yes' : 'no'}
                >
                    {label}
                    {info ? <InfoPopover content={info} /> : null}
                </label>
            ) : null}
            {type === 'checkbox' && options.length > 1 ? (
                <p className={styles.label}>Multiple choice possible</p>
            ) : null}
            <div className={classNames({[styles.horizontal]: horizontal})}>
                {options.map(o => {
                    return (
                        <div
                            className={classNames(
                                styles.checkItem,
                                optionClassName,
                            )}
                            key={o.value}
                        >
                            <input
                                key={o.value}
                                id={`${name}_${o.value}`}
                                name={name}
                                value={o.value}
                                type={type}
                                ref={register}
                                className={styles.input}
                                onChange={onChange && onChange}
                                defaultValue={0}
                                defaultChecked={defaultValue === o.value}
                                disabled={disabled || !!o.disabled || readOnly}
                            />
                            <label
                                htmlFor={`${name}_${o.value}`}
                                className={classNames(styles.checkItemLabel, {
                                    [styles.invalid]: getDeepValue(
                                        errors,
                                        name,
                                    ),
                                    [styles.dark]: variant === 'dark',
                                    [styles.rounded]: rounded,
                                    [styles.disabled]: disabled || readOnly,
                                    [styles.large]: size === 'large',
                                    [styles.disabled]:
                                        disabled || !!o.disabled || readOnly,
                                })}
                            >
                                {o.label}
                            </label>
                        </div>
                    );
                })}
            </div>
            {getDeepValue(errors, name)?.message ? (
                <ErrorFeedback message={getDeepValue(errors, name).message} />
            ) : null}
        </div>
    );
};

export default CheckBoxGroup;
