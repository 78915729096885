import {useQuery} from '@tanstack/react-query';

import {useClient} from 'context/auth-context';

const CURRENCY_ENDPOINT = '/web_api/currencies';
export interface ICurrency {
    '@id': string;
    name: string;
    code: string;
    closingTime: string;
    valueDay: number;
}

const useCurrencies = () => {
    const client = useClient();
    return useQuery<ICurrency[]>({
        queryKey: ['currencies'],
        queryFn: () => client(CURRENCY_ENDPOINT, {}),
    });
};

export {useCurrencies};
