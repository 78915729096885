export enum UserMenuItemType {
    LINK = 'LINK',
    BUTTON = 'BUTTON',
    SUBMENU = 'SUBMENU',
    DIVIDER = 'DIVIDER',
}

export interface IUserMenuItemBase {
    label: string;
    type: UserMenuItemType;
    disabled?: boolean;
    disabledTooltip?: string;
    className?: string;
    renderCondition?: (args?: unknown) => boolean;
    renderComponent?: () => JSX.Element;
}

export interface IUserMenuItemDivider extends Omit<IUserMenuItemBase, 'label'> {
    type: UserMenuItemType.DIVIDER;
}

export interface IUserMenuItemLink extends IUserMenuItemBase {
    type: UserMenuItemType.LINK;
    to: string;
}

export interface IUserMenuItemButton extends IUserMenuItemBase {
    type: UserMenuItemType.BUTTON;
    action: (args?: unknown) => unknown;
}

export interface IUserMenuItemSubmenu extends IUserMenuItemBase {
    type: UserMenuItemType.SUBMENU;
    children: IUserMenuItem[];
}

export type IUserMenuItem =
    | IUserMenuItemLink
    | IUserMenuItemButton
    | IUserMenuItemSubmenu
    | IUserMenuItemDivider;

export type UserMenuHierarchy = IUserMenuItem[];
