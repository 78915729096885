import classNames from 'classnames';
import {translate} from 'helpers/localize';
import React, {useState} from 'react';
import FA from 'react-fontawesome';

import styles from './ContractOverviewCard.module.scss';

export interface IOverviewCardProps {
    children: React.ReactElement[];
    className?: string;
}

// TODO remove this component and use the ui/Accordion

const ContractOverviewCard = ({children, className}: IOverviewCardProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const toggleOpen = () => {
        setOpen(isOpen => !isOpen);
    };

    return (
        <div
            className={classNames(styles.container, className, {
                [styles.closed]: !open,
            })}
        >
            {children}
            <div className={styles.buttonRow}>
                <button
                    className={styles.button}
                    type="button"
                    onClick={toggleOpen}
                >
                    {translate('Details')}{' '}
                    {open ? (
                        <FA name="chevron-up" />
                    ) : (
                        <FA name="chevron-down" />
                    )}
                </button>
            </div>
        </div>
    );
};

const Summary = ({children}: {children: React.ReactNode}) => (
    <div className={styles.summary}>{children}</div>
);

const Title = ({children}: {children: React.ReactNode}) => (
    <p className={styles.title}>{children}</p>
);

const Subtitle = ({children}: {children: React.ReactNode}) => (
    <p className={styles.subtitle}>{children}</p>
);

const SummaryInfo = ({
    children,
}: {
    children: React.ReactNode | React.ReactNode[];
}) => <div className={styles.summaryInfo}>{children}</div>;

const Details = ({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) => <div className={classNames(styles.details, className)}>{children}</div>;

const DetailRow = ({
    title,
    content,
}: {
    title: string;
    content: Record<string, string>;
}) => (
    <div className={styles.detailRow}>
        <p className={styles.detailRowTitle}>{translate(title)}</p>
        {Object.keys(content).map(contentKey => {
            if (
                content[contentKey] === undefined ||
                content[contentKey] === null
            )
                return null; // don't render empty values
            return (
                <div key={contentKey} className={styles.detailRowEntry}>
                    <p className={styles.key}>{translate(contentKey)}</p>
                    <p className={styles.value}>
                        <span>{content[contentKey]}</span>
                    </p>
                </div>
            );
        })}
    </div>
);

ContractOverviewCard.Summary = Summary;
ContractOverviewCard.Title = Title;
ContractOverviewCard.Subtitle = Subtitle;
ContractOverviewCard.SummaryInfo = SummaryInfo;
ContractOverviewCard.Details = Details;
ContractOverviewCard.DetailRow = DetailRow;

export default ContractOverviewCard;
