import classNames from 'classnames';
import Button, {IButtonProps} from 'components/ui/Button';
import IconSVG from 'components/ui/Icon/IconSVG';
import {getDeepValue} from 'helpers/common';
import React, {useEffect, useRef} from 'react';
import {useFormContext} from 'react-hook-form';
import useFileUpload from 'hooks/useFileUpload';
import {
    isNewFileUploadStatus,
    isUploadedFileUploadStatus,
    IUploadStatus,
} from 'components/ui/Form/FileUploadButton/useFileUploadStatuses';
import {getIconForStatus} from 'components/ui/Form/FileUploadButton/FileUploadButton';
import InfoPopover from 'components/ui/InfoPopover/InfoPopover';
import ErrorFeedback from '../ErrorFeedback/ErrorFeedback';
import {IDefaultControlProps} from '../Form/Form';

import styles from './FileUploadArea.module.scss';

interface IProps extends IDefaultControlProps {
    accept?: string;
    areaText?: string;
    buttonLabel?: string;
    multi?: boolean;
    buttonVariant?: IButtonProps['variant'];
    uploadStatuses: IUploadStatus[];
    uploadedDocuments?: {id: string; name: string | null}[];
    onDelete?: (idOrDocument: string | File, onSuccess?: () => void) => void;
    removeUploaded?: boolean;
    label?: string;
}

const FileUploadArea = (props: IProps) => {
    const {
        name,
        disabled,
        readOnly,
        multi,
        uploadStatuses,
        buttonLabel = 'Select document(s)',
        areaText = 'Drag your PDF, JPG, JPEG or PNG file here or choose one from your device (max file size 8MB).',
        uploadedDocuments = [],
        onDelete,
        accept,
        label,
        info,
    } = props;
    const {register, errors, setValue} = useFormContext();
    const {
        dragHandlers,
        files,
        dragOver,
        clearUploadFile,
        changeHandler,
        inputRef,
    } = useFileUpload({multi, disabled});
    register(name);

    const isUploading = uploadStatuses.some(u => u.status === 'loading');

    const firstRender = useRef(true);

    useEffect(() => {
        files.forEach(file => {
            const found = uploadStatuses.find(
                d =>
                    isNewFileUploadStatus(d) &&
                    d.document === file &&
                    (d.status === 'uploaded' || d.status === 'error'),
            );
            if (found && isNewFileUploadStatus(found)) {
                clearUploadFile(found.document);
            }
        });
    }, [clearUploadFile, files, uploadStatuses]);

    useEffect(() => {
        setValue(name, files, {
            shouldDirty: !firstRender.current,
            shouldValidate: !firstRender.current,
        });
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        if (firstRender.current) firstRender.current = false;
    }, [files, inputRef, name, setValue]);

    return (
        <>
            {label ? (
                <p className={styles.label}>
                    {label}
                    {info ? <InfoPopover content={info} /> : null}
                </p>
            ) : null}
            <div
                {...dragHandlers}
                className={classNames(styles.uploadContainer, {
                    [styles.onDrag]: dragOver,
                    [styles.disabled]: disabled,
                })}
            >
                <div className={classNames(styles.container)}>
                    {!files?.length &&
                    !uploadedDocuments.length &&
                    !uploadStatuses.length ? (
                        <div className={styles.input}>
                            <IconSVG
                                name="addFile"
                                className={styles.icon}
                                customDimensions
                            />
                            <p>{areaText}</p>
                        </div>
                    ) : (
                        <div className={styles.fileList}>
                            <div className={styles.fileNames}>
                                {uploadedDocuments.map(doc => {
                                    return (
                                        <div className={styles.fileContainer}>
                                            <div
                                                key={doc.id}
                                                className={classNames(
                                                    styles.file,
                                                    styles.successStatus,
                                                )}
                                            >
                                                <div
                                                    className={
                                                        styles.successIcon
                                                    }
                                                >
                                                    <IconSVG name="checkMark" />
                                                </div>
                                                <div
                                                    className={styles.fileName}
                                                    data-notranslate
                                                >
                                                    {doc.name}
                                                </div>
                                                {onDelete !== undefined ? (
                                                    <Button
                                                        variant="text"
                                                        color="secondary"
                                                        iconOnly
                                                        className={
                                                            styles.iconButton
                                                        }
                                                        icon={
                                                            <IconSVG name="trash" />
                                                        }
                                                        onClick={() => {
                                                            onDelete(doc.id);
                                                        }}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    );
                                })}
                                {uploadStatuses
                                    .filter(doc => doc.status !== 'uploaded')
                                    .map(doc => {
                                        return (
                                            <div
                                                className={styles.fileContainer}
                                            >
                                                <div
                                                    key={
                                                        isNewFileUploadStatus(
                                                            doc,
                                                        )
                                                            ? `${doc.document.name}${doc.document.size}${doc.document.lastModified}`
                                                            : doc.id
                                                    }
                                                    className={classNames(
                                                        styles.file,
                                                        {
                                                            [styles.errorStatus]:
                                                                doc?.status ===
                                                                'error',
                                                            [styles.successStatus]:
                                                                doc?.status ===
                                                                'uploaded',
                                                        },
                                                    )}
                                                >
                                                    <div
                                                        className={
                                                            styles.fileName
                                                        }
                                                        data-notranslate
                                                    >
                                                        {doc.name}
                                                    </div>
                                                    {isNewFileUploadStatus(
                                                        doc,
                                                    ) ? (
                                                        <Button
                                                            variant="text"
                                                            color="secondary"
                                                            iconOnly
                                                            className={
                                                                styles.iconButton
                                                            }
                                                            icon={getIconForStatus(
                                                                doc?.status,
                                                            )}
                                                            onClick={() =>
                                                                onDelete?.(
                                                                    doc.document,
                                                                    () =>
                                                                        clearUploadFile(
                                                                            doc.document,
                                                                        ),
                                                                )
                                                            }
                                                        />
                                                    ) : null}
                                                    {isUploadedFileUploadStatus(
                                                        doc,
                                                    ) && !!doc?.id ? (
                                                        <Button
                                                            variant="text"
                                                            color="secondary"
                                                            iconOnly
                                                            className={
                                                                styles.iconButton
                                                            }
                                                            icon={getIconForStatus(
                                                                doc?.status,
                                                            )}
                                                            onClick={() => {
                                                                onDelete?.(
                                                                    doc.id,
                                                                );
                                                            }}
                                                        />
                                                    ) : null}
                                                </div>
                                                {doc?.status === 'error' ? (
                                                    <p className={styles.error}>
                                                        {doc?.errorMessage}
                                                    </p>
                                                ) : null}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    )}
                    <input
                        data-notranslate
                        type="file"
                        multiple={multi}
                        hidden
                        name={name}
                        ref={inputRef}
                        accept={accept}
                        onChange={e => {
                            e.persist();
                            changeHandler(Array.from(e.target.files || []));
                        }}
                        disabled={disabled}
                    />
                    <div className={styles.buttonContainer}>
                        <Button
                            icon="upload"
                            onClick={() => inputRef.current?.click()}
                            color={
                                getDeepValue(errors, name)
                                    ? 'danger'
                                    : 'primary'
                            }
                            disabled={disabled || readOnly || isUploading}
                        >
                            {buttonLabel}
                        </Button>
                        {getDeepValue(errors, name)?.message ? (
                            <ErrorFeedback
                                message={getDeepValue(errors, name).message}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FileUploadArea;
