import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useClient} from 'context/auth-context';
import {
    WALLETS_CACHE,
    BP_WALLETS_COLLATERAL_CACHE,
    WALLET_CACHE,
} from 'constants/cache';
import {IWalletTransactionDetail} from 'types/transactions';
import {
    BusinessPartner,
    IBusinessPartnerWallet,
    ITag,
    IUpdateBusinessPartnerWalletOrderRequest,
    IWalletCreate,
    TransactionDetailWithBalanceOnExecutionDate,
    WalletTypes,
} from 'types/api';
import {ROUTING_TYPE_MAPPING} from 'constants/wallet';

import {getIdsFromTags} from './util';
import {PATCH_HEADERS} from './constants';

export type VirtualIban = {
    '@type': 'VirtualIban';
    id: number;
    currency: string;
    iban: string;
    address: string;
    country: string;
    paymentType: 'regular' | 'priority';
    bankName: string;
    routingCode: string;
    routingType: keyof typeof ROUTING_TYPE_MAPPING;
};

const useGetBusinessPartnerWallets = (
    walletType: WalletTypes = WalletTypes.NORMAL,
) => {
    const client = useClient();
    return useQuery<IBusinessPartnerWallet[]>({
        queryKey: [WALLETS_CACHE, {walletType}],
        queryFn: () =>
            client('/web_api/wallets', {
                params: {
                    type: walletType,
                },
            }),
    });
};

const useGetWallet = (walletIri?: string) => {
    const client = useClient();
    return useQuery<IBusinessPartnerWallet>({
        queryKey: [WALLET_CACHE, {walletIri}],
        queryFn: () => client(`${walletIri}`, {}),
        enabled: !!walletIri,
    });
};

const useUpdateBusinessPartnerWalletOrder = () => {
    const client = useClient();
    const queryClient = useQueryClient();

    return useMutation<
        BusinessPartner,
        unknown,
        IUpdateBusinessPartnerWalletOrderRequest
    >({
        mutationFn: ({wallets, businessPartnerId, walletType}) => {
            return client(`${businessPartnerId}/order_wallets`, {
                data: {wallets},
                method: 'PATCH',
                params: {
                    type: walletType,
                },
                ...PATCH_HEADERS,
            });
        },
        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: [BP_WALLETS_COLLATERAL_CACHE],
            }),
    });
};

export interface IDeleteBusinessPartnerWallet {
    walletId: string;
}

const useDeleteBusinessPartnerWallet = () => {
    const client = useClient();
    const queryClient = useQueryClient();

    return useMutation<unknown, unknown, IDeleteBusinessPartnerWallet>({
        mutationFn: ({walletId}) =>
            client(walletId, {
                data: {},
                method: 'DELETE',
            }),
        onSuccess: () =>
            queryClient.invalidateQueries({queryKey: [WALLETS_CACHE]}),
    });
};

export interface IUseCreateWalletTransactionRequest {
    contact: string;
    senderWallet: string;
    amount: string;
    comment: string | null;
    executionDate: string;
    tags: ITag[];
}

const useCreateWalletTransaction = () => {
    const client = useClient();
    return useMutation<
        TransactionDetailWithBalanceOnExecutionDate<IWalletTransactionDetail>,
        unknown,
        IUseCreateWalletTransactionRequest
    >({
        mutationFn: data => {
            return client('/web_api/wallet_transaction_details', {
                data: {...data, tags: getIdsFromTags(data.tags)},
                method: 'POST',
            });
        },
    });
};

const useCreateWallet = (walletType: WalletTypes = WalletTypes.NORMAL) => {
    const client = useClient();
    const queryClient = useQueryClient();

    return useMutation<IBusinessPartnerWallet, unknown, IWalletCreate>({
        mutationFn: data =>
            client('/web_api/wallets', {
                data: {
                    ...data,
                    type: walletType,
                },
                method: 'POST',
            }),
        onSuccess: () =>
            queryClient.invalidateQueries({queryKey: [WALLETS_CACHE]}),
    });
};

const useCreateCollateralTransaction = () => {
    const client = useClient();

    return useMutation({
        mutationFn: (data: any) =>
            client('/web_api/wallet_transaction_collateral_details', {
                data,
                method: 'POST',
            }),
    });
};

export {
    useGetWallet,
    useGetBusinessPartnerWallets,
    useCreateWalletTransaction,
    useCreateWallet,
    useCreateCollateralTransaction,
    useUpdateBusinessPartnerWalletOrder,
    useDeleteBusinessPartnerWallet,
};
