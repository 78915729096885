import {useEffect} from 'react';
import {Alert, AlertProps, ProgressBar} from 'react-bootstrap';
import {
    INotificationData,
    INotificationListItem,
    useNotification,
} from 'context/notification-context';
import classNames from 'classnames';
import {useCountdown} from '../../../../../hooks/common';

import styles from './Notification.module.scss';

export interface INotificationProps {
    data: INotificationListItem;
}

const alertMappings: {
    [key in INotificationData['status']]: AlertProps['variant'];
} = {
    error: 'danger',
    success: 'success',
};

const TIME_LIMIT = 10;

const Notification = ({data}: INotificationProps) => {
    const {timeLeft, stopCounter} = useCountdown(TIME_LIMIT, true);
    const {removeNotification} = useNotification();

    useEffect(() => {
        if (timeLeft <= 0) {
            removeNotification(data.id);
        }
    }, [timeLeft, removeNotification, data]);

    useEffect(() => {
        return () => stopCounter?.();
    }, [stopCounter]);

    return (
        <Alert
            variant={alertMappings[data.content.status]}
            onClose={() => removeNotification(data.id)}
            dismissible
            className={styles.container}
        >
            {data.content.message}
            <ProgressBar
                className={classNames(
                    styles.progress,
                    styles[alertMappings[data.content.status]!],
                )}
                now={(100 / (TIME_LIMIT - 1)) * (timeLeft - 1)}
            />
        </Alert>
    );
};

export default Notification;
