import React, {useState, useEffect, useMemo, useCallback} from 'react';

import {BusinessPartner, BusinessPartnerList} from 'types/api';
import {
    useGetBusinessPartner,
    useGetBusinessPartnersList,
} from 'api/business-partner';
import Loader from 'components/ui/AmnisLoader/AmnisLoader';
import {
    getItemFromStorage,
    removeItemFromStorage,
    setItemToStorage,
} from 'helpers/localStorage';
import {LOCAL_STORAGE_BP_KEY, PATH} from 'constants/common';
import {BP_LIST_CACHE} from 'constants/cache';
import {useHistory} from 'react-router';
import {startOfDay, subDays} from 'date-fns';
//@ts-ignore
import {BUILD_ENV} from 'config';
import {useAuth} from './auth-context';
import {useQueryClient} from '@tanstack/react-query';

const RESTRICTED_ACCOUNT_TEXT =
    'Account temporarily restricted. Contact your relationship manager for details.';
const INACTIVE_ACCOUNT_TEXT =
    'Due to extended inactivity, we are obligated to limit access until you update your customer profile. Start the review process to re-activate your amnis account.';

export const getBusinessPartnerRestrictionText = (
    businessPartner?: BusinessPartner,
): {
    cardsBlocked: boolean;
    paymentBlocked: boolean;
    message: string;
} | null => {
    if (businessPartner?.status === 'dormant') {
        return {
            cardsBlocked: businessPartner?.cardsBlocked,
            paymentBlocked: businessPartner?.paymentBlocked,
            message: INACTIVE_ACCOUNT_TEXT,
        };
    }

    if (businessPartner?.cardsBlocked || businessPartner?.paymentBlocked) {
        return {
            message: RESTRICTED_ACCOUNT_TEXT,
            cardsBlocked: businessPartner?.cardsBlocked,
            paymentBlocked: businessPartner?.paymentBlocked,
        };
    }

    return null;
};

export const BusinessPartnerContext = React.createContext<
    | {
          businessPartners: BusinessPartner[];
          activeBusinessPartner: BusinessPartner;
          setSelectedBpId: React.Dispatch<React.SetStateAction<number | null>>;
          setActiveBusinessPartner: React.Dispatch<
              React.SetStateAction<BusinessPartner | null>
          >;
          setWithGwgInfo: React.Dispatch<React.SetStateAction<boolean>>;
          businessPartnerRestriction: ReturnType<
              typeof getBusinessPartnerRestrictionText
          >;
      }
    | undefined
>(undefined);
BusinessPartnerContext.displayName = 'BusinessPartnerContext';

const BusinessPartnerProvider = (props: any) => {
    const {user, logout} = useAuth();
    const history = useHistory();
    const queryClient = useQueryClient();

    const [withGwgInfo, setWithGwgInfo] = useState<boolean>(false);
    const [businessPartners, setBusinessPartners] =
        useState<BusinessPartnerList | null>(null);
    const [selectedBpId, _setSelectedBpId] = useState<number | null>(null);

    const {data, isFetched, isLoading, isError, isSuccess, status} =
        useGetBusinessPartnersList({user});

    const {data: activeBusinessPartner, isError: isBusinessPartnerError} =
        useGetBusinessPartner(selectedBpId, withGwgInfo);

    const businessPartnerRestriction = useMemo(
        () => getBusinessPartnerRestrictionText(activeBusinessPartner),
        [activeBusinessPartner],
    );

    const redirectToOnboarding = useMemo(() => {
        return (
            activeBusinessPartner &&
            activeBusinessPartner.bexio &&
            !activeBusinessPartner.identified &&
            !history.location.pathname.includes(PATH.ONBOARDING_OVERVIEW)
        );
    }, [activeBusinessPartner]);

    const setSelectedBpId = useCallback((bpId: number | null) => {
        setItemToStorage(LOCAL_STORAGE_BP_KEY, bpId);
        _setSelectedBpId(bpId);
    }, []);

    const setActiveBusinessPartner = useCallback(
        (bp: BusinessPartner | null) => {
            if (bp && selectedBpId !== bp.id) {
                setItemToStorage(LOCAL_STORAGE_BP_KEY, bp?.id?.toString());
                setSelectedBpId(bp.id);
                queryClient.invalidateQueries({queryKey: [BP_LIST_CACHE]});
            }
        },
        [setSelectedBpId, selectedBpId],
    );

    useEffect(() => {
        if (redirectToOnboarding) {
            history.push(PATH.ONBOARDING_OVERVIEW);
        }
    }, [redirectToOnboarding]);

    // if the API has a response, set the state
    useEffect(() => {
        if (!data) {
            return;
        }

        const selected = getSelectedBusinessPartner();
        setSelectedBpId(selected);
        setBusinessPartners(data);
    }, [data]); // eslint-disable-line

    // if user logs out, clear the state
    useEffect(() => {
        if (user) {
            return;
        }

        setActiveBusinessPartner(null);
        setBusinessPartners(null);
        setSelectedBpId(null);
        removeItemFromStorage(LOCAL_STORAGE_BP_KEY);
    }, [user]); // eslint-disable-line

    // redirect user to reviewProcess if it's pending more than 30 days
    useEffect(() => {
        if (
            BUILD_ENV === 'prod' &&
            !!activeBusinessPartner?.permission?.admin &&
            !!activeBusinessPartner?.reviewProcess?.pendingStart &&
            new Date(activeBusinessPartner?.reviewProcess?.pendingStart) <=
                subDays(startOfDay(new Date()), 30)
        ) {
            history.push(PATH.ONBOARDING_OVERVIEW);
        }
    }, [activeBusinessPartner, history]);

    function getSelectedBusinessPartner(): number {
        const bpFromLocalStorage = getItemFromStorage(LOCAL_STORAGE_BP_KEY);
        if (!data?.length) {
            logout(
                'There was an error during the setup of your account, please get in contact with us',
            );

            return 0;
        }
        let {id} = data[0];
        if (user.defaultBusinessPartner?.['@id']) {
            id = (
                data.find(
                    bp =>
                        bp.id ===
                        +user.defaultBusinessPartner!['@id'].split('/').pop()!,
                ) ?? data[0]
            ).id;
        }

        if (!bpFromLocalStorage) {
            return id;
        }

        const foundIndex = data.findIndex(bp => bp.id === +bpFromLocalStorage);

        if (foundIndex === -1) return id;

        return data[foundIndex].id;
    }

    const values = useMemo(
        () => ({
            businessPartners,
            activeBusinessPartner,
            setActiveBusinessPartner,
            setSelectedBpId,
            setWithGwgInfo,
            businessPartnerRestriction,
        }),
        [
            businessPartners,
            activeBusinessPartner,
            setActiveBusinessPartner,
            setSelectedBpId,
            setWithGwgInfo,
            businessPartnerRestriction,
        ],
    );

    if (isLoading || (user && !activeBusinessPartner)) {
        return <Loader big />;
    }
    if ((isError || isBusinessPartnerError) && logout) {
        logout();
    }

    if (isSuccess || isFetched || status === 'pending') {
        return <BusinessPartnerContext.Provider value={values} {...props} />;
    }

    throw new Error('Unhandled status in business partner provider');
};

const useBusinessPartner = () => {
    const context = React.useContext(BusinessPartnerContext);
    if (context === undefined) {
        throw new Error(
            `useBusinessPartner must be used within a BusinessPartnerProvider`,
        );
    }
    return context;
};

export {BusinessPartnerProvider, useBusinessPartner};
