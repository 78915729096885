import IconSVG from 'components/ui/Icon/IconSVG';
import OnboardingOverlay from 'components/ui/Overlays/OnboardingOverlay/OnboardingOverlay';
import useModal from 'components/ui/ModalContainer/useModal';

import styles from './StepTitle.module.scss';

interface IStepTitleProps {
    title: string | React.ReactNode;
    subTitle?: string | React.ReactNode;
    translateSubtitle?: boolean;
    info?: React.ReactNode;
}

const StepTitle = ({
    title,
    subTitle,
    translateSubtitle = true,
    info,
}: IStepTitleProps) => {
    const {openModalWithContent} = useModal();

    const toggleInfo = () => {
        openModalWithContent(
            'onboardingStepInfo',
            <OnboardingOverlay align="right" fullHeight>
                {info}
            </OnboardingOverlay>,
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.titleRow}>
                <h3 className={styles.title}>{title}</h3>
                {info !== undefined ? (
                    <button
                        onClick={toggleInfo}
                        type="button"
                        className={styles.infoButton}
                    >
                        <IconSVG name="newInfo" />
                    </button>
                ) : null}
            </div>
            {subTitle ? (
                <h3
                    className={styles.subTitle}
                    translate={translateSubtitle ? 'yes' : 'no'}
                >
                    {subTitle}
                </h3>
            ) : null}
        </div>
    );
};

export default StepTitle;
