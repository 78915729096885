import React, {useCallback, useEffect, useState} from 'react';
import Loader from 'components/ui/AmnisLoader/AmnisLoader';
import {useGetExportFile} from 'api/fileExport';
import classNames from 'classnames';
import {getItemFromStorage, setItemToStorage} from 'helpers/localStorage';
import {getTermsAndConditions} from 'helpers/termsAndPrivacy';
import {LOCAL_STORAGE_ACCEPT_CONTRACT_SIGN_TERMS} from 'constants/common';
import {useLanguage} from 'context/language-context';
import {useGetContractSigningUrl} from 'api/business-partner';
import {useAuth} from 'context/auth-context';
import StepTitle from '../components/StepTitle';
import {IDefaultStepProps} from '../useOnboarding';
import illustration from '../components/illustrations/5.svg?url';
import NextStepButton from '../components/NextStepButton';
import AcceptTermsCheckbox from '../components/AcceptTermsCheckbox';

import styles from './Steps.module.scss';

const ContractSign = (props: IDefaultStepProps) => {
    const [subTitle, setSubTitle] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const {selectedLanguage} = useLanguage();
    const {user} = useAuth();
    const [acceptTerms, setAcceptTerms] = useState<number>(0);

    const {
        contract,
        activeBusinessPartner,
        isContractLoading,
        onSuccess,
        crpList,
    } = props;
    const userCrp = crpList?.find(crpBp => crpBp.crp['@id'] === user.crp);
    const {mutate: getPdf, isPending: isPDFLoading} = useGetExportFile();
    const {data: signingUrlData, isLoading: isSigningUrlLoading} =
        useGetContractSigningUrl(
            contract?.['@id'].split('/').pop(),
            userCrp?.contractSigner,
        );

    useEffect(() => {
        if (contract?.status.toLowerCase() === 'signed') {
            setSubTitle(
                'Your contract is already signed. We will contact you shortly, we ask for a little patience.',
            );
        } else if (userCrp?.contractSigned === true) {
            setSubTitle(
                'You already signed the contract, but we are waiting for the second person to sign it.',
            );
        } else if (contract?.status.toLowerCase() === 'cant_sign') {
            setSubTitle(
                'To sign the contract first please click the button to download your contract. After you signed the contract, please send it back to us in digital format to the info@amnistreasury.ch.',
            );
        } else {
            setSubTitle(
                "In the next step, you can check the contract in peace and then sign it online. It's quick and easy - thanks to our trustworthy partner Skribble.",
            );
        }
    }, [contract, userCrp]);

    useEffect(() => {
        setAcceptTerms(
            getItemFromStorage(LOCAL_STORAGE_ACCEPT_CONTRACT_SIGN_TERMS) === '1'
                ? 1
                : 0,
        );
    }, []);

    const handleAccept = (e: React.ChangeEvent<HTMLInputElement>) => {
        const termsValue = e.target.checked ? '1' : '0';
        setItemToStorage(LOCAL_STORAGE_ACCEPT_CONTRACT_SIGN_TERMS, termsValue);
        setAcceptTerms(+termsValue);

        if (e.target.checked) setError(false);
        else setError(true);
    };

    const showAcceptTerms =
        activeBusinessPartner.amnisLegalEntityCountry.split('/').pop() !== 'CH';

    const handleNextStepClick = (callback: () => void) => {
        if (
            !showAcceptTerms ||
            getItemFromStorage(LOCAL_STORAGE_ACCEPT_CONTRACT_SIGN_TERMS) === '1'
        ) {
            callback();
        } else {
            setError(true);
        }
    };

    const getTermsAndConditionsUrl = useCallback(
        () => getTermsAndConditions(selectedLanguage),
        [selectedLanguage],
    );

    if (isContractLoading || isSigningUrlLoading) {
        return <Loader />;
    }

    const renderContent = () => {
        if (userCrp?.contractSigned === true) {
            return (
                <NextStepButton
                    onClick={() => handleNextStepClick(onSuccess)}
                />
            );
        }

        if (contract?.status === 'cant_sign') {
            return (
                <NextStepButton
                    loading={isPDFLoading}
                    onClick={() =>
                        handleNextStepClick(() =>
                            getPdf({
                                url: `${activeBusinessPartner['@id']}/contract-pdf`,
                            }),
                        )
                    }
                />
            );
        }

        return (
            <NextStepButton
                onClick={() =>
                    handleNextStepClick(() => {
                        if (!signingUrlData?.signingUrl)
                            throw new Error(
                                'Invalid signing url: ' +
                                    JSON.stringify(signingUrlData),
                            );
                        window.location.href = signingUrlData.signingUrl;
                    })
                }
            />
        );
    };

    return (
        <div className={classNames(styles.form, styles.contractContainer)}>
            <StepTitle
                title={
                    userCrp?.contractSigned === true
                        ? 'The contract is already signed'
                        : 'Sign the contract'
                }
                subTitle={subTitle}
            />
            <div className={styles.centered}>
                <img
                    src={illustration}
                    className={styles.centeredIllustration}
                />
            </div>
            {showAcceptTerms ? (
                <AcceptTermsCheckbox
                    onChange={handleAccept}
                    linkToTerms={getTermsAndConditionsUrl()}
                    isError={error}
                    value={acceptTerms}
                    termsName="terms & conditions"
                />
            ) : null}
            {renderContent()}
        </div>
    );
};

export default ContractSign;
