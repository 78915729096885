import {useMutation} from '@tanstack/react-query';
import {useClient} from 'context/auth-context';
import {useNotification} from 'context/notification-context';
import {saveFile} from 'helpers/fileSaver';
import {BusinessPartner} from 'types/api';

export interface IUseGetExportFile {
    url: string;
    params?: Record<string, any>;
}

export interface IUseGetExportFileResponse {
    fileName: string;
    content: string;
    contentType?: string;
}

const useGetExportFile = () => {
    const client = useClient();

    return useMutation({
        mutationFn: ({url, params}: IUseGetExportFile) =>
            client(url, {
                params,
            }),
        onSuccess: (data: IUseGetExportFileResponse) => {
            saveFile(data);
        },
    });
};

interface IUseExportZipRequest {
    businessPartner: BusinessPartner;
    month: string;
    card?: string;
}

const useExportZip = () => {
    const client = useClient();
    const {addNotification} = useNotification();

    return useMutation<unknown, unknown, IUseExportZipRequest>({
        mutationFn: ({businessPartner, month, card}) =>
            client('/web_api/card_transaction_export_requests', {
                method: 'POST',
                data: {
                    businessPartner: businessPartner['@id'],
                    month,
                    card,
                },
            }),
        onSuccess: () =>
            addNotification(
                {
                    area: 'debitCards',
                    status: 'success',
                    message: 'ZIP export will be sent to your email',
                },
                'zip_export',
            ),
    });
};

export {useGetExportFile, useExportZip};
