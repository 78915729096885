import * as React from 'react';

const SvgArrowUpRight = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="-3 0 18 10"
        {...props}
    >
        <path
            fill="currentColor"
            d="M6.133 3.814 2.79.012 11.983 0l-1.188 9.118-3.342-3.802-5.633 4.952L.5 8.764l5.633-4.95Z"
        />
    </svg>
);

export default SvgArrowUpRight;
