import * as React from 'react';

const PaymentsNew = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="b9c1aa0c-af69-4af5-825e-9924ec01cfdf"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        {...props}
    >
        <polygon
            fill="#0f3e82"
            fillRule="evenodd"
            points="40 100 30 100 35 105 40 100"
        />
        <polygon
            fill="#b5e3d8"
            fillRule="evenodd"
            points="160 100 170 100 165 95 160 100"
        />
        <path
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#b5e3d8"
            d="M36.74,115A65,65,0,0,0,165,100"
        />
        <path
            stroke="#b5e3d8"
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            d="M163.26,85A65,65,0,0,0,35,100"
        />
        <path
            fill="#0f3e82"
            stroke="#0f3e82"
            d="M116.93,109.93a13.06,13.06,0,0,1-3.77,9.76,16.42,16.42,0,0,1-9.89,4.43V130H98.6v-5.8a19,19,0,0,1-9.7-3.2,15.74,15.74,0,0,1-5.83-7.6l9.2-5.33c1.42,3.25,3.53,5.11,6.33,5.6v-9.2a3.47,3.47,0,0,0-.43-.16,3.43,3.43,0,0,1-.44-.17c-1.42-.58-2.62-1.1-3.59-1.56a27.46,27.46,0,0,1-3.34-2,13.92,13.92,0,0,1-3-2.7,12.46,12.46,0,0,1-2-3.57A13.19,13.19,0,0,1,85,89.6a12.43,12.43,0,0,1,3.93-9.69,16.14,16.14,0,0,1,9.66-4.1V70h4.67v5.93q9.39,1.2,13.12,9.67l-9,5.26a6.83,6.83,0,0,0-4.13-4.33v9q.33.12,2.19.9c1.25.5,2.06.85,2.44,1s1.1.54,2.16,1.1a11.49,11.49,0,0,1,2.27,1.43c.44.4,1,1,1.76,1.73a7.44,7.44,0,0,1,1.57,2.23,20.43,20.43,0,0,1,.87,2.64A13.45,13.45,0,0,1,116.93,109.93ZM95.67,89.67c0,1.33,1,2.56,2.93,3.67v-7C96.64,86.83,95.67,87.94,95.67,89.67Zm7.6,23.86c2-.54,3-1.69,3-3.47,0-1.46-1-2.68-3-3.66Z"
        />
    </svg>
);

export default PaymentsNew;
