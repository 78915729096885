const Search = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="m19.542 20.577-6.28-6.28c-.5.412-1.075.735-1.726.969-.65.233-1.322.35-2.017.35-1.709 0-3.155-.592-4.34-1.776-1.183-1.183-1.775-2.63-1.775-4.338 0-1.709.592-3.155 1.775-4.34 1.184-1.185 2.63-1.777 4.339-1.777 1.708 0 3.155.592 4.34 1.776 1.184 1.184 1.777 2.63 1.777 4.34 0 .713-.12 1.396-.36 2.046a5.72 5.72 0 0 1-.96 1.696l6.281 6.28-1.054 1.054ZM9.52 14.116c1.289 0 2.38-.447 3.274-1.341.895-.895 1.342-1.986 1.342-3.275 0-1.288-.447-2.38-1.342-3.274-.894-.894-1.985-1.341-3.274-1.341-1.288 0-2.38.447-3.274 1.341-.894.895-1.341 1.986-1.341 3.274 0 1.289.447 2.38 1.341 3.274.894.895 1.986 1.342 3.274 1.342Z"
        />
    </svg>
);

export default Search;
