const NewGroup = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 26 15"
        {...props}
    >
        <path
            fill="currentColor"
            d="M3 6.429c1.094 0 2-.938 2-2.143 0-1.172-.906-2.143-2-2.143-1.125 0-2 .97-2 2.143 0 1.205.875 2.143 2 2.143ZM10 7.5c1.906 0 3.5-1.674 3.5-3.75C13.5 1.708 11.906 0 10 0 8.062 0 6.5 1.708 6.5 3.75 6.5 5.826 8.063 7.5 10 7.5Zm2.375 1.071h-.25A4.67 4.67 0 0 1 10 9.107a4.7 4.7 0 0 1-2.156-.536h-.25c-2 0-3.594 1.742-3.594 3.884v.938C4 14.297 4.656 15 5.5 15h9c.813 0 1.5-.703 1.5-1.607v-.938c0-2.143-1.625-3.884-3.625-3.884Zm-6.969-.435C5.031 7.768 4.531 7.5 4 7.5H2c-1.125 0-2 .971-2 2.143v1.071c0 .603.438 1.072 1 1.072h2.031c.219-1.574 1.094-2.913 2.375-3.65ZM18 5h-3v1.5h3v3h1.5v-3h3V5h-3V2H18v3Z"
        />
    </svg>
);

export default NewGroup;
