import * as React from 'react';

const SvgFaq = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 266.66666 266.66666"
        {...props}
    >
        <defs>
            <clipPath id="a" clipPathUnits="userSpaceOnUse">
                <path d="M0 200h200V0H0Z" />
            </clipPath>
        </defs>
        <g
            fill="none"
            strokeMiterlimit="10"
            clipPath="url(#a)"
            transform="matrix(1.33333 0 0 -1.33333 0 266.66667)"
        >
            <path
                stroke="#b5e3d8"
                strokeWidth="10"
                d="M164.9043 105c-2.553 33.563-30.594 60-64.811 60-35.898 0-65-29.102-65-65 0-35.898 29.102-65 65-65 34.222 0 62.266 26.445 64.813 60.015"
            />
            <path
                stroke="#0f3e82"
                strokeWidth="9.036"
                d="M100 66.1826v-13.069m-25.6104 64.9303c0 14.145 11.466 25.61 25.61 25.61 14.145 0 25.611-11.465 25.611-25.61s-11.466-25.61-25.611-25.61v-16.684"
            />
        </g>
    </svg>
);

export default SvgFaq;
