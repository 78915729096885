import * as React from 'react';

const SvgUpgrade = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 21 21"
        {...props}
    >
        <circle
            cx="10.5"
            cy="10.5"
            r="9.8"
            stroke="currentColor"
            strokeWidth="1.4"
        />
        <path
            stroke="currentColor"
            strokeWidth="1.4"
            d="m14.7778 10.1111-3.8889-3.88884-3.88885 3.88884m3.81105-3.88887v8.55557"
        />
    </svg>
);

export default SvgUpgrade;
