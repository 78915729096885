import React, {FocusEvent, useEffect, useRef} from 'react';
import {yupResolver} from '@hookform/resolvers';
import {Alert} from 'react-bootstrap';
import {
    Control,
    DeepPartial,
    FieldError,
    FieldName,
    FieldValues,
    FormProvider,
    RegisterOptions,
    SetFieldValue,
    UnpackNestedValue,
    useForm,
    UseFormMethods,
} from 'react-hook-form';
import {ObjectSchema} from 'yup';
import {useHistory} from 'react-router-dom';
//@ts-ignore
import {BUILD_ENV} from 'config';
import RouteLeavingGuard from '../FormLeaveGuard/FormLeaveGuard';

import styles from './Form.module.scss';
import {Nullable} from 'types/utils/utilitytypes';
import {DevTool} from 'helpers/hookform-devtools';

export interface IDefaultControlProps {
    name: string;
    label?: string | React.ReactNode;
    placeholder?: string;
    className?: string;
    info?: string | React.ReactNode;
    disabled?: boolean;
    readOnly?: boolean;
    variant?: FormVariants;
    rounded?: boolean;
    autoComplete?: string;
    size?: 'large';
    registerOptions?: RegisterOptions;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    hidden?: boolean;
    decimalScale?: number;
    maxLength?: number;
    autoFocus?: boolean;
}

export type FormVariants = 'dark' | 'light';
export type WithFormError<T extends FieldValues> = T & {formError?: string};

type SatisifiesFieldValues<T> = T extends FieldValues ? T : never;

export type FormOnSubmitData<T> = UnpackNestedValue<
    WithFormError<SatisifiesFieldValues<T>>
>;
export type FormOnSubmitSetError = UseFormMethods['setError'];
export type FormOnSubmit<T> = (
    data: FormOnSubmitData<T>,
    setError: FormOnSubmitSetError,
) => void;

export const isFieldError = (error: unknown): error is FieldError => {
    return (
        typeof error === 'object' &&
        error !== null &&
        typeof (error as Record<string, unknown>).message === 'string'
    );
};

export type FormControlValueChangeEventHandler<T> = (
    values: UnpackNestedValue<WithFormError<SatisifiesFieldValues<T>>>,
    setValue: (
        name: FieldName<WithFormError<SatisifiesFieldValues<T>>>,
        value: SetFieldValue<WithFormError<SatisifiesFieldValues<T>>>,
        config?: Partial<{
            shouldValidate: boolean;
            shouldDirty: boolean;
        }>,
    ) => void,
) => void;

export interface IFormProps<T> {
    onSubmit: FormOnSubmit<T>;
    children: React.ReactNode | React.ReactNode[];
    onControlValueChange?: FormControlValueChangeEventHandler<T>;
    /**
     * Form controls will be set with the provided default values, but only if the form hasn't
     * been touched yet
     */
    defaultValues?: UnpackNestedValue<
        DeepPartial<WithFormError<SatisifiesFieldValues<T>>>
    >;
    validationSchema?: ObjectSchema<{} & Nullable<T>>;
    /**
     * Can be used to register fields that aren't actually represented by a control.
     * Form will run *register(key)* with every string key passed down in this prop.
     */
    customFields?: (keyof Partial<T>)[];
    /**
     * Almos equivalent with defaultValues, the difference is the form will be overwritten with
     * the new values even if the fields are dirty.
     */
    resetValues?: UnpackNestedValue<
        DeepPartial<WithFormError<SatisifiesFieldValues<T>>>
    >;
    className?: string;
    confirmLeave?: boolean;
    /**
     * Object of errors can be passed to manually set errors and messages on the form controls
     */
    formErrors?: DeepPartial<{
        [key in keyof WithFormError<SatisifiesFieldValues<T>>]: string;
    }>;
    /**
     * Optionally the values of the useForm<WithFormError<T>>() hook can be provided from outside
     */
    formMethods?: UseFormMethods<WithFormError<SatisifiesFieldValues<T>>>;
}

const Form = <T,>({
    defaultValues,
    children,
    onSubmit,
    onControlValueChange,
    validationSchema,
    customFields,
    resetValues,
    className,
    confirmLeave = false,
    formErrors,
    formMethods,
}: IFormProps<T>) => {
    const history = useHistory();
    const methods = useForm<WithFormError<SatisifiesFieldValues<T>>>({
        defaultValues,
        resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    });
    const {
        handleSubmit,
        setError,
        errors,
        clearErrors,
        formState,
        reset,
        setValue,
        getValues,
    } = formMethods || methods;
    const errorRef = useRef<HTMLDivElement>(null);

    if (customFields) {
        customFields.map(field =>
            methods.register(
                field as unknown as FieldName<
                    WithFormError<SatisifiesFieldValues<T>>
                >,
            ),
        );
    }

    const handleFormChange = () => {
        if (errors.formError) {
            clearErrors(
                'formError' as FieldName<
                    WithFormError<SatisifiesFieldValues<T>>
                >,
            ); // sort of hack, but react-hook-form using string template literals internally...
        }

        if (onControlValueChange) {
            onControlValueChange(getValues(), setValue);
        }
    };

    const handleFormSubmit = (
        values: UnpackNestedValue<WithFormError<SatisifiesFieldValues<T>>>,
    ) => {
        onSubmit(values, setError);
    };

    useEffect(() => {
        if (!formState.isDirty && defaultValues) {
            // dirties the field preventing multiple resets
            setValue(
                'formError' as FieldName<
                    WithFormError<SatisifiesFieldValues<T>>
                >,
                null,
                {
                    shouldDirty: true,
                },
            );
            reset(defaultValues, {
                isDirty: true,
                dirtyFields: true,
                errors: true,
            });
        }
    }, [defaultValues, formState.isDirty, reset, setValue]);

    useEffect(() => {
        if (formErrors && !Object.keys(errors).length) {
            Object.keys(formErrors).forEach(controlName => {
                setError(
                    controlName as FieldName<
                        WithFormError<SatisifiesFieldValues<T>>
                    >,
                    {
                        message: `${formErrors[controlName as keyof typeof formErrors]}`,
                    },
                );
            });
        }
    }, [formErrors, setError]);

    useEffect(() => {
        if (
            formErrors &&
            'formError' in formErrors &&
            formErrors['formError'] &&
            errorRef.current
        ) {
            setTimeout(() => {
                errorRef.current!.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }, 0);
        }
    }, [formErrors]);

    useEffect(() => {
        const errorInputs = Object.keys(errors).filter(
            err => err !== 'formError',
        );
        if (!errorInputs.length) return;

        const firstFieldError = errorInputs.find(err =>
            isFieldError(errors[err]),
        );
        if (!firstFieldError) return;

        const inputRef = (errors[firstFieldError] as FieldError).ref;
        if (!inputRef) return;

        inputRef.focus?.();
    }, [errors, setError]);

    useEffect(() => {
        if (resetValues) {
            reset({...getValues(), ...resetValues}, {isDirty: true});
        }
    }, [getValues, reset, resetValues]);

    const onlyFormErrorIsDirty = (): boolean => {
        const fields = Object.keys(formState.dirtyFields);
        return (
            (fields.length === 1 && fields[0] !== 'formError') || // formError is manually dirtied field, shouldn't trigger RouteLeavingGuard
            fields.length > 1
        );
    };

    return (
        <FormProvider
            {...((formMethods || (methods as unknown)) as UseFormMethods<
                SatisifiesFieldValues<T>
            >)}
        >
            <RouteLeavingGuard
                // When should shouldBlockNavigation be invoked,
                // simply passing a boolean
                // (same as "when" prop of Prompt of React-Router)
                when={confirmLeave}
                navigate={path => history.push(path)}
                shouldBlockNavigation={nextLocation => {
                    return !formState.isSubmitted && onlyFormErrorIsDirty();
                }}
            />
            <form
                onSubmit={handleSubmit(handleFormSubmit)}
                onChange={handleFormChange}
                className={className}
            >
                {children}
                <div ref={errorRef}>
                    {errors.formError && isFieldError(errors.formError) ? (
                        <Alert variant="danger" className={styles.alert}>
                            {errors.formError.message}
                        </Alert>
                    ) : null}
                    {BUILD_ENV === 'local' && Object.keys(errors).length ? (
                        <div className={styles.devErrors}>
                            <p className={styles.title}>FORM ERROR DEBUGGER</p>
                            <p className={styles.subtext}>
                                * Only visible in LOCAL *
                            </p>
                            <div>
                                {Object.keys(errors).map(k => {
                                    return (
                                        <p key={k}>
                                            {JSON.stringify(
                                                (errors[k] as any)?.message,
                                            )}
                                        </p>
                                    );
                                })}
                            </div>
                        </div>
                    ) : null}
                </div>
            </form>
            {/* set up the dev tool */}
            <div data-notranslate>
                <DevTool
                    control={
                        (formMethods?.control ||
                            (methods.control as unknown)) as Control<FieldValues>
                    }
                />
            </div>
        </FormProvider>
    );
};

export interface IGroupProps {
    children: React.ReactNode[];
}

const Group = ({children}: IGroupProps) => {
    return <div className={styles.formGroup}>{children}</div>;
};

Form.Group = Group;

export default Form;
