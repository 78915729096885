const Payments = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
            <path d="m13.262 11.57-.769-.77 4.91-4.91h-4.547V4.812h6.394v6.394h-1.078V6.66l-4.91 4.91ZM4.75 19.187v-6.394h1.078v4.547l4.91-4.91.77.769-4.91 4.91h4.547v1.078H4.749Z" />
            <path d="M12.756 4.712h6.595v6.595h-1.278V6.9l-4.81 4.81-.911-.91 4.81-4.81h-4.406V4.712Zm.559 6.664 4.831-4.832v-.105l.2-.2v4.794h.805v-6.12h-6.12v.803h4.792L12.74 10.8l.575.576Zm-2.577.913.91.91-4.81 4.811h4.406v1.278H4.649v-6.594h1.278V17.1l4.81-4.81Zm.575.859-.575-.576-5.01 5.01v-4.688h-.805v6.12h6.12v-.804h-4.51l-.044.044-.045-.044h-.09l.046-.045-.053-.052 4.966-4.965Z" />
        </g>
    </svg>
);

export default Payments;
