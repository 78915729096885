import * as React from 'react';

const ProductTourIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 200 200" {...props}>
        <path
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            d="M74.83,126.79c12,19,25.17,35.71,25.17,35.71s44.64-56.63,44.64-80.36S123.73,37.5,100,37.5,55.36,58.41,55.36,82.14c0,8.87,6.23,22.33,14,35.72"
        />
        <path
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#b5e3d8"
            d="M114.15,77.5a15,15,0,1,0,0,10"
        />
    </svg>
);

export default ProductTourIcon;
