import * as Yup from 'yup';

const MAX_FILE_SIZE = 8 * 1024 * 1024;

const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/pdf',
];

export const fileUploadShape = Yup.mixed<File[]>()
    .test('fileSize', 'File too large', value => {
        return (value as FileList).length
            ? Array.from(value as FileList).every(
                  file => file.size <= MAX_FILE_SIZE,
              )
            : true;
    })
    .test('fileFormat', 'Unsupported Format', value => {
        return (value as FileList).length
            ? Array.from(value as FileList).every(file =>
                  SUPPORTED_FORMATS.includes(file.type),
              )
            : true;
    });

export const documentShape = Yup.mixed<File>()
    .test('fileSize', 'File too large', value => {
        return value.size <= MAX_FILE_SIZE;
    })
    .test('fileFormat', 'Unsupported Format', value => {
        return SUPPORTED_FORMATS.includes(value.type);
    });
