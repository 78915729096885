import {Icons} from 'components/ui/Icon/IconSVGConfig';
import {PATH} from './common';

export interface IMenuItem {
    title: string;
    link?: string;
    icon?: Icons;
    disabled?: boolean;
    hasDivider?: boolean;
    children?: IMenuItem[];
    getClassName?: () => string;
    action?: () => void;
    funcTitle?: () => string;
}

export interface IMainMenu {
    [key: string]: IMenuItem;
}

export const SIDEBAR_MENU: IMainMenu = {
    dashboard: {
        icon: 'home',
        title: 'Dashboard',
        link: PATH.DASHBOARD,
    },
    currencies: {
        icon: 'currencyExchange',
        title: 'Currency exchange',
        link: PATH.CURRENCIES,
    },
    payments: {
        icon: 'arrowOutward',
        title: 'Payments',
        link: PATH.PAYMENTS,
    },
    collections: {
        icon: 'invoice',
        title: 'Invoices',
        link: PATH.COLLECTIONS,
    },
    cards: {
        icon: 'debitCards',
        title: 'Debit cards',
        link: PATH.CARDS,
    },
    contacts: {
        icon: 'contacts',
        title: 'Contacts',
        link: PATH.CONTACTS,
    },
    accounts: {
        icon: 'accountBalanceWallet',
        title: 'Accounts',
        link: PATH.ACCOUNTS,
    },
    tools: {
        icon: 'tools',
        title: 'Tools',
        link: PATH.TOOLS,
    },
    refer: {
        icon: 'campaign',
        title: 'Refer & earn',
        link: PATH.REFER,
    },
    automation: {
        icon: 'automation',
        title: 'Automation',
        link: PATH.AUTOMATION,
    },
};
