import IconSVG from 'components/ui/Icon/IconSVG';
import classNames from 'classnames';

import styles from './ErrorFeedback.module.scss';

interface IErrorFeedbackProps {
    message: string;
    errorClassName?: string;
}

const ErrorFeedback = ({message, errorClassName}: IErrorFeedbackProps) => {
    return (
        <p className={classNames(styles.errorMessage, errorClassName)}>
            <IconSVG customDimensions name="warning" /> {message}
        </p>
    );
};

export default ErrorFeedback;
