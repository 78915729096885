const CurrencyExchange = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="b3e4b19e-0660-4dd2-9dc8-52e11587d836"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        {...props}
    >
        <line
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#b5e3d8"
            x1="75"
            y1="100"
            x2="75"
            y2="109.99"
        />
        <line
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#b5e3d8"
            x1="75"
            y1="122.98"
            x2="75"
            y2="132.96"
        />
        <line
            fill="none"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#b5e3d8"
            x1="125"
            y1="100"
            x2="125"
            y2="109.99"
        />
        <polyline
            fill="none"
            fillRule="evenodd"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            points="60.62 80.91 39.04 80.91 55 50.94 145 50.94 160.96 80.91 140.44 80.91"
        />
        <polyline
            fill="none"
            fillRule="evenodd"
            strokeMiterlimit={10}
            strokeWidth={10}
            stroke="#0f3e82"
            points="145 95 145 165 125 165 125 128 100 128 100 165 55 165 55 95"
        />
    </svg>
);
export default CurrencyExchange;
