const ArrowForward = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="m12 19.616-1.07-1.054 5.812-5.812H4.385v-1.5h12.357l-5.811-5.81L12 4.385 19.615 12 12 19.616Z"
        />
    </svg>
);

export default ArrowForward;
