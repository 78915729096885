/* eslint-disable jsx-a11y/label-has-associated-control */
import {ICrpBpListItem} from 'api/business-partner';
import classNames from 'classnames';
import {CrpVariants} from 'constants/businessPartnerAttributes';
import {useAuth} from 'context/auth-context';

import FA from 'react-fontawesome';
import useModal from 'components/ui/ModalContainer/useModal';
import {useDeleteCrpBp, useGetConnectedCrps} from 'api/crp';
import AlertModal from 'components/modals/AlertModal/AlertModal';
import OnboardingOverlay from 'components/ui/Overlays/OnboardingOverlay/OnboardingOverlay';
import {LegalType} from 'types/onboarding';
import {crpFormValidationSchema} from 'form/validation/businessPartner';
import useCrpTable, {CrpChangeProp, getChangeProp} from './hooks/useCrpTable';
import CrpForm from './CrpForm';
import AddNewCrpForm, {AddNewCrpBp} from './AddNewCrpForm';

import styles from './CrpTable.module.scss';

interface ICrpTableCheckboxProps {
    crp?: ICrpBpListItem;
    handleChange: React.ChangeEventHandler<HTMLInputElement>;
    changeProp: CrpChangeProp;
    disabled?: boolean;
}
const validateCrpFields = (
    variant: CrpVariants,
    confidential: boolean,
    legalType: LegalType | null,
    crpBp: ICrpBpListItem,
    formOfControl?: string,
): boolean => {
    const validationSchema = crpFormValidationSchema(
        variant,
        confidential,
        legalType,
        formOfControl,
    );
    try {
        const {
            '@id': id,
            '@type': type,
            businessPartner,
            crp,
            user,
            ...rest
        } = crpBp;
        validationSchema.validateSync({...crpBp.crp, ...rest});
    } catch (err) {
        return false;
    }
    return true;
};
const CrpTableCheckbox = ({
    crp,
    handleChange,
    changeProp,
    disabled,
}: ICrpTableCheckboxProps) => {
    const id = crp ? `${crp['@id']}~${changeProp}` : `user~${changeProp}`;
    return (
        <>
            <input
                id={id}
                type="checkbox"
                className={styles.checkbox}
                checked={crp?.[changeProp] ?? false}
                onChange={handleChange}
                disabled={disabled}
            />
            <label
                htmlFor={id}
                className={classNames(styles.checkItemLabel, {
                    [styles.disabled]: disabled,
                })}
            />
        </>
    );
};
interface ICrpTableProps {
    crpList: ICrpBpListItem[];
    isCrpLoading: boolean;
    variant: CrpVariants;
    currentStep: number;
    legalType: LegalType | null;
    formOfControl?: number;
    inReview: boolean;
}
const CrpTable = ({
    crpList,
    variant,
    isCrpLoading,
    currentStep,
    legalType,
    formOfControl,
    inReview,
}: ICrpTableProps) => {
    const {user} = useAuth();
    const {mutate: deleteCrp} = useDeleteCrpBp();
    const {
        handleCrpEdit,
        maxSignersReached,
        showSignersColumn,
        showContractSignersColumn,
        showAdminColumn,
    } = useCrpTable({
        crpList,
        currentStep,
        isIndividual: legalType === LegalType.INDIVIDUAL,
    });
    const {openModalWithContent, closeModal} = useModal();
    const {data: connectedCrps, isLoading: isConnectedCrpsLoading} =
        useGetConnectedCrps();
    const userCrp = crpList?.find(crpBp => crpBp.crp.user === user['@id']);

    const openEdit = (
        crp: ICrpBpListItem,
        targetVariant: CrpVariants,
        loggedInUser?: boolean,
    ) => {
        const changeProp = getChangeProp(targetVariant);
        openModalWithContent(
            'crpForm',
            <OnboardingOverlay fullHeight>
                <CrpForm
                    legalType={legalType}
                    variant={variant}
                    crpBpId={crp?.['@id']}
                    handleSubmit={(values, setError) => {
                        handleCrpEdit(
                            variant,
                            !!loggedInUser,
                            values,
                            crp[changeProp],
                            crp,
                            setError,
                        );
                    }}
                    isLoggedInUser={loggedInUser}
                    formOfControl={formOfControl?.toString()}
                    inReview={inReview}
                />
            </OnboardingOverlay>,
        );
    };
    const handleChange = ({
        event,
        crp,
        targetVariant,
        loggedInUser,
    }: {
        event: React.ChangeEvent<HTMLInputElement>;
        crp?: ICrpBpListItem;
        targetVariant: CrpVariants;
        loggedInUser?: boolean;
    }) => {
        event.persist();
        const newFlag = event.target.checked;
        if (
            newFlag &&
            targetVariant === CrpVariants.CONTRACT_SIGNER &&
            maxSignersReached
        ) {
            openModalWithContent(
                'alert',
                <OnboardingOverlay hideCloseButton>
                    <AlertModal
                        variant="warning"
                        title="Warning"
                        description="You can mark maximum 2 persons as signers."
                        onClose={() => closeModal('alert')}
                    />
                </OnboardingOverlay>,
            );
            return;
        }
        if (
            newFlag &&
            targetVariant === CrpVariants.CONTRACT_SIGNER &&
            crpList.find(
                crpBp =>
                    crpBp.contractSigner &&
                    crpBp.hasSigningRights &&
                    !crpBp.collectiveSigningRight,
            ) !== undefined
        ) {
            openModalWithContent(
                'alert',
                <OnboardingOverlay hideCloseButton>
                    <AlertModal
                        variant="warning"
                        title="Warning"
                        description="You already marked someone who has single contract signing rights."
                        onClose={() => closeModal()}
                    />
                </OnboardingOverlay>,
            );
            return;
        }
        if (
            crp &&
            ((targetVariant === CrpVariants.OWNER &&
                legalType === LegalType.INDIVIDUAL) ||
                validateCrpFields(
                    targetVariant,
                    crp.confidential,
                    legalType,
                    crp,
                    formOfControl?.toString(),
                ))
        ) {
            handleCrpEdit(targetVariant, !!loggedInUser, crp.crp, newFlag, crp);
        } else {
            openModalWithContent(
                'crpForm',
                <OnboardingOverlay fullHeight>
                    <CrpForm
                        legalType={legalType}
                        variant={targetVariant}
                        crpBpId={crp?.['@id']}
                        handleSubmit={(values, setError) => {
                            handleCrpEdit(
                                targetVariant,
                                !!loggedInUser,
                                values,
                                newFlag,
                                crp,
                                setError,
                            );
                        }}
                        isLoggedInUser={loggedInUser}
                        formOfControl={formOfControl?.toString()}
                        inReview={inReview}
                    />
                </OnboardingOverlay>,
            );
        }
    };

    const handleAddNewCrp: AddNewCrpBp = crpBp => {
        if (!crpBp) {
            openModalWithContent(
                'crpForm',
                <OnboardingOverlay fullHeight>
                    <CrpForm
                        legalType={legalType}
                        variant={variant}
                        handleSubmit={(val, setErr) =>
                            handleCrpEdit(
                                variant,
                                false,
                                val,
                                true,
                                undefined,
                                setErr,
                            )
                        }
                        formOfControl={formOfControl?.toString()}
                        inReview={inReview}
                    />
                </OnboardingOverlay>,
            );
        } else {
            openModalWithContent(
                'crpForm',
                <OnboardingOverlay fullHeight>
                    <CrpForm
                        legalType={legalType}
                        variant={variant}
                        crpBpId={crpBp['@id']}
                        handleSubmit={(val, setErr) =>
                            handleCrpEdit(
                                variant,
                                false,
                                val,
                                true,
                                crpBp,
                                setErr,
                            )
                        }
                        formOfControl={formOfControl?.toString()}
                        inReview={inReview}
                    />
                </OnboardingOverlay>,
            );
        }
    };

    return (
        <div
            className={classNames(styles.crpTableWrapper, {
                [styles.loading]: isCrpLoading || isConnectedCrpsLoading,
            })}
        >
            {isCrpLoading ? (
                <div className={styles.loadingOverlay}>
                    <FA name="circle-o-notch" spin size="2x" />
                </div>
            ) : null}
            <div className={styles.crpTable}>
                <div className={styles.columns}>
                    <div
                        className={classNames(
                            styles.crpTableColumn,
                            styles.crpTableColumn_name,
                        )}
                    >
                        <div className={styles.crpTableHeader} />
                        {!userCrp ? (
                            <div
                                className={classNames(
                                    styles.crpTableCell,
                                    styles.crpTableCell_name,
                                )}
                                data-notranslate
                            >
                                <div>
                                    {user.firstName} {user.lastName}
                                    <button
                                        type="button"
                                        className={styles.editCrp}
                                    >
                                        <FA name="pencil" />
                                    </button>
                                </div>
                            </div>
                        ) : null}
                        {crpList?.map(crp => {
                            return (
                                <div
                                    key={`${crp['@id']}~nameCell`}
                                    className={classNames(
                                        styles.crpTableCell,
                                        styles.crpTableCell_name,
                                    )}
                                    data-notranslate
                                >
                                    <div>
                                        {crp.crp.firstName} {crp.crp.lastName}{' '}
                                        <button
                                            type="button"
                                            className={styles.editCrp}
                                            onClick={() =>
                                                openEdit(
                                                    crp,
                                                    variant,
                                                    crp.crp['@id'] === user.crp,
                                                )
                                            }
                                        >
                                            <FA name="pencil" />
                                        </button>
                                    </div>
                                    {crp['@id'] === userCrp?.['@id'] ? null : (
                                        <button
                                            type="button"
                                            className={styles.deleteCrp}
                                            onClick={() =>
                                                deleteCrp(crp['@id'])
                                            }
                                        >
                                            <FA name="trash" />
                                        </button>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div
                        className={classNames(
                            styles.crpTableColumn,
                            styles.crpTableColumn_owner,
                            {
                                [styles.crpTableColumn_active]:
                                    variant === CrpVariants.OWNER,
                            },
                        )}
                    >
                        <div className={styles.crpTableHeader}>
                            Controlling person
                        </div>
                        {!userCrp ? (
                            <div className={styles.crpTableCell}>
                                <CrpTableCheckbox
                                    handleChange={e =>
                                        handleChange({
                                            event: e,
                                            targetVariant: CrpVariants.OWNER,
                                            loggedInUser: true,
                                        })
                                    }
                                    changeProp={getChangeProp(
                                        CrpVariants.OWNER,
                                    )}
                                />
                            </div>
                        ) : null}
                        {crpList?.map(crp => {
                            return (
                                <div
                                    key={`${crp['@id']}~ownerCell`}
                                    className={classNames(
                                        styles.crpTableCell,
                                        styles.crpTableCell_owner,
                                    )}
                                >
                                    <CrpTableCheckbox
                                        handleChange={e =>
                                            handleChange({
                                                event: e,
                                                crp,
                                                targetVariant:
                                                    CrpVariants.OWNER,
                                                loggedInUser:
                                                    crp.crp['@id'] ===
                                                    user?.crp,
                                            })
                                        }
                                        changeProp={getChangeProp(
                                            CrpVariants.OWNER,
                                        )}
                                        crp={crp}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {showSignersColumn ? (
                        <div
                            className={classNames(
                                styles.crpTableColumn,
                                styles.crpTableColumn_signe,
                                {
                                    [styles.crpTableColumn_active]:
                                        variant === CrpVariants.SIGNER,
                                },
                            )}
                        >
                            <div className={styles.crpTableHeader}>
                                Authorized person
                            </div>
                            {!userCrp ? (
                                <div className={styles.crpTableCell}>
                                    <CrpTableCheckbox
                                        handleChange={e =>
                                            handleChange({
                                                event: e,
                                                loggedInUser: true,
                                                targetVariant:
                                                    CrpVariants.SIGNER,
                                            })
                                        }
                                        changeProp={getChangeProp(
                                            CrpVariants.SIGNER,
                                        )}
                                        disabled={
                                            legalType === LegalType.INDIVIDUAL
                                        }
                                    />
                                </div>
                            ) : null}
                            {crpList?.map(crp => {
                                return (
                                    <div
                                        key={`${crp['@id']}~signerCell`}
                                        className={classNames(
                                            styles.crpTableCell,
                                            styles.crpTableCell_signer,
                                        )}
                                    >
                                        <CrpTableCheckbox
                                            handleChange={e =>
                                                handleChange({
                                                    event: e,
                                                    crp,
                                                    targetVariant:
                                                        CrpVariants.SIGNER,
                                                    loggedInUser:
                                                        crp.crp['@id'] ===
                                                        user.crp,
                                                })
                                            }
                                            changeProp={getChangeProp(
                                                CrpVariants.SIGNER,
                                            )}
                                            crp={crp}
                                            disabled={
                                                legalType ===
                                                LegalType.INDIVIDUAL
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                    {showContractSignersColumn ? (
                        <div
                            className={classNames(
                                styles.crpTableColumn,
                                styles.crpTableColumn_signe,
                                {
                                    [styles.crpTableColumn_active]:
                                        variant === CrpVariants.CONTRACT_SIGNER,
                                },
                            )}
                        >
                            <div className={styles.crpTableHeader}>
                                Contract signers
                            </div>
                            {!userCrp ? (
                                <div className={styles.crpTableCell} />
                            ) : null}
                            {crpList?.map(crp => {
                                return (
                                    <div
                                        key={`${crp['@id']}~contractSignerCell`}
                                        className={classNames(
                                            styles.crpTableCell,
                                            styles.crpTableCell_signer,
                                        )}
                                    >
                                        {crp.hasSigningRights ? (
                                            <CrpTableCheckbox
                                                handleChange={e =>
                                                    handleChange({
                                                        event: e,
                                                        crp,
                                                        targetVariant:
                                                            CrpVariants.CONTRACT_SIGNER,
                                                        loggedInUser:
                                                            crp.crp['@id'] ===
                                                            user.crp,
                                                    })
                                                }
                                                changeProp={getChangeProp(
                                                    CrpVariants.CONTRACT_SIGNER,
                                                )}
                                                crp={crp}
                                                disabled={
                                                    legalType ===
                                                        LegalType.INDIVIDUAL ||
                                                    !crp?.hasSigningRights
                                                }
                                            />
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                    {showAdminColumn ? (
                        <div
                            className={classNames(
                                styles.crpTableColumn,
                                styles.crpTableColumn_admin,
                                {
                                    [styles.crpTableColumn_active]:
                                        variant === CrpVariants.ADMIN,
                                },
                            )}
                        >
                            <div className={styles.crpTableHeader}>
                                Administrator
                            </div>
                            {!userCrp ? (
                                <div className={styles.crpTableCell}>
                                    <CrpTableCheckbox
                                        handleChange={e =>
                                            handleChange({
                                                event: e,
                                                targetVariant:
                                                    CrpVariants.ADMIN,
                                                loggedInUser: true,
                                            })
                                        }
                                        changeProp={getChangeProp(
                                            CrpVariants.ADMIN,
                                        )}
                                        disabled={
                                            legalType === LegalType.INDIVIDUAL
                                        }
                                    />
                                </div>
                            ) : null}
                            {crpList?.map(crp => {
                                return (
                                    <div
                                        key={`${crp['@id']}~adminCell`}
                                        className={classNames(
                                            styles.crpTableCell,
                                            styles.crpTableCell_admin,
                                        )}
                                    >
                                        <CrpTableCheckbox
                                            handleChange={e =>
                                                handleChange({
                                                    event: e,
                                                    crp,
                                                    targetVariant:
                                                        CrpVariants.ADMIN,
                                                    loggedInUser:
                                                        crp.crp['@id'] ===
                                                        user.crp,
                                                })
                                            }
                                            changeProp={getChangeProp(
                                                CrpVariants.ADMIN,
                                            )}
                                            crp={crp}
                                            disabled={
                                                legalType ===
                                                LegalType.INDIVIDUAL
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                </div>
                <button
                    className={classNames(styles.addNewCrpButton, {
                        [styles.hidden]:
                            legalType === LegalType.INDIVIDUAL ||
                            variant === CrpVariants.CONTRACT_SIGNER,
                    })}
                    type="button"
                    onClick={() => {
                        if (
                            connectedCrps?.length &&
                            legalType === LegalType.COMPANY
                        ) {
                            openModalWithContent(
                                'alert',
                                <OnboardingOverlay fullHeight>
                                    <AddNewCrpForm
                                        handleSubmit={handleAddNewCrp}
                                        connectedCrps={connectedCrps}
                                        crpBpList={crpList}
                                        variant={variant}
                                    />
                                </OnboardingOverlay>,
                            );
                        } else {
                            handleAddNewCrp();
                        }
                    }}
                >
                    <FA name="plus" /> Add another person
                </button>
            </div>
        </div>
    );
};
export default CrpTable;
