import {useState, useEffect, useRef, useCallback} from 'react';

export function useRecursiveTimeout<T>(
    callback: () => Promise<T> | (() => void),
    delay: number | null,
) {
    const savedCallback = useRef(callback);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the timeout loop.
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        //@ts-ignore
        let id: NodeJS.Timeout;
        function tick() {
            const ret = savedCallback.current();

            if (ret instanceof Promise) {
                ret.then(() => {
                    if (delay !== null) {
                        id = setTimeout(tick, delay);
                    }
                });
            } else if (delay !== null) {
                id = setTimeout(tick, delay);
            }
        }
        if (delay !== null) {
            id = setTimeout(tick, delay);
            return () => id && clearTimeout(id);
        }
    }, [delay]);
}

export function useCountdown(timeout?: number, autoStart = true) {
    if (!timeout) return {timeLeft: Infinity};
    const intervalId = useRef<number | null>(null);
    const [timeLeft, setTimeLeft] = useState<number>(timeout);

    const stopCounter = useCallback(() => {
        if (intervalId.current) {
            clearInterval(intervalId.current);
        }
    }, [intervalId]);

    const startCounter = useCallback(() => {
        intervalId.current = setInterval(() => {
            setTimeLeft(currentTimeLeft => {
                if (currentTimeLeft > 1) {
                    return currentTimeLeft - 1;
                }

                stopCounter();
                return 0;
            });
        }, 1000);
    }, [stopCounter]);

    const restartCounter = () => {
        stopCounter();
        setTimeLeft(timeout);
        startCounter();
    };

    useEffect(() => {
        if (autoStart) {
            startCounter();
        }

        return () => {
            if (autoStart) {
                stopCounter();
            }
        };
    }, [autoStart, startCounter, stopCounter]);

    return {
        stopCounter,
        startCounter,
        restartCounter,
        timeLeft,
    };
}

export const useIntersectionObserver = ({
    root = null,
    target,
    onIntersect,
    threshold = 0,
    rootMargin = '10px',
    enabled = false,
}: any) => {
    const observer = useRef<IntersectionObserver | null>(null);
    useEffect(() => {
        const el = target && target.current;

        if (!enabled || !el) return undefined;

        if (!observer.current) {
            observer.current = new IntersectionObserver(
                entries =>
                    entries.forEach(
                        entry => entry.isIntersecting && onIntersect(),
                    ),
                {
                    root: root && root.current,
                    rootMargin,
                    threshold,
                },
            );
        }

        observer.current.observe(el);

        return () => {
            observer.current?.unobserve(el);
            observer.current = null;
        };
    }, [enabled, root, rootMargin, threshold, target, onIntersect]);
};
