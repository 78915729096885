const Bolt = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="m10.55 18.2 5.175-6.2h-4l.725-5.675L7.825 13H11.3l-.75 5.2Zm-1.973 3.242 1-6.942H4.952l8.24-11.894h1.23l-.99 7.894h5.5L9.808 21.442H8.577Z"
        />
    </svg>
);

export default Bolt;
