const Transactions = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M12 20.959c-1.664 0-3.173-.412-4.53-1.236a8.857 8.857 0 0 1-3.19-3.31v2.544H3v-4.693h4.693v1.28H5.27a7.338 7.338 0 0 0 2.735 3A7.368 7.368 0 0 0 12 19.679a7.483 7.483 0 0 0 2.94-.58 7.712 7.712 0 0 0 2.403-1.58 7.775 7.775 0 0 0 1.654-2.366c.417-.91.641-1.89.674-2.94h1.28a8.664 8.664 0 0 1-.76 3.41 9.14 9.14 0 0 1-1.93 2.781 8.976 8.976 0 0 1-2.822 1.872A8.726 8.726 0 0 1 12 20.96Zm-.604-3.15v-1.077c-.624-.145-1.143-.405-1.557-.778-.414-.373-.732-.876-.953-1.51l1.08-.439c.203.556.494.978.873 1.267.38.289.81.433 1.29.433.485 0 .92-.121 1.307-.364.386-.242.58-.63.58-1.165 0-.446-.167-.8-.499-1.064-.332-.264-.951-.563-1.858-.895-.877-.315-1.51-.668-1.898-1.059-.389-.39-.583-.906-.583-1.547 0-.55.199-1.054.596-1.511.397-.457.952-.749 1.665-.875V6.192h1.165v1.033c.48.037.92.218 1.323.54.404.324.694.713.873 1.167l-1.054.423a2.086 2.086 0 0 0-.628-.788c-.273-.21-.63-.315-1.075-.315-.53 0-.946.126-1.248.378a1.218 1.218 0 0 0-.452.981c0 .402.147.716.441.94.295.224.89.49 1.787.796 1.024.37 1.712.784 2.064 1.244.353.46.529.988.529 1.585 0 .407-.075.764-.226 1.071a2.4 2.4 0 0 1-.59.776 2.85 2.85 0 0 1-.83.496 4.42 4.42 0 0 1-.957.256v1.034h-1.165ZM3.05 11.786c.038-1.24.3-2.391.785-3.455a8.95 8.95 0 0 1 4.764-4.625A8.759 8.759 0 0 1 12 3.041c1.648 0 3.158.413 4.53 1.24a8.596 8.596 0 0 1 3.19 3.34V5.043H21v4.693h-4.693v-1.28h2.424c-.6-1.22-1.506-2.215-2.715-2.983C14.806 4.705 13.468 4.32 12 4.32a7.45 7.45 0 0 0-2.902.571A7.784 7.784 0 0 0 6.69 6.457a7.778 7.778 0 0 0-1.676 2.357c-.424.91-.653 1.9-.685 2.973H3.05Z"
        />
    </svg>
);

export default Transactions;
