import classNames from 'classnames';
import IconSVG from 'components/ui/Icon/IconSVG';

import {useLocation} from 'react-router';
import {PATH} from 'constants/common';
import {useResolveInviteStore} from 'components/pages/ResolveInvitePage/resolveInviteStore';
import {InviteData, useGetInviteData} from 'api/register';
import {BusinessPartnerInvite} from 'types/api/business-partner-invite';
import {Icons} from 'components/ui/Icon/IconSVGConfig';
import {useLanguage} from 'context/language-context';
import styles from './InfoBlock.module.scss';
import {useMemo} from 'react';

const getText = (inviteData?: InviteData) => {
    if (!inviteData || inviteData['@type'] !== 'BusinessPartnerInvite') {
        return (
            <h2 data-isolate>
                Sign up for your&nbsp;
                <span className={styles.yellow}>business demo account</span>
            </h2>
        );
    }
    switch (inviteData.intent) {
        case 'crp': {
            return (
                <h2 data-isolate>
                    Sign the contract&nbsp;
                    <span className={styles.yellow}>online</span> without any
                    hassle.
                </h2>
            );
        }
        case 'card': {
            return (
                <h2>
                    Managing your&nbsp;
                    <span className={styles.yellow}>debit card</span> is now
                    easier than ever.
                </h2>
            );
        }
        case 'default': {
            return (
                <h2 data-isolate>
                    <span className={styles.yellow}>International banking</span>
                    &nbsp;is now easier than ever.
                </h2>
            );
        }
        default: {
            throw new Error(
                `No title set for intent type ${inviteData.intent}`,
            );
        }
    }
};

const iconMaps: Record<
    BusinessPartnerInvite['intent'],
    {icon: Icons; text: string}[]
> = {
    card: [
        {icon: 'registerCheckmark', text: 'Activate your card in a few clicks'},
        {
            icon: 'registerCheckmark',
            text: 'Monitor your transactions in real-time',
        },
        {icon: 'registerCheckmark', text: 'Conveniently manage your PIN code'},
        {icon: 'registerCheckmark', text: 'Block or suspend your card'},
    ],
    crp: [
        {icon: 'registerCheckmark', text: 'Register in 30 seconds'},
        {
            icon: 'registerCheckmark',
            text: 'Get your ID card ready',
        },
        {icon: 'registerCheckmark', text: 'Sign the contract digitally'},
    ],
    default: [
        {icon: 'registerGlobe', text: 'Send money globally'},
        {
            icon: 'registerCollect',
            text: 'Collect 20+ currencies with your own account',
        },
        {icon: 'registerXChange', text: 'Exchange any currency 24/7'},
        {
            icon: 'registerCard',
            text: 'Pay worldwide with multi-currency debit cards',
        },
    ],
};

const defaultIconMap: {icon: Icons; text: string}[] = [
    {icon: 'registerGlobe', text: 'Send money globally'},
    {
        icon: 'registerCollect',
        text: 'Collect 20+ currencies with your own account',
    },
    {icon: 'registerXChange', text: 'Exchange any currency 24/7'},
    {icon: 'registerCard', text: 'Streamline expenses with debit cards'},
];

const bexioIconMap: {icon: Icons; text: string}[] = [
    {icon: 'bolt', text: 'Instant sync with bexio'},
    {icon: 'listView', text: 'Auto-categorised expenses'},
    {icon: 'debitCards', text: '0% fees on card transactions'},
    {icon: 'searchInsights', text: 'Real-time spending control'},
];

const InfoBlock = () => {
    const {pathname} = useLocation();
    const inviteType = useResolveInviteStore(state => state.type);
    const hash = useResolveInviteStore(state => state.hash);
    const {selectedLanguageParams} = useLanguage();
    const {data: inviteData, isLoading: isInviteDataLoading} = useGetInviteData(
        {
            hash,
            type: inviteType === 'business-partner' ? inviteType : undefined,
        },
    );

    const iconMap = useMemo(() => {
        if (
            pathname === PATH.REGISTRATION_BEXIO ||
            pathname === PATH.REGISTRATION_BEXIO_INIT ||
            pathname === PATH.BEXIO
        ) {
            return bexioIconMap;
        }
        return inviteData && inviteData['@type'] === 'BusinessPartnerInvite'
            ? iconMaps[inviteData.intent]
            : defaultIconMap;
    }, [inviteData, pathname]);

    if (isInviteDataLoading) {
        return null;
    }

    const getTitle = () => {
        switch (pathname) {
            case PATH.REGISTRATION_RATE_ALERT:
                return (
                    <h2>
                        Sign up and never miss out on{' '}
                        <span className={styles.yellow}>
                            FX opportunities again
                        </span>
                    </h2>
                );
            case PATH.BEXIO:
            case PATH.REGISTRATION_BEXIO:
            case PATH.REGISTRATION_BEXIO_INIT:
                return (
                    <h2 data-isolate>
                        Smart{' '}
                        <span className={styles.yellow}>
                            expense accounting
                        </span>{' '}
                        starts here
                    </h2>
                );
            default:
                return getText(inviteData);
        }
    };

    return (
        <div className={classNames(styles.side, styles.withBg)}>
            <div className={styles.info}>
                {getTitle()}
                {pathname !== PATH.REGISTRATION_RATE_ALERT ? (
                    <>
                        <ul className={styles.list}>
                            {iconMap.map(icon => (
                                <li key={JSON.stringify(icon)}>
                                    <IconSVG
                                        className={styles.icon}
                                        name={icon.icon}
                                        customDimensions
                                        height="2.5rem"
                                        width="2.5rem"
                                        style={{minWidth: '2.5rem'}}
                                    />
                                    <span>{icon.text}</span>
                                </li>
                            ))}
                        </ul>
                    </>
                ) : null}
                <div
                    className={classNames(
                        'trustpilot-widget',
                        styles.trustpilotWidget,
                    )}
                    data-locale={selectedLanguageParams.locale.code}
                    data-template-id="5419b732fbfb950b10de65e5"
                    data-businessunit-id="62da945343cebd8294642544"
                    data-style-height="24px"
                    data-style-width="100%"
                    data-theme="dark"
                    data-font-family="Poppins"
                    data-text-color="#ffffff"
                >
                    <a
                        href="https://www.trustpilot.com/review/amnistreasury.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Trustpilot
                    </a>
                </div>
            </div>
        </div>
    );
};

export default InfoBlock;
