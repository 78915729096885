const OpenInNew = (props: React.HTMLAttributes<SVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fill="currentColor"
                d="M5.308 20.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.282V5.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h6.307V5H5.308a.294.294 0 0 0-.212.097.294.294 0 0 0-.096.211v13.385c0 .077.032.147.096.211a.294.294 0 0 0 .212.096h13.384a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.211v-6.308h1.5v6.308c0 .505-.175.932-.525 1.282-.35.35-.778.525-1.283.525H5.308Zm4.411-5.165-1.054-1.054 9.281-9.28H14V3.5h6.5V10H19V6.054l-9.28 9.281Z"
            />
        </svg>
    );
};

export default OpenInNew;
