import * as React from 'react';

const SvgRegisterGlobe = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 48 48" fill="none" {...props}>
        <path
            fill="#B3DFD8"
            d="M29.074 26.88c-2.1257-2.4-3.1543-5.9657-2.9486-10.1485.0686-1.44.3429-2.8115.8914-4.1143l1.5772.6171c-.48 1.1657-.7543 2.3315-.7543 3.5657-.2057 3.7029.6857 6.8572 2.5371 8.9143L29.074 26.88Z"
        />
        <path
            fill="#B3DFD8"
            d="M22.081 19.6798h-1.0285c-1.3029 0-2.5372-.2743-3.7029-.96l.8229-1.5086c.8914.48 1.8514.7543 2.8114.6858h.1371c3.7715.2057 6.9943-.8915 9.12-3.1543l1.2343 1.1657c-2.1943 2.4686-5.5543 3.7714-9.3943 3.7714ZM21.0514 35.3146l-1.5771-.6171c.48-1.1657.7542-2.3315.7542-3.5657.2058-3.7029-.6857-6.8572-2.5371-8.9143l1.3029-1.1657c2.1257 2.4 3.1542 5.9657 2.9485 10.1485-.0685 1.3715-.3428 2.8115-.8914 4.1143Z"
        />
        <path
            fill="#B3DFD8"
            d="m17.83 33.12-1.2343-1.1657c2.4686-2.6743 6.1714-3.9772 10.4229-3.6343 1.3028 0 2.5371.2743 3.7028.96l-.8228 1.5086c-.8915-.48-1.8515-.7543-2.8115-.6858H26.95c-3.7714-.4114-6.9943.7543-9.12 3.0172Z"
        />
        <path
            fill="#B3DFD8"
            d="M24 36c-6.5829 0-12-5.4171-12-12s5.4171-12 12-12 12 5.4171 12 12-5.3486 12-12 12Zm0-22.3543c-5.6914 0-10.2857 4.5943-10.2857 10.2857 0 5.6915 4.5943 10.2857 10.2857 10.2857 5.6914 0 10.2857-4.5942 10.2857-10.2857 0-5.6914-4.5943-10.2857-10.2857-10.2857Z"
        />
        <path
            fill="#B3DFD8"
            d="M25.784 24.8228H12.8926v1.7142H25.784v-1.7142ZM35.2469 21.394H22.3555v1.7143h12.8914V21.394Z"
        />
        <rect width="47" height="47" x=".5" y=".5" stroke="#66F" rx="23.5" />
    </svg>
);

export default SvgRegisterGlobe;
