/* eslint-disable */
import * as React from 'react';

const SvgPaymentContact = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="19.75" height="21.372">
        <rect
            fill="#0f3e82"
            width="1.681"
            height="8.468"
            transform="translate(2.696 8.265)"
        />
        <rect
            fill="#0f3e82"
            width="1.681"
            height="8.468"
            transform="translate(6.928 8.265)"
        />
        <rect
            fill="#0f3e82"
            width="1.681"
            height="8.468"
            transform="translate(11.16 8.265)"
        />
        <rect
            fill="#0f3e82"
            width="1.681"
            height="8.468"
            transform="translate(15.392 8.265)"
        />
        <rect
            fill="#0f3e82"
            width="19.75"
            height="1.681"
            transform="translate(0 5.312)"
        />
        <rect
            fill="#0f3e82"
            width="11.286"
            height="1.951"
            transform="translate(4.241 0)"
        />
        <rect
            fill="#b5e3d8"
            width="16.929"
            height="1.681"
            transform="translate(1.42 3.632)"
        />
        <rect
            fill="#b5e3d8"
            width="14.107"
            height="1.681"
            transform="translate(2.831 1.951)"
        />
        <rect
            fill="#0f3e82"
            width="19.75"
            height="1.681"
            transform="translate(0 18.01)"
        />
        <rect
            fill="#b5e3d8"
            width="19.75"
            height="1.681"
            transform="translate(0 19.691)"
        />
    </svg>
);

export default SvgPaymentContact;
