import classnames from 'classnames';
import {createMaps} from 'helpers/common';
import {LayoutVariants} from 'components/layout/UnauthorisedLayout/UnauthorisedLayout';

import logo from 'assets/images/amnis_logo_white.svg?url';
import logoBlue from 'assets/images/amnis_logo_blue.svg?url';
import logo_wir from 'assets/images/amnis_wir.svg?url';
import logo_a352 from 'assets/images/A352_logo.png';
import logo_bexio from 'assets/images/bexio_logo.svg?url';

import styles from './Logo.module.scss';

interface ILogoProps {
    className?: string;
    small?: boolean;
    blue?: boolean;
    variant?: LayoutVariants;
}

const createLogoVariants = (blue?: boolean) =>
    createMaps<Record<LayoutVariants, string>>({
        [LayoutVariants.DEFAULT]: blue ? logoBlue : logo,
        [LayoutVariants.WIR]: logo_wir,
        [LayoutVariants.A352]: logo_a352,
        [LayoutVariants.BEXIO]: logo_bexio,
    });

const Logo: React.FC<ILogoProps> = ({
    className,
    blue,
    small,
    variant = LayoutVariants.DEFAULT,
}) => {
    const LOGO_VARIANTS = createLogoVariants(blue);
    return (
        <div
            className={classnames(styles.container, className, {
                [styles.small]: small,
                [styles.wir]: variant === LayoutVariants.WIR,
            })}
            data-notranslate
        >
            <img
                className={styles.img}
                src={LOGO_VARIANTS[variant]}
                alt="Amnis logo"
            />
            {variant === LayoutVariants.BEXIO ? (
                <>
                    <div className={styles.divider} />
                    <div>
                        <p>powered by</p>
                        <img
                            className={styles.img}
                            src={logoBlue}
                            alt="amnis logo"
                        />
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default Logo;
