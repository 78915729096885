import BusinessActivities from 'components/pages/OnboardingPage/steps/BusinessActivities';
import ContractOverview from 'components/pages/OnboardingPage/steps/ContractOverview';
import ContractSign from 'components/pages/OnboardingPage/steps/ContractSign';
import CreateBusinessPartner from 'components/pages/OnboardingPage/steps/CreateBusinessPartner';
import CrpAdmin from 'components/pages/OnboardingPage/steps/CrpAdmin';
import CrpContractSigner from 'components/pages/OnboardingPage/steps/CrpContractSigner';
import CrpSigner from 'components/pages/OnboardingPage/steps/CrpSigner';
import CrpOwner from 'components/pages/OnboardingPage/steps/CrpOwner';
import FinishCompanyGroup from 'components/pages/OnboardingPage/steps/FinishCompanyGroup';
import FinishOnboarding from 'components/pages/OnboardingPage/steps/FinishOnboarding';
import FinishSignatoriesGroup from 'components/pages/OnboardingPage/steps/FinishSignatoriesGroup';
import Identification from 'components/pages/OnboardingPage/steps/Identification';
import Pep from 'components/pages/OnboardingPage/steps/Pep';
import TwoFactor from 'components/pages/OnboardingPage/steps/TwoFactor';
import {IDefaultStepProps} from 'components/pages/OnboardingPage/useOnboarding';
import {LegalType, StepTypes} from 'types/onboarding';
import {PATH} from 'constants/common';
import BusinessAttributes from 'components/pages/OnboardingPage/steps/BusinessAttributes';
import ReasonForAmnisStep from 'components/pages/OnboardingPage/steps/ReasonForAmnis';
import IncomingPayments from 'components/pages/OnboardingPage/steps/IncomingPayments';
import DebitCardStep from 'components/pages/OnboardingPage/steps/DebitCardStep';
import FxStep from 'components/pages/OnboardingPage/steps/FxStep';
import SourceOfWealth from 'components/pages/OnboardingPage/steps/SourceOfWealth';
import PepBp from 'components/pages/OnboardingPage/steps/PepBp';
import {BusinessPartner, ReasonForAmnis} from 'types/api';
import BusinessPartnerDocumentsUpload from 'components/pages/OnboardingPage/steps/BusinessPartnerDocumentsUpload';
import GeneralPayments from 'components/pages/OnboardingPage/steps/GeneralPayments';
import OutgoingPayments from 'components/pages/OnboardingPage/steps/OutgoingPayments';

export interface IStep {
    type: StepTypes;
    next: StepTypes | null;
    start?: boolean;
    illustration?: boolean;
    Component: (props: IDefaultStepProps) => JSX.Element;
    title: (legalType: LegalType | null, sandbox: boolean) => string;
    disabled?: (activeBusinessPartner: BusinessPartner) => boolean;
}

export const STEPS: IStep[] = [
    {
        type: StepTypes.CREATE_BP,
        next: StepTypes.BUSINESS_ACTIVITIES,
        start: true,
        Component: CreateBusinessPartner,
        title: (legalType, sandbox) =>
            legalType === LegalType.INDIVIDUAL
                ? 'Private Individual'
                : `${sandbox ? 'Create' : 'Edit'} Business Partner`,
    },
    {
        type: StepTypes.BUSINESS_ACTIVITIES,
        next: StepTypes.BUSINESS_ATTRIBUTES,
        Component: BusinessActivities,
        title: legalType =>
            legalType === LegalType.INDIVIDUAL
                ? 'Background information'
                : 'Business activities',
    },
    {
        type: StepTypes.BUSINESS_ATTRIBUTES,
        next: StepTypes.REASON_FOR_AMNIS,
        Component: BusinessAttributes,
        title: () => 'Business attributes',
    },
    {
        type: StepTypes.REASON_FOR_AMNIS,
        next: StepTypes.GENERAL_PAYMENTS,
        Component: ReasonForAmnisStep,
        title: () => 'Reason for amnis',
        disabled: activeBusinessPartner => {
            // bexio onboarding should skip purpose question, but only during onboarding
            return !!activeBusinessPartner.bexio && !activeBusinessPartner.reviewProcess
        }
    },
    {
        type: StepTypes.GENERAL_PAYMENTS,
        next: StepTypes.INCOMING_PAYMENTS,
        Component: GeneralPayments,
        title: () => 'General payments',
        disabled: activeBusinessPartner => {
            const stepsToCheck: Partial<ReasonForAmnis>[] = [
                'incoming_payment',
                'outgoing_payment',
            ];
            return !stepsToCheck.some(v =>
                activeBusinessPartner.gwgInfo?.reasonForAmnis?.includes(v),
            );
        },
    },
    {
        type: StepTypes.INCOMING_PAYMENTS,
        next: StepTypes.OUTGOING_PAYMENTS,
        Component: IncomingPayments,
        title: () => 'Incoming payments',
        disabled: activeBusinessPartner =>
            !activeBusinessPartner.gwgInfo?.reasonForAmnis?.includes(
                'incoming_payment',
            ),
    },
    {
        type: StepTypes.OUTGOING_PAYMENTS,
        next: StepTypes.DEBIT_CARD,
        Component: OutgoingPayments,
        title: () => 'Outgoing payments',
        disabled: activeBusinessPartner =>
            !activeBusinessPartner.gwgInfo?.reasonForAmnis?.includes(
                'outgoing_payment',
            ),
    },
    {
        type: StepTypes.DEBIT_CARD,
        next: StepTypes.FX,
        Component: DebitCardStep,
        title: () => 'Debit card',
        disabled: activeBusinessPartner =>
            !activeBusinessPartner.gwgInfo?.reasonForAmnis?.includes(
                'debit_card',
            ),
    },
    {
        type: StepTypes.FX,
        next: StepTypes.PEP,
        Component: FxStep,
        title: () => 'Currency exchange',
        disabled: activeBusinessPartner =>
            !activeBusinessPartner.gwgInfo?.reasonForAmnis?.includes('fx'),
    },
    {
        type: StepTypes.PEP,
        next: StepTypes.SOURCE_OF_WEALTH,
        Component: PepBp,
        title: () => 'Politically exposed person',
    },
    {
        type: StepTypes.SOURCE_OF_WEALTH,
        next: StepTypes.COMPANY_FINISH,
        Component: SourceOfWealth,
        title: () => 'Source of wealth',
    },
    {
        type: StepTypes.OWNER_CRPS,
        next: StepTypes.SIGNER_CRPS,
        Component: CrpOwner,
        title: () => 'Controlling person(s)',
    },
    {
        type: StepTypes.SIGNER_CRPS,
        next: StepTypes.CONTRACT_SIGNER_CRPS,
        Component: CrpSigner,
        title: () => 'Authorized signatories',
    },
    {
        type: StepTypes.CONTRACT_SIGNER_CRPS,
        next: StepTypes.ADMIN_CRPS,
        Component: CrpContractSigner,
        title: () => 'Authorized contract signatories',
    },
    {
        type: StepTypes.ADMIN_CRPS,
        next: StepTypes.GENERATE_CONTRACT,
        Component: CrpAdmin,
        title: () => 'Select Administrator',
    },
    {
        type: StepTypes.GENERATE_CONTRACT,
        next: StepTypes.SIGNATORIES_FINISH,
        Component: ContractOverview,
        title: () => 'Generate contract',
    },
    {
        type: StepTypes.TWOFA,
        next: StepTypes.PEP_CRP,
        Component: TwoFactor,
        title: () => 'Activate 2FA',
    },
    {
        type: StepTypes.PEP_CRP,
        next: StepTypes.IDENTITY,
        Component: Pep,
        title: () => 'Politically exposed person',
    },
    {
        type: StepTypes.IDENTITY,
        next: StepTypes.CONTRACT_SIGN,
        Component: Identification,
        title: () => 'Identification',
    },
    {
        type: StepTypes.CONTRACT_SIGN,
        next: StepTypes.FILE_UPLOADS,
        Component: ContractSign,
        title: () => 'Sign the contract',
    },
    {
        type: StepTypes.FILE_UPLOADS,
        next: StepTypes.FINISH_ONBOARDING,
        Component: BusinessPartnerDocumentsUpload,
        title: () => 'Required documents',
    },
    {
        type: StepTypes.COMPANY_FINISH,
        next: StepTypes.OWNER_CRPS,
        illustration: true,
        Component: FinishCompanyGroup,
        title: () => 'Company done',
    },
    {
        type: StepTypes.SIGNATORIES_FINISH,
        next: StepTypes.TWOFA,
        illustration: true,
        Component: FinishSignatoriesGroup,
        title: () => 'Signatories done',
    },
    {
        type: StepTypes.FINISH_ONBOARDING,
        illustration: true,
        next: null,
        Component: FinishOnboarding,
        title: () => 'Finish',
    },
];

export type OrderedStep = IStep & {
    number: number;
    prev: StepTypes | null;
};

const createStepOrder = () => {
    const result: OrderedStep[] = [];

    const first = STEPS.find(l => l.start);
    if (!first) throw new Error('missing first step');

    let current: IStep | null = first;
    let prev: StepTypes | null = null;
    let currentNumber = -1;
    let currentIllustrationNumber = 90;
    while (current !== null) {
        result.push({
            ...current,
            number: current.illustration
                ? (currentIllustrationNumber += 1)
                : (currentNumber += 1),
            next: current.next,
            prev,
        });
        const defaultNextStep: StepTypes | null = current.next;
        prev = current.type;
        current = STEPS.find(l => l.type === defaultNextStep) || null;
    }

    return result;
};

export const steps = createStepOrder();

export const createOnboardingUrlPath = (type: StepTypes) => {
    return `${PATH.ONBOARDING_OVERVIEW}/${type}`;
};

export const findOnboardingStepByType = (
    target: StepTypes | null,
): OrderedStep => {
    return steps.find(s => s.type === target)!;
};

export const findOnboardingStepByNumber = (target: number): OrderedStep => {
    const found = steps.find(s => s.number === target);
    if (!found) {
        throw new Error(`Onboarding step not found: ${target}`);
    }
    return found;
};

export const transformStepTypeToNamed = (step: StepTypes) => {
    return step === StepTypes.CREATE_BP
        ? 'BackToCreate'
        : step.replace(/(^\w|-\w)/g, partial =>
              partial.replace(/-/, '').toUpperCase(),
          );
};

export const transformNamedStepToStepType = (step: string): StepTypes => {
    return step === 'BackToCreate'
        ? StepTypes.CREATE_BP
        : (step.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() as StepTypes);
};
